// Components
import TextCustom from '../TextCustom';
import { Divider } from '@mui/material';

// Assets
import { ReactComponent as EditarIcon } from '../../../assets/icons/Icono-Editar.svg';

const TituloCard = ({
  titulo = '',
  subtitulo = '',
  editar = false,
  handleShowModal = () => null,
}) => {
  return (
    <>
      <div className="mb-1 flex justify-between items-center">
        <TextCustom
          text={titulo}
          className="fontPSemiBold mb-1"
          color="general"
        />
        {editar && (
          <EditarIcon
            width={30}
            height={30}
            onClick={() => handleShowModal(true)}
            className="cursor-pointer hover:scale-105 transition-all duration-150 "
          />
        )}
        {subtitulo && (
          <TextCustom
            text={subtitulo}
            className="fontPSemiBold mb-1"
            color="general"
          />
        )}
      </div>
      <Divider />
    </>
  );
};

export default TituloCard;
