import axios from "axios";
import { endpoints } from "../../../common/endpoints";
import { stGetToken } from "../../storage";
import buildRequest from "../../buildRequest";

export const apiSubirLista = async (params) => {
    const url = `${process.env.REACT_APP_api}${endpoints.blacklist}`;
    const method = 'post';
    const dataResponse = {
        success: false,
        statusCode: 0,
        message: '',
        data: [],
    };


    const { data: token } = stGetToken();
    

    if (!token) return;

    const request = params;
    try {
        const response = await axios[method](
            url,
            request,
            buildRequest(token, request)
        );

        
        const { data } = response;

        dataResponse.success = true;
        dataResponse.data = data.data;
    } catch (error) {
        
        dataResponse.message = error.response.data.message;
        dataResponse.statusCode = error.response.status;
        dataResponse.success = false;
    }
    return dataResponse;
};


export const obtenerUrlBlackList = async (params = null) => {
    const url = `${process.env.REACT_APP_api}${endpoints.urlBlacklist}`;
    const method = 'post';
    const dataResponse = {
        success: false,
        statusCode: 0,
        message: '',
        data: [],
    };

    const { data: token } = stGetToken();
    
    if (!token) return;

    // Si params es null, asignar un objeto vacío como valor predeterminado
    const request = params || {};

    try {
        const response = await axios[method](
            url,
            request,
            buildRequest(token, request)
        );

        const { data } = response;

        dataResponse.success = true;
        dataResponse.data = data.data;
    } catch (error) {
        dataResponse.message = error.response.data.message;
        dataResponse.statusCode = error.response.status;
        dataResponse.success = false;
    }
    return dataResponse;
};
