import { useEffect, useState } from 'react';

// Components
import { ButtonCustom, SelectCustom, TextCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';
import DatePickerCustom from '../../atoms/DatePickerCustom';

const seguros = [
    { id: 'SEG-AEG', label: 'Seguro de Enfermedades Graves'},
    { id: 'SEG-V', label: 'Seguro de Viaje'},
    { id: 'SEG-DA', label: 'Seguro de Deducible Automóvil'},
    // { id: 'SEG-VID', label: 'Seguro de Vida Plus' },
    // { id: 'SEG-FUNE', label: 'Seguro Funerario Plus' },
    // { id: 'SEG-ONC', label: 'Seguro Oncológico Plus' },
    // { id: 'SEG-PM', label: 'Seguro de Protección Múltiple' }
]

const estados = [
    { id: 'COT', label: 'Cotización'},
    { id: 'NEG', label: 'Negociación'},
    { id: 'DOC', label: 'Documentación'},
    { id: 'EMI', label: 'Emisión'},
    { id: 'PAG', label: 'Pago'},
    { id: 'GAN', label: 'Ganada'},
    { id: 'DOR', label: 'Dormida'},
    { id: 'PER', label: 'Perdida'},
]

const DialogExportarOportunidades = ({
  isOpenModal = false,
  setIsOpenModal = () => null,
  actionSubmit = () => null,
  isLoading
}) => {
  const [tipoSeguro, setTipoSeguro] = useState('');
  const [estado, setEstado] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  useEffect(() => {
    if (isOpenModal) {
        resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleSubmit = () => {
    let params = {};

    !!tipoSeguro && Object.assign(params, {codSeguro: tipoSeguro});
    !!estado && Object.assign(params, {codEtapa: estado});
    !!fechaInicio && Object.assign(params, {fecha_inicio: fechaInicio});
    !!fechaFin && Object.assign(params, {fecha_fin: fechaFin});
    actionSubmit(params);
    resetValues();
  };


  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const resetValues = () => {
    setTipoSeguro('');
    setEstado('');
    setFechaInicio('')
    setFechaFin('')
  };


  return (
    <DialogCustom
      open={isOpenModal}
      setOpen={handleCloseModal}
      title={'Exportar'}
      disabledDismiss
    >
      <DialogContent style={{ width: 900 }}>
        <div className="md:grid md:grid-cols-2 gap-x-10 mt-10 px-7">
          <div className="mt-5 mb-5">
            <TextCustom text="Tipo de seguro" />
            <SelectCustom            
              placeholder="Selecciona un tipo de seguro"
              borderStyle={true}
              value={tipoSeguro}
              setValue={setTipoSeguro}
              className="mb-2"
              options={seguros}
            //   typesValidation={typesValidation.lettersExtend}
              type="text"
            //   onKeyUp={event => formatData(event, 'nombre')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Estado" />
            <SelectCustom
              placeholder="Selecciona un estado"
              borderStyle={true}
              value={estado}
              setValue={setEstado}
              options={estados}
              className="mb-2"
            //   onKeyUp={event => formatData(event, 'cedula')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Fecha de inicio" />
            <DatePickerCustom
              placeholderText="DD/MM/AAAA"
              borderStyle={true}
              value={fechaInicio}
              setValue={setFechaInicio}
              className="mb-2"
            //   msgError={formErrors.correo}
            //   success={formSuccess.correo}
            //   onKeyUp={event => formatData(event, 'correo')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Fecha de fin" />
            <DatePickerCustom
              placeholderText="DD/MM/AAAA"
              borderStyle={true}
              value={fechaFin}
              setValue={setFechaFin}
              className="mb-2"
            //   msgError={formErrors.telefono}
            //   success={formSuccess.telefono}
            //   onKeyUp={event => formatTel(event, this)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="mx-auto mt-10">
          <ButtonCustom
            disabled={ isLoading ? true : false }
            text={isLoading ? 'Exportando...': 'Exportar'}
            typeColor="general"
            width={'150px'}
            onClick={handleSubmit}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default DialogExportarOportunidades;
