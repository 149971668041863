export const stSetIsAuth = data => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    dataResponse.data = localStorage.setItem('isLogin', JSON.stringify(data));
    dataResponse.success = true;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};

export const stGetIsAuth = () => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    const isLogin = JSON.parse(localStorage.getItem('isLogin'));
    if (isLogin) {
      dataResponse.data = isLogin;
      dataResponse.success = true;
    }
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};

export const stRemoveIsAuth = () => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    dataResponse.data = localStorage.removeItem('isLogin');
    dataResponse.success = true;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};
