import { validTextInput } from './validateInput';
import { typesValidation } from '../../common/types';

const { onlyPassword } = typesValidation;

export const formValidCambiarPassword = user => {
  const dataResponse = {
    isValid: true,
    msgValid: {
      errors: null,
      success: null,
    },
  };
  let isValid = true;
  let inputsError = {
    passwordOld: '',
    passwordNew: '',
    passwordConfirmacion: '',
    telefono: '',
  };
  let inputsSuccess = {
    passwordOld: false,
    passwordNew: false,
    passwordConfirmacion: false,
    telefono: false,
  };

  if (!user.passwordOld) {
    inputsError.passwordOld = 'Ingrese su contraseña actual.\n';
    inputsSuccess.passwordOld = false;
    isValid = false;
  }

  if (!user.passwordNew) {
    inputsError.passwordNew = 'Ingrese su nueva contraseña.\n';
    inputsSuccess.passwordNew = false;
    isValid = false;
  } else {
    if (user.passwordNew.length < 8) {
      inputsError.passwordNew = 'La contraseña debe tener 8 o más caracteres';
      inputsSuccess.passwordNew = false;
      isValid = false;
    } else {
      if (!validTextInput(user.passwordNew, onlyPassword)) {
        inputsError.passwordNew =
          'La contraseña debe tener mayúsculas, minúsculas y números';
        inputsSuccess.passwordNew = false;
        isValid = false;
      }
    }
  }

  if (!user.passwordConfirmacion) {
    inputsError.passwordConfirmacion = 'Ingrese contraseña de verificacion.\n';
    inputsSuccess.passwordConfirmacion = false;
    isValid = false;
  }

  if (user.passwordConfirmacion !== user.passwordNew) {
    inputsError.passwordNew = 'Las contraseñas no coinciden.\n';
    inputsSuccess.passwordNew = false;
    isValid = false;
  }

  dataResponse.isValid = isValid;
  dataResponse.msgValid.errors = inputsError;
  dataResponse.msgValid.success = inputsSuccess;
  return dataResponse;
};
