// Components
import { TextCustom, TituloCard } from '../../atoms';

const CardDatosPersonales = ({
  primerNombre = '',
  segundoNombre = '',
  primerApellido = '',
  segundoApellido = '',
  identificacion = '',
  nacimiento = '',
}) => {
  return (
    <>
      <TituloCard titulo="Datos Personales" />
      <div className="grid grid-cols-3 gap-x-2 gap-y-7 m-5 mt-6">
        <div>
          <TextCustom text="Primer Nombre" className="text-sm" />
          <br />
          <TextCustom
            text={primerNombre}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
        <div>
          <TextCustom text="Segundo Nombre" className="text-sm" />
          <br />
          <TextCustom
            text={segundoNombre}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
        <div>
          <TextCustom text="N. de Identificación" className="text-sm" />
          <br />
          <TextCustom
            text={identificacion}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
        <div>
          <TextCustom text="Primer Apellido" className="text-sm" />
          <br />
          <TextCustom
            text={primerApellido}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
        <div>
          <TextCustom text="Segundo Apellido" className="text-sm" />
          <br />
          <TextCustom
            text={segundoApellido}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
        <div>
          <TextCustom text="Fecha de Nacimiento" className="text-sm" />
          <TextCustom
            text={nacimiento}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
      </div>
    </>
  );
};

export default CardDatosPersonales;
