import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

// Componentes
import { LabelTexto, Loader, TextCustom, TituloCard } from '../../atoms';
import {
  CardContacto,
  CardDatosPersonales,
  Cotizacion,
  TituloPerfil,
} from '../../molecules';

import {
  ModalPermiso
} from "../../organisms"
// Assets
import { ReactComponent as ExternoIcon } from '../../../assets/icons/Icono-Externo.svg';
import { ReactComponent as PDFIcon } from '../../../assets/icons/Icono-PDF.svg';

// Services
import {
  apiDescargarDocumentos,
  getApiDetalleCotizacion,
  getApiOportunidadPorCotizacion,
} from '../../../services';

// Funciones
import {
  buscarPermiso,
  formatearFechaNacimiento,
  formatearIdentificacion,
  renderDetalle,
  renderTipoSeguro,
} from '../../../core/utils';

//styles
import './styles.css';

// HOOKS
import { AuthContext } from '../../../hooks/context';

const PerfilCotizacion = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  const [detalleCotizacion, setDetalleCotizacion] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [permisoGetOportunidad, setPermisoGetOportunidad] = useState(false);
  const [permisoGetAliado, setPermisoGetAliado] = useState(false);
  const [modalPermiso, setModalPermiso] = useState(false);
  const { persona, seguro, detalle, aliado, etapa } = detalleCotizacion;

  useEffect(() => {
    setPermisoGetOportunidad(
      buscarPermiso(permisosContext, 'oportunidades', 'get'),
    );
    setPermisoGetAliado(buscarPermiso(permisosContext, 'aliados', 'get'));
  }, [permisosContext]);

  const handleBack = () => {
    navigate('/dashboard/cotizaciones/');
  };

  const handleFiltrarOportunidad = async () => {
    const { success, data, statusCode } = await getApiOportunidadPorCotizacion(
      detalleCotizacion?._id,
    );
    if (success) {
      navigate(`/dashboard/oportunidades/${data._id}`);
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
    setCargando(false);
  };

  const handleBackToList = () => {
    setModalPermiso(false)
    navigate('/dashboard/cotizaciones');
  };
  
  useEffect(() => {
    const obtenerDetalleCotizacion = async () => {
      const { success, data, statusCode } = await getApiDetalleCotizacion(
        params.id,
      );
      if (success) {
        if (data.length === 0) {
          setModalPermiso(true);
        }else{
          setDetalleCotizacion(data);
        }
      } else {
        if (statusCode === 403) {
          navigate('/page-error-401');
          return;
        }
        navigate('/page-error-500', { state: { statusCode: statusCode } });
      }
      setCargando(false);
    };

    obtenerDetalleCotizacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleDescargarPdf = async () => {
    const documento = detalleCotizacion?.documentos?.find(
      doc => doc.value === 'resumen',
    );

    const params = {
      path: documento?.path,
    };

    const { data, success, message } = await apiDescargarDocumentos(params);
    if (success) {
      window.location = data;
    } else {
      console.log(message);
    }
  };

  const handleRecuperarCotizacion = cotizacionUrl => {
    window.open(cotizacionUrl, '_blank');
  };

  const formatDetalle = () => {
    return detalle?.concat(
      detalleCotizacion?.detalleSeguro
        ? // eslint-disable-next-line no-unused-vars
          Object.entries(detalleCotizacion.detalleSeguro)
            .filter(([k]) => k === 'fechaRegresoOriginal')
            .map(val => `${val[0]}: ${val[1]}`)
        : [],
    );
  };

  return (
    <>
      {cargando && <Loader />}
      <ModalPermiso isOpen ={modalPermiso} setIsOpen={handleBackToList} modulo='cotizaciones'/>
      <div className="flex items-center justify-between gap-3">
        <TituloPerfil
          titulo={`${seguro?.nombre}`}
          icono={renderTipoSeguro(seguro?.codigo)}
          handleBack={handleBack}
        />
        <div className="flex gap-4">
          {detalleCotizacion.linkRecuperacionCotizacion &&
            etapa?.codigo !== 'PER' && (
              <ExternoIcon
                className="cursor-pointer"
                onClick={() =>
                  handleRecuperarCotizacion(
                    detalleCotizacion.linkRecuperacionCotizacion,
                  )
                }
              />
            )}

          {detalleCotizacion?.documentos?.some(
            doc => (doc.value = 'resumen'),
          ) && (
            <PDFIcon className="cursor-pointer" onClick={handleDescargarPdf} />
          )}
        </div>
      </div>
      <div className="pt-10 grid grid-cols-4 gap-4">
        <div className="bg-card col-span-2">
          <CardDatosPersonales
            primerNombre={persona?.primer_nombre}
            segundoNombre={
              persona?.segundo_nombre ? persona?.segundo_nombre : '-'
            }
            identificacion={`${
              persona?.numero_identificacion
                ? formatearIdentificacion(persona?.numero_identificacion)
                : '-'
            }`}
            primerApellido={persona?.primer_apellido}
            segundoApellido={
              persona?.segundo_apellido ? persona?.segundo_apellido : '-'
            }
            nacimiento={
              persona?.fecha_nacimiento
                ? formatearFechaNacimiento(persona?.fecha_nacimiento)
                : '-'
            }
          />
        </div>
        <div className="bg-card">
          <CardContacto correo={persona?.correo} telefono={persona?.telefono} />
        </div>
        <div className="flex flex-col gap-4">
          <div className="bg-card">
            <TituloCard titulo="Oportunidad" />
            <div className="flex flex-col m-2 mt-6 ">
              {permisoGetOportunidad ? (
                <div onClick={handleFiltrarOportunidad}>
                  <LabelTexto
                    color={etapa?.colores?.find(
                      color => color.tipo === 'primary',
                    )}
                    texto={etapa?.nombre}
                    className="fontPSemiBold underline cursor-pointer"
                  />
                </div>
              ) : (
                <div>
                  <LabelTexto
                    color={etapa?.colores?.find(
                      color => color.tipo === 'primary',
                    )}
                    texto={etapa?.nombre}
                    className="fontPSemiBold"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="bg-card">
            <TituloCard titulo="Aliado" />
            <div className="flex flex-col gap-10 m-2 mt-6 ">
              {permisoGetAliado ? (
                <Link
                  to={`/dashboard/aliados/${aliado?._id}`}
                  className="fontPBold underline cursor-pointer"
                >
                  {aliado?.nombre ? aliado?.nombre : '-'}
                </Link>
              ) : (
                <TextCustom
                  className="fontPBold"
                  text={aliado?.nombre ? aliado?.nombre : '-'}
                ></TextCustom>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 row-seguro bg-card">
        <TituloCard
          titulo="Detalle del Seguro"
          subtitulo={`Cotización: ${detalleCotizacion?.codigo ?? params?.id}`}
        />

        <div className="md:grid md:grid-cols-4 items-center gap-10  m-5 mt-6">
          {formatDetalle()?.map((det, index) => (
            <div key={index}>
              {renderDetalle(det.split(':')[0], det.split(':')[1])}
            </div>
          ))}
        </div>
      </div>
      <div className="my-6 mt-5 row-resultados">
        <TituloCard titulo="Resultados" />
        <div className="md:grid md:grid-cols-3 2xl:grid-cols-4 gap-5 mt-6">
          {detalleCotizacion?.cotizaciones?.map((cotizacion, index) => (
            <Cotizacion cotizacion={cotizacion} key={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PerfilCotizacion;
