import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import {
  DatePickerDashboard,
  Loader,
  NoDataGrafico,
  TextCustom,
} from '../../atoms';

import { ReactComponent as IconoAgenda } from '../../../assets/icons/Icono-Agenda.svg';
import { apiCanalizacionVentas } from '../../../services/apis/dashboard/apiDashboard';
import { useOportunidad } from '../../../hooks/others';

const ChartCanalizacionVentas = () => {
  const params = useParams();

  const { valorDivisa, divisaSeleccionada } = useOportunidad();

  const [open, setOpen] = useState(false);
  const divisaRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [isValorCotizacionLleno, setisValorCotizacionLleno] = useState(false);
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        distributed: true,
        barHeight: '80%',
        isFunnel: true,
        isFunnel3d: true,
      },
    },

    dataLabels: {
      enabled: false,
      textAnchor: 'start',
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
      },
      offsetX: 0,
    },
    legend: {
      show: false,
    },
    dropShadow: {
      enabled: false,
    },
  });

  useEffect(() => {
    setCargando(true);
    if (valorDivisa !== 0) {
      obtenerDataGrafico();
    }
  }, [selectedDate, params, valorDivisa]);

  useEffect(() => {
    divisaRef.current = divisaSeleccionada;
    const dataAConvertir = series[0].data;
    const currency = valorDivisa;

    //convierto los datos a dolares
    if (divisaSeleccionada === 'USD') {
      const dataConvert = dataAConvertir.map(dat =>
        (+dat / currency).toFixed(2),
      );

      setSeries([{ data: dataConvert }]);
    } else {
      //convierto los datos a colones
      const dataConvert = dataAConvertir.map(dat =>
        (+dat * currency).toFixed(2),
      );

      setSeries([{ data: dataConvert }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisaSeleccionada]);

  const handleShowDatePicker = () => {
    setOpen(true);
  };

  const obtenerDataGrafico = async () => {
    const currency = valorDivisa;
    const param = {
      filtroMes: selectedDate ? dayjs(selectedDate).format('M') : null,
      aliadoId: params.id ? params.id : null,
    };

    const { success, data } = await apiCanalizacionVentas(param);

    if (success) {
      //comprobar si hay para mostrar el grafico o imagen de no data
      const valoresCotizacionCombinados = data.map(dataGrafico => {
        return (
          dataGrafico.valorCotizacion.USD * currency +
          dataGrafico.valorCotizacion.CRC
        );
      });
      const valorCotizacion = valoresCotizacionCombinados.reduce(
        (acc, dataGrafico) => acc + dataGrafico,
        0,
      );
      valorCotizacion
        ? setisValorCotizacionLleno(true)
        : setisValorCotizacionLleno(false);

      if (param.filtroMes) {
        if (divisaSeleccionada === 'USD') {
          //dolares
          const oportunidadValoresDolares = valoresCotizacionCombinados.map(
            valorGrafico => valorGrafico / currency,
          );

          setSeries([{ data: oportunidadValoresDolares }]);
        } else {
          setSeries([{ data: valoresCotizacionCombinados }]);
        }
      } else {
        setSeries([{ data: valoresCotizacionCombinados }]);
      }

      setOptions({
        ...options,
        xaxis: {
          categories: '',
          labels: {
            style: {
              colors: ['#AAAAAA'],
              fontSize: '12px',
              fontFamily: 'Poppins-Regular',
              fontWeight: 400,
            },
            formatter: function (value) {
              if (value >= 1e6) {
                return (
                  (value / 1e6).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) + 'M'
                );
              } else if (value >= 1e3) {
                return (
                  (value / 1e3).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) + 'k'
                );
              } else {
                return value.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
        },
        tooltip: {
          theme: 'dark',
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Poppins-Regular',
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return '';
              },
            },
            formatter: function (val, { dataPointIndex }) {
              const labels = data.map(op => op.nombre);
              const label = labels[dataPointIndex];
              return `${label}: ${new Intl.NumberFormat(
                divisaRef.current === 'USD' ? 'en-US' : 'es-CR',
                {
                  style: 'currency',
                  currency: divisaRef.current === 'USD' ? 'USD' : 'CRC',
                },
              ).format(val)}`;
            },
          },
        },

        colors: data
          .map(item => item.colores.filter(color => color.tipo === 'primary'))
          .flat()
          .map(color => color.codigo),
      });
    }

    //cambio el estado de cargando
    setCargando(false);
  };

  const handleMinDate = () => {
    const currentDate = new Date();
    return currentDate.setFullYear(currentDate.getFullYear() - 5);
  };

  return (
    <>
      <div className="flex justify-between items-center ">
        <TextCustom text="Canalización de ventas" className="fontPSemiBold" />

        <div
          className="bg-[#E5EBEF] rounded-[10px] flex items-center py-0.5 px-4 gap-3 cursor-pointer relative"
          onClick={handleShowDatePicker}
        >
          <IconoAgenda fill="#003763" className="w-[13px] h-[13px]" />
          <TextCustom
            text={` ${
              selectedDate
                ? dayjs(selectedDate).locale('es').format('MMMM. YYYY')
                : dayjs().locale('es').format('MMMM. YYYY')
            }`}
            className="text-[#003763] text-xs"
          />
          <DatePickerDashboard
            open={open}
            setOpen={setOpen}
            className="invisible absolute"
            setValue={setSelectedDate}
            value={selectedDate}
            minDate={handleMinDate()}
            maxDate={new Date()}
            viewsPicker={['month', 'year']}
          />
        </div>
      </div>

      {cargando ? (
        <Loader type="full" />
      ) : isValorCotizacionLleno ? (
        <Chart type="bar" height={320} options={options} series={series} />
      ) : (
        <NoDataGrafico tituloGrafico="Canalización de ventas" />
      )}
    </>
  );
};

export default ChartCanalizacionVentas;
