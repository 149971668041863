import { useNavigate } from 'react-router-dom';

//Assets
import { ReactComponent as Error404Image } from '../../../assets/images/Errors/Error404.svg';
import { ButtonCustom, TextCustom } from '../../atoms';

// Styles
import './style.css';

const Error404 = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="contenedor-page-error flex justify-center items-center h-screen w-screen flex-col bg-white absolute top-0 gap-5">
      <Error404Image />
      <TextCustom
        text="ERROR 404"
        className="fontPMedium color-primary text-xl pt-1"
      />
      <TextCustom
        text="Parece que estás perdido"
        variant="h2"
        className="fontPBold color-general"
      />
      <TextCustom
        text="Te llevaremos de nuevo al inicio."
        className="fontPRegular"
      />
      <ButtonCustom
        text="Regresar"
        height="auto"
        width="200px"
        onClick={handleGoBack}
      />
    </div>
  );
};

export default Error404;
