import { typesActionsAuth } from '../../common/types';

const initialState = {
  isLogin: false,
};

const authReducer = (state = initialState, action = null) => {
  const { type, payload } = action;
  switch (type) {
    case typesActionsAuth.authLogin:
      return {
        ...state,
        isLogin: true,
        personalInfo: payload?.personalInfo,
        token: payload?.token,
        permisos: payload?.permisos,
        categorias: payload?.categorias,
        etapas: payload?.etapas,
      };

    case typesActionsAuth.authLogout:
      return initialState;
    case typesActionsAuth.authUpdate:
      return {
        ...state,
        personalInfo: payload,
      };

    default:
      return state;
  }
};

export default authReducer;
