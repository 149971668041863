import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import { ButtonCustom, LoaderInput, TextCustom } from '../../atoms';

// Assets
import { ReactComponent as DescargarIcon } from '../../../assets/icons/Icono-Descargar.svg';
import { ReactComponent as SubirIcon } from '../../../assets/icons/Icono-Subir.svg';

// Utils
import {
  renderBgEstado,
  renderColorEstado,
  renderIconEstado,
} from '../../../core/utils';
import { apiSubirDocumentos, apiDescargarDocumentos } from '../../../services';

const CardDocumento = ({
  requerido = false,
  val = '',
  onChange = () => null,
  defaulValue = '',
  pregunta = '',
}) => {
  const params = useParams();
  const [value, setValue] = useState('');
  const [estado, setEstado] = useState('');
  const [mensaje, setMnsaje] = useState('');
  const [fileName, setFileName] = useState('');
  const [linkDocumentoPath, setlinkDocumentoPath] = useState(
    pregunta.linkDocumento,
  );
  const [cargandoSubida, setCargandoSubida] = useState(false);
  const [cargandoBajada, setCargandoBajada] = useState(false);

  const estados = {
    obligatorio: { estado: 'obligatorio', mensaje: 'Documento Obligatorio' },
    opcional: { estado: 'opcional', mensaje: 'Documento Opcional' },
    aceptado: { estado: 'aceptado' },
  };

  useEffect(() => {
    if (pregunta.linkDocumento) {
      setEstado(estados.aceptado.estado);
      //TODO: cambiar el mensaje por el nombre del documento
      setMnsaje(pregunta.nombreDocumento || 'Condiciones ');
    } else if (value === '') {
      if (requerido) {
        setEstado(estados.obligatorio.estado);
        setMnsaje(estados.obligatorio.mensaje);
      } else {
        setEstado(estados.opcional.estado);
        setMnsaje(estados.opcional.mensaje);
      }
    } else {
      setEstado(estados.aceptado.estado);
      setMnsaje(fileName);
    }

    if (defaulValue) {
      setlinkDocumentoPath(defaulValue.path);
      setMnsaje(defaulValue.name);
      setEstado(estados.aceptado.estado);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClick = () => {
    const inputFile = document.getElementById(`file-${val}`);

    const clicEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    inputFile.dispatchEvent(clicEvent);
  };

  const handleOnChange = e => {
    handleChangeImage(e.target.files);
  };

  const handleChangeImage = async files => {
    try {
      setCargandoSubida(true);

      const file = {
        url: URL.createObjectURL(files[0]),
        name: files[0].name,
      };

      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('name', files[0].name);
      formData.append('id', params.id);
      formData.append('modulo', pregunta?.pathDocumento);
      formData.append('value', val);

      const { data, success, message } = await apiSubirDocumentos(formData);

      if (success) {
        setlinkDocumentoPath(data.pathDocument);
      } else {
        console.log(message);
      }

      pregunta.urlDocumento = data;
      setValue(file.url);
      setFileName(file.name);
      onChange({ path: data, name: file.name }, pregunta);

      setCargandoSubida(false);
    } catch (error) {
      console.log(error);
      setCargandoSubida(false);
    }
  };

  const handleDescargarDocumento = async () => {
    setCargandoBajada(true);

    let params = {};

    if (typeof linkDocumentoPath === 'object') {
      params = {
        path: linkDocumentoPath.pathDocument,
      };
    } else {
      params = {
        path: linkDocumentoPath,
      };
    }

    try {
      const { data, success, message } = await apiDescargarDocumentos(params);
      if (success) {
        window.location = data;
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log('error al descargar archivo', error);
    }

    setCargandoBajada(false);
  };

  return (
    <div className="mt-5">
      <TextCustom className="text-sm" text={pregunta.descripcion} />
      <div className="grid grid-cols-2 gap-4 my-4 items-center">
        <div className="hidden">
          <input
            type="file"
            onChange={handleOnChange}
            accept=".pdf"
            className="input-file"
            id={`file-${val}`}
          />
        </div>
        <ButtonCustom
          text={` ${
            cargandoSubida
              ? 'Subiendo Archivo Espera...'
              : linkDocumentoPath
              ? 'Actualizar Documento'
              : 'Subir documento'
          }`}
          height="40px"
          width="100%"
          variant="text"
          className={`sombra-btn justify-between px-4 text-xs fontPSemiBold rounded-md ${
            cargandoSubida || cargandoBajada ? ' pointer-events-none ' : ''
          }`}
          typeColor="general"
          endIcon={<SubirIcon className="w-5 h-5" />}
          onClick={handleClick}
        />

        {cargandoSubida ? (
          <div className="flex justify-center">
            <LoaderInput />
          </div>
        ) : linkDocumentoPath ? (
          <ButtonCustom
            text={`${cargandoBajada ? 'Descargando Espera...' : 'Descargar'}`}
            height="40px"
            width="100%"
            variant="text"
            className={`sombra-btn justify-between px-4 text-xs fontPSemiBold rounded-md z-10 bg-neutral ${
              cargandoSubida || cargandoBajada ? ' pointer-events-none ' : ''
            }`}
            typeColor="general"
            endIcon={<DescargarIcon className="w-5 h-5" />}
            onClick={handleDescargarDocumento}
          />
        ) : null}
      </div>
      <TextCustom className="text-sm" text="Documento:" />
      <div className="mt-4">
        <div
          className="grid grid-cols-8 rounded-md py-1 px-5 items-center"
          style={{ backgroundColor: renderBgEstado(estado) }}
        >
          <div className="col-span-1">{renderIconEstado(estado)}</div>
          <div
            className="col-span-7 fontPSemiBold text-sm cursor-pointer"
            style={{ color: renderColorEstado(estado) }}
          >
            {mensaje}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDocumento;
