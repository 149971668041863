import { useEffect, useState } from 'react';

// Components
import {
  ButtonCustom,
  TextCustom,
  TextInputCustom,
  SelectCustom,
} from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

// Hooks
import { useForm } from '../../../hooks/others';

//validaciones
import {
  validFormEditUsuario,
  validFormUsuario,
  validInputEmail,
} from '../../../core/validations';
import { typesValidation } from '../../../common/types';

// API's
import { getRoles } from '../../../services';

// Assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const DialogUsuarios = ({
  isOpenModal = false,
  setIsOpenModal = () => null,
  usuario = null,
  actionSubmit = () => null,
}) => {
  const [id, setId] = useState(null);
  const [nombre, setNombre] = useState('');
  const [rol, setRol] = useState('');
  const [correo, setCorreo] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //validaciones
  const [formErrors, setFormErrors, reset] = useForm({
    nombre: '',
    rol: '',
    correo: '',
    password: '',
  });

  useEffect(() => {
    const obtenerRoles = async () => {
      const { success, data } = await getRoles();

      if (success) {
        const opcionesFormatSelect = data.map(rol => ({
          id: rol._id,
          label: rol.nombre,
        }));
        setRoles(opcionesFormatSelect);
      }
    };

    obtenerRoles();
  }, []);

  useEffect(() => {
    if (isOpenModal) {
      if (usuario) {
        setId(usuario?.id);
        setNombre(usuario?.nombre);
        setCorreo(usuario?.correo);
      } else {
        resetValues();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleSubmit = () => {
    const validation = handleValidForm();

    if (validation.isValid) {
      setisLoading(true);
      let params;

      if (id) {
        //* mandar la informacion al endpoint
        params = {
          id,
          correo,
          nombre,
          rol,
        };
      } else {
        //* mandar la informacion al endpoint
        params = {
          password,
          correo,
          nombre,
          rol,
        };
      }

      actionSubmit(params, setisLoading);
    }
    // setisLoading();
  };

  //* funcion de validacion del formulario
  const handleValidForm = () => {
    const params = { password, correo, nombre, rol };
    let mensaje = '';
    let responseValid;
    if (id) {
      responseValid = validFormEditUsuario(params);
    } else {
      responseValid = validFormUsuario(params);
    }
    const { isValid, msgValid } = responseValid;

    if (validarObjeto(msgValid.errors)) mensaje = msgValid.errors.password;

    setFormErrors(msgValid.errors);

    return { isValid, mensaje };
  };

  const validarObjeto = objeto => {
    if (
      Object.prototype.hasOwnProperty.call(objeto, 'password') &&
      objeto.password.trim() !== ''
    ) {
      for (var key in objeto) {
        if (
          key !== 'password' &&
          Object.prototype.hasOwnProperty.call(objeto, 'key') &&
          objeto[key].trim() !== ''
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const resetValues = () => {
    reset();
    setId(null);
    setNombre('');
    setRol('');
    setCorreo('');
    setPassword('');
  };

  const handleSetRol = value => {
    setRol(value);
    if (formErrors['rol']) {
      setFormErrors({ ...formErrors, ['rol']: '' });
    }
  };

  const formatData = (e, value) => {
    const valorInput = e.target.value;

    if (formErrors[value] && valorInput) {
      if (
        value === 'correo' &&
        !validInputEmail(valorInput, typesValidation.validateEmail)
      ) {
        setFormErrors({
          ...formErrors,
          [value]: 'Formato de correo electrónico no válido.',
        });
      } else {
        setFormErrors({ ...formErrors, [value]: '' });
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderIcon = () => {
    if (showPassword) {
      return (
        <VisibilityOff
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'transparent',
            color: '#999999',
          }}
        />
      );
    } else {
      return (
        <Visibility
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'transparent',
            color: '#999999',
          }}
        />
      );
    }
  };

  return (
    <DialogCustom
      open={isOpenModal}
      setOpen={handleCloseModal}
      title={`${id ? 'Editar Usuario' : 'Crear Usuario'} `}
      disabledDismiss
    >
      <DialogContent style={{ width: 900 }}>
        <div className="md:grid md:grid-cols-2 gap-x-10 mt-10 px-7">
          <div className="mt-5 mb-5">
            <TextCustom text="Nombre" required />
            <TextInputCustom
              placeholder="Nombre"
              borderStyle={true}
              value={nombre}
              setValue={setNombre}
              className="mb-2"
              msgError={formErrors.nombre}
              type="text"
              typesValidation={typesValidation.lettersExtend}
              onKeyUp={event => formatData(event, 'nombre')}
            />
          </div>
          {!id && (
            <div className="mt-5 mb-5">
              <TextCustom text="Rol" required />
              <SelectCustom
                value={rol}
                setValue={handleSetRol}
                placeholder="Selecciona un rol"
                className="mb-2"
                msgError={formErrors.rol}
                borderStyle={true}
                options={roles}
              />
            </div>
          )}
          <div className="mt-5 mb-5">
            <TextCustom text="Correo Electrónico" required />
            <TextInputCustom
              placeholder="Escribe el correo electrónico"
              borderStyle={true}
              value={correo}
              setValue={setCorreo}
              className="mb-2"
              msgError={formErrors.correo}
              type="email"
              onKeyUp={event => formatData(event, 'correo')}
            />
          </div>
          {!id && (
            <div className="mt-5 mb-5">
              <TextCustom text="Contraseña" required />
              <TextInputCustom
                placeholder="Escribe la contraseña"
                borderStyle={true}
                value={password}
                setValue={setPassword}
                className="mb-2"
                msgError={formErrors.password}
                type={showPassword ? 'text' : 'password'}
                onKeyUp={event => formatData(event, 'password')}
                iconEnd={renderIcon()}
                iconOnClick={handleClickShowPassword}
                iconMode="button"
                iconTypeColor="white"
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="mx-auto mt-10">
          <ButtonCustom
            text={`${id ? 'Guardar cambios' : 'Crear'} `}
            typeColor="general"
            width={`${id ? '200px' : '150px'} `}
            onClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default DialogUsuarios;
