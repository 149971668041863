import { memo, useEffect, useState } from 'react';

// Components
import { ButtonGroup, Select } from '@mui/material';
import { FormControlCustom, IconButtonCustom, MenuItemCustom } from '../';

// Utils
import { checkAccess, renderColor } from '../../../core/utils';

// Assets
import { ReactComponent as SelectIcon } from '../../../assets/icons/SelectIconEtapas.svg';

// Hooks
import { useOportunidad } from '../../../hooks/others';

// Styles
import './style.css';

const SelectEtapas = ({
  name = null,
  value = '',
  setValue = () => null,
  disabled = false,
  required = false,
  fontSize = 18,
  className = '',
  defaultValue = '',
  placeholder = '',
  access = true,
  ruta = '',
  metodo = '',
  disabledBtn = false,
}) => {
  const { dataEtapas } = useOportunidad();
  access = checkAccess(ruta, metodo);

  useEffect(() => {
    if (value) renderStyles(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [background, setBackground] = useState('');
  const [mostrarBtn, setMostrarBtn] = useState(true);

  const handlePaso = () => {
    let pasoActual = dataEtapas.filter(opc => opc.label === value)?.[0];
    let optionSelect = dataEtapas.filter(
      opc => opc.orden === pasoActual.orden + 1,
    )?.[0];
    setValue(optionSelect.label);
  };

  const handleChange = event => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  const renderStyles = value => {
    let optionSelect = dataEtapas.filter(opc => opc.label === value)?.[0];
    const { colorPrimary, codigo } = optionSelect;

    if (codigo === 'GAN' || codigo === 'PER') setMostrarBtn(false);

    setBackground(colorPrimary);
    renderIcon(colorPrimary);
  };

  const renderIcon = color => {
    let svg = document.querySelector('.Buttom-flecha');
    let icon = svg?.querySelector('svg');
    let flecha = icon?.querySelector('path');

    if (flecha && icon) {
      icon.style.fill = '#fff';
      flecha.style.stroke = color;
    }
  };

  if (access) {
    return (
      <div className={`flex flex-col ${className}`}>
        <FormControlCustom required={required}>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            style={{
              backgroundColor: background,
              borderRadius: '20px',
              boxShadow: '1px 1px 20px #E5EBEF',
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              label={name}
              value={value}
              defaultValue={defaultValue}
              onChange={handleChange}
              className="select-etapas"
              size="large"
              disabled={disabled}
              inputProps={{ style: { padding: '13.5px 14px', color: '#fff' } }}
              IconComponent={SelectIcon}
              placeholder={placeholder}
              displayEmpty
              sx={{
                '& .MuiInputBase-input': {
                  fontFamily: 'Poppins-SemiBold',
                  // paddingLeft: '10px',
                },
                '$ MuiSelect-select': {
                  padding: '10px 14px',
                  color: '#fff',
                },
                '&.MuiOutlinedInput-root': {
                  '&:hover > fieldset': {
                    border: 0,
                    borderColor: 'transparent',
                  },
                },
                '& MuiPaper-root': {
                  marginTop: 1,
                },
                '& legend': {
                  marginLeft: 2,
                  fontSize: fontSize * 0.82,
                },
                '& fieldset': {
                  borderRadius: '0px',
                  borderColor: 'transparent',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  display: 'none',
                },
                backgroundColor: disabled
                  ? renderColor('ligthGray')
                  : background,
                borderRadius: mostrarBtn ? '20px 0 0 20px' : '20px',
                marginTop: 0,
                color: 'white',
                width: mostrarBtn ? '180px' : '220px',
                fontWeight: '600',
                borderRight: '1px solid #fff',
              }}
            >
              <MenuItemCustom disabled value="">
                <span
                  className="fontPRegular"
                  style={{ color: renderColor('texto') }}
                >
                  {placeholder}
                </span>
              </MenuItemCustom>

              {dataEtapas.map(
                (option, index) =>
                    <MenuItemCustom
                      key={index}
                      className="justify-start fontPRegular"
                      value={option?.id}
                      disabled={option.disabledSelect}
                    >
                      <div className="space-x-2">
                        <span
                          className="w-3 h-3 rounded-full inline-block rounded-label"
                          style={{ backgroundColor: `${option.colorPrimary}` }}
                        ></span>
                        <span className="texto-select">{option?.label}</span>
                      </div>
                    </MenuItemCustom>
              )}
            </Select>
            {mostrarBtn && (
              <IconButtonCustom
                className="Buttom-flecha"
                icon={<SelectIcon width={'20px'} height={'20px'} />}
                onClick={handlePaso}
                disabled={disabledBtn}
              />
            )}
          </ButtonGroup>
        </FormControlCustom>
      </div>
    );
  }
};

export default memo(SelectEtapas);
