import { Tooltip } from '@mui/material';

// Assets
import { ReactComponent as LinkIcon } from '../../../assets/icons/Icono-Link.svg';

const ButtonCopyClipboard = ({
  toolTipMensaje = '',
  handleCopyClick = () => null,
  inputRef = null,
  valueToCopy = '',
  titulo = '',
  tamTitulo = 'sm',
}) => {
  return (
    <Tooltip title={toolTipMensaje} arrow placement="top">
      <div
        className="rounded-[10px] px-9 py-2 mt-2 flex gap-5 cursor-pointer"
        style={{ backgroundColor: '#F8FBFD' }}
        onClick={handleCopyClick}
      >
        <input
          ref={inputRef}
          style={{ position: 'absolute', left: '-9999px' }}
          value={valueToCopy}
        />
        <LinkIcon />

        <button
          className={`fontPSemiBold color-general ${
            tamTitulo === 'sm' ? 'text-base' : 'text-sm'
          }`}
        >
          {titulo}
        </button>
      </div>
    </Tooltip>
  );
};

export default ButtonCopyClipboard;
