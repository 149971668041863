import { ReactComponent as IconDownload } from "../../../assets/icons/UploadFile/Icono-Download.svg";
import "./styles/style.css";
import { TextCustom} from "../../atoms";
import "./../../../components/styles/colors.css";

const CardDescargaArchivo = ({
  titulo = "",
  fecha = "",
  tamaño = "",
  url = ""
}) => {


  return (
    <div className="card flex items-center h-[4.5rem]   "  >
      <a href={url}></a>
      <div className="flex flex-col  h-full ">
        <IconDownload width={100} height={100}  />
      </div>

      <div>
     <a href={url}><TextCustom text={titulo} color="general" className="fontPBold"  /></a>

        <div className="flex flex-row gap-1  opacity-90">
          <span  > {fecha} <span   >&#8226;</span> {tamaño}  </span>
    
        </div>
      </div>
    </div>
  );
};

export default CardDescargaArchivo;
