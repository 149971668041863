import { typesValidation } from '../../common/types';
import { validInputEmail } from './validateInput';

const { validateEmail } = typesValidation;

export const validFormAliado = user => {
  const dataResponse = {
    isValid: true,
    msgValid: {
      errors: null,
      success: null,
    },
  };
  let isValid = true;
  let inputsError = {
    nombre: '',
    cedula: '',
    correo: '',
    telefono: '',
    codigoAliado: '',
  };
  let inputsSuccess = {
    nombre: false,
    cedula: false,
    correo: false,
    telefono: false,
    codigoAliado: false,
  };

  if (!user.nombre) {
    inputsError.nombre = 'Nombre del aliado no ha sido asiganado.\n';
    inputsSuccess.nombre = false;
    isValid = false;
  }

  if (user.cedula.length !== 9) {
    inputsError.cedula = 'Cédula del aliado no es válido.\n';
    inputsSuccess.cedula = false;
    isValid = false;
  }
  if (!user.cedula) {
    inputsError.cedula = 'Cédula del aliado no se ha asignado.\n';
    inputsSuccess.cedula = false;
    isValid = false;
  }

  if (!user.correo) {
    inputsError.correo = 'Correo del aliado no ha sido asignado.\n';
    inputsSuccess.correo = false;
    isValid = false;
  }

  if (user.codigoAliado?.length < 6) {
    inputsError.codigoAliado =
      'Código del aliado debe tener al menos 6 caracteres.\n';
    inputsSuccess.codigoAliado = false;
    isValid = false;
  }

  if (!user.codigoAliado) {
    inputsError.codigoAliado = 'Código del aliado no ha sido asignado.\n';
    inputsSuccess.codigoAliado = false;
    isValid = false;
  }

  if (!inputsError.correo && !validInputEmail(user.correo, validateEmail)) {
    inputsError.correo = 'Correo no válido.\n';
    inputsSuccess.correo = false;
    isValid = false;
  }
  dataResponse.isValid = isValid;
  dataResponse.msgValid.errors = inputsError;
  dataResponse.msgValid.success = inputsSuccess;
  return dataResponse;
};
