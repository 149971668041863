import { useEffect, useState } from 'react';

// Components
import { TextCustom } from '../../atoms';

// Hooks
import { useOportunidad } from '../../../hooks/others';

const CardPrecio = ({ pregunta = '', cuotas = '', setValue = () => null, values = {}}) => {
  const { pago, setPago } = useOportunidad();
  const [pagoActual, setPagoActual] = useState('');
  const [pagoAnual, setPagoAnual] = useState('');

  useEffect(() => {
    let dataPago = pregunta.respuestas.filter(
      etapa => etapa.codigo === 'A',
    )?.[0];
    if(values.valorCuota){
      setPagoAnual(values);
    }else{
      setPagoAnual(dataPago);
    }

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if(values.valorCuota ){
      setPagoActual(values)
    }else{
      if (cuotas) {
        let dataPago = pregunta.respuestas.filter(
          etapa => etapa.idPago === cuotas['id'],
        )?.[0];
        let newPago = { ...pago };
        newPago.totalPagar = dataPago.valor;
        newPago.cuotas = cuotas.id;
  
        setPago(newPago);
        setPagoActual(dataPago);
        setValue(dataPago.valor);
      }
    }
    
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cuotas]);


  return (
    <div className="mt-5">
      <TextCustom className="text-sm" text={pregunta.descripcion} />
      <div className="flex flex-wrap my-4">
        <div className="flex justify-center w-1/2">
          <div className="flex flex-col">
            <TextCustom
              className="text-xs fontPSemiBold"
              text="Total anual"
              color="general"
            />
            <TextCustom
              className="text-3xl fontPSemiBold"
              text={pagoAnual.totalPagar ? pagoAnual.totalPagar : pagoAnual.valor }
              color="general"
            />
          </div>
        </div>
        {pagoActual?.valorCuota && pagoActual?.formaPagoSeleccionada !== "Anual" && (
          <div
            className="flex justify-center w-1/2"
            style={{ borderLeft: '1px solid #E5EBEF' }}
          >
            <div className="flex flex-col">
              <TextCustom
                className="text-xs fontPSemiBold"
                text={`Valor Cuota`}
                color="general"
              />
              <TextCustom
                className="text-3xl fontPSemiBold"
                text={`${pagoActual.valorCuota}`}
                color="general"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardPrecio;
