import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Components
import { Loader } from '../../atoms';
import { Etapa, TituloPage } from '../../molecules';
import TablePaginationServerSide from '../../templates/TablePaginationServerSide';
// Const
import { columnsTableOportunidades } from '../../../common/tables';
import { typesTableActions } from '../../../common/types';

// Funciones
import { buscarPermiso } from '../../../core/utils';
import { categoriasFormateadas } from '../../../core/utils/functions';

// Hooks
import { AuthContext } from '../../../hooks/context';
import useQueryOportunidades from '../../../hooks/queries/oportunidades/useQueryOportunidades';
import useQueryContarEtapasOportunidades from '../../../hooks/queries/oportunidades/useQueryContarEtapasOportunidades';
import { cambiarTituloCodigo } from '../../../core/utils/functions';

const { tableView } = typesTableActions;

const Oportunidades = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const filtroRef = useRef('');
  const [permisoGetEtapas, setPermisoGetEtapas] = useState(false);

  //context
  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;
  const [filtro] = useState(JSON.parse(sessionStorage.getItem(`filterOportunidades`)) || '',);
  const [value] = useState(JSON.parse(sessionStorage.getItem(`searchOportunidades`)) || '',);
  const [fecha] = useState(JSON.parse(sessionStorage.getItem(`dateOportunidades`)) || '',);
  const [activeEtapa] = useState(JSON.parse(sessionStorage.getItem("etapaSeleccionada")) || '');

  const [filterParams, setFilterParams] = useState({
    limit: 10,
    page: 0,
    ...(filtro && { codSeguro: filtro }),
    ...(value && { search: value }),
    ...(fecha && { fecha_inicio: fecha.split(" ")[0], fecha_fin: fecha.split(" ")[1] }),
    ...(activeEtapa && { codEtapa: cambiarTituloCodigo(activeEtapa) }),    
  });

  const { queryOportunidadesFiltro } = useQueryOportunidades(filterParams);

  const { queryContarEtapasOportunidades } = useQueryContarEtapasOportunidades();
  const oportunidadesFiltradas = queryOportunidadesFiltro?.data?.oportunidades ?? [];

  const totalPages = queryOportunidadesFiltro?.data?.pagination?.totalPages || 0;

  const categorias = categoriasFormateadas();

  //Funcion para actualizar la data
  const { refetch } = queryOportunidadesFiltro;

  useEffect(() => { 
      setPermisoGetEtapas(buscarPermiso(permisosContext, 'etapas', 'get'));
  }, [permisosContext]);

  useEffect(() => { 
    let shouldDelay =
      'search'||"codSeguro"||"search" ||"fecha_inicio"||"codEtapa" in filterParams;

    if (shouldDelay) {
      const getData = setTimeout(() => {
        refetch();
      }, 500);
      return () => clearTimeout(getData);
    }
  }, [ refetch, filterParams]);

  const handleAction = (action, id, obj) => {
    navigate(`/dashboard/oportunidades/${obj.id_oportunidad}`);
  };

  const handleFiltroSelect = valueSelect => {
    if (valueSelect === 'Todo') {
      setFilterParams({ ...filterParams, codSeguro: '' });
    } else {
      setFilterParams({ ...filterParams, codSeguro: valueSelect });
    }
  };

  const handleFiltroButtom = value => {
    setFilterParams({ ...filterParams, codEtapa: value, page: 0 });
    //Funcionalidad de mantener filtros a recargar pagina
    // const objectEtapa = { codEtapa: value };
    // localStorage.setItem("etapa", JSON.stringify(objectEtapa));
  };

  return (
    <>
      {queryOportunidadesFiltro.isPending && <Loader />}
      <div className="mb-10 overflow-hidden">
        <TituloPage
          titulo={` ${
            location.state?.oportunidadCotizacion
              ? 'Oportunidad'
              : 'Oportunidades'
          } `}
        />
      </div>

      {permisoGetEtapas && (
        <div
          className={`flex flex-nowrap gap-items mb-9 ${
            location.state?.oportunidadCotizacion ? 'hidden' : 'block'
          }`}
        >
          {queryContarEtapasOportunidades.isFetching && <Loader />}
          {queryContarEtapasOportunidades.isSuccess && (
            <Etapa
              etapas={queryContarEtapasOportunidades?.data?.conteo?.data}
              setearFiltroGlobal={handleFiltroButtom}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          )}
        </div>
      )}

      <TablePaginationServerSide
        data={oportunidadesFiltradas}
        dataExport={{
          fileName: 'oportunidades',
          data: queryOportunidadesFiltro?.data?.oportunidades
            .filter(op => op.estado === 'Ganada')
            .map(op => ({
              Tipo: op.filtro,
              Contacto: op.contacto.trim(),
              'Fecha creada': op.fecha_creacion_oportunidad,
              Aliado: op.aliado_oportunidad,
              Valor: op.valor,
              Estado: op.estado,
            })),
        }}
        columns={columnsTableOportunidades}
        identifierHidden={[
          'filtro',
          'id_oportunidad',
          'nombre1Apellidos',
          'nombre2Apellidos',
          'nombre1Apellido2',
          'nombre2Apellido2',
        ]}
        actions={[tableView]}
        actionClick={(action, id, obj) => handleAction(action, id, obj)}
        filterHeader={true}
        isSearch={true}
        isFilter={true}
        isLoading={queryOportunidadesFiltro.isFetching}
        centrar={true}
        optionsFilter={categorias || []}
        valueFilter={filtroRef.current}
        handleFiltroSelect={handleFiltroSelect}
        haveContenido={oportunidadesFiltradas.length > 0}
        textIfNotContent="No hay oportunidades por mostrar"
        tablePaginationDynamic={true}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        totalPages={totalPages}
        type="Oportunidades"
        isFilterDate={true}
      />
    </>
  );
};

export default Oportunidades;
