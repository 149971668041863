import { useEffect, useState } from 'react';

// Components
import IconButtonCustom from '../IconButtonCustom';

// Assets
import { ReactComponent as AdvertenciaIcon } from '../../../assets/icons/Alerts/Advertencia.svg';
import { ReactComponent as ListoIcon } from '../../../assets/icons/Alerts/Listo.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/Alerts/Error.svg';
import { ReactComponent as AlertCrossIcon } from '../../../assets/icons/Alerts/AlertCrossIcon.svg';

// Styles
import './style.css';

// Utils
import { renderColor } from '../../../core/utils';

const AlertCustom = ({
  description = '',
  open = true,
  resetValues = () => null,
  severity = 'info',
  size = '380px',
  title = '',
  setOpen = () => null,
}) => {
  const [showMore, setShowMore] = useState(true);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    if (open) {
      let timeout = 0;
      switch (severity) {
        case 'danger':
          timeout = 44000;
          break;
        case 'warning':
          timeout = 3000;
          break;
        case 'success':
          timeout = 2000;
          break;
        case 'info':
          timeout = 3000;
          break;
        default:
          timeout = 2000;
          break;
      }
      if (!pause) {
        let id = setInterval(() => {
          resetValues();
          setShowMore(true);
        }, timeout);
        return () => clearInterval(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, pause]);

  const renderTitle = () => {
    let text = '';
    if (title) {
      text = title;
    } else {
      switch (severity) {
        case 'danger':
          text = 'Error';
          break;
        case 'warning':
          text = 'Advertencia';
          break;
        case 'success':
          text = 'Listo';
          break;
        case 'info':
          text = 'Información';
          break;
        default:
          text = 'Información';
          break;
      }
    }
    return text;
  };

  const renderIcon = () => {
    let icon = null;
    switch (severity) {
      case 'info':
        icon = <AdvertenciaIcon />;
        break;
      case 'error':
        icon = <ErrorIcon />;
        break;
      case 'warning':
        icon = <AdvertenciaIcon />;
        break;
      case 'success':
        icon = <ListoIcon />;
        break;
      default:
        icon = <AdvertenciaIcon />;
        break;
    }
    return icon;
  };

  const renderColorAlert = () => {
    let color = '';
    switch (severity) {
      case 'error':
        color = renderColor('danger');
        break;
      case 'warning':
        color = renderColor('warning');
        break;
      case 'success':
        color = renderColor('success');
        break;
      case 'info':
        color = renderColor('primary');
        break;
      default:
        color = renderColor('primary');
        break;
    }
    return color;
  };

  const renderTypeColor = () => {
    let type = '';
    switch (severity) {
      case 'error':
        type = 'danger';
        break;
      case 'warning':
        type = 'warning';
        break;
      case 'success':
        type = 'success';
        break;
      case 'info':
        type = 'primary';
        break;
      default:
        type = 'primary';
        break;
    }
    return type;
  };

  const handleClose = () => {
    resetValues();
    document.getElementById(severity).children[0].style.transform = 'none';
    setOpen(!open);
  };

  const handleClick = () => {
    setShowMore(!showMore);
    if (showMore) {
      document.getElementById(severity).children[0].style.transform = 'none';
    } else {
      document.getElementById(severity).children[0].style.transform =
        'scale3d(1.5, 1.5, 1.5)';
    }
  };

  return (
    <div className={`popup ${open ? 'popup-open' : 'popup-close'} `}>
      <div className="flex justify-center sm:justify-end">
        <div className="flex w-[95%] justify-end  md:w-full ">
          <div
            className="popup-alert"
            onMouseEnter={() => setPause(true)}
            onMouseLeave={() => setPause(false)}
            style={{
              width: size,
              backgroundColor: renderColor('white'),
              borderRadius: '20px',
            }}
          >
            <div
              className="flex relative justify-between h-[5rem]"
              style={{
                borderRadius: '20px',
                boxShadow: '1px 1px 10px #D2E4EA',
              }}
            >
              <div
                className="flex gap-3"
                style={{ cursor: 'pointer' }}
                onClick={handleClick}
              >
                <div
                  className="alert-icon justify-center min-w-[4.5rem]"
                  id={severity}
                  style={{
                    background: renderColorAlert(),
                    borderRadius: '20px 0 0 20px',
                  }}
                >
                  {renderIcon()}
                </div>
                <div className="fontLBold text-base flex flex-col justify-center">
                  <div
                    className="fontPSemiBold"
                    style={{ color: renderColorAlert() }}
                  >
                    {renderTitle()}
                  </div>
                  <div className="fontPRegular text-[#222222] text-sm">
                    {description}
                  </div>
                </div>
              </div>
              <div className="mr-4 flex justify-center items-center">
                <IconButtonCustom
                  onClick={handleClose}
                  size={14}
                  backgroundColor={renderColor('general')}
                  typeColorHover={renderColor('general')}
                  icon={<AlertCrossIcon fill={renderColor('white')} />}
                  padding="6px"
                  typeColor={renderTypeColor()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertCustom;
