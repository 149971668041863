import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//storage
import {
  stRemoveIsAuth,
  stRemoveListPermisos,
  stRemovePersonalInfo,
  stRemoveToken,
} from '../../../services/storage';

//hooks
import { AuthContext } from '../../../hooks/context';

import { typesGlobalState } from '../../../common/types';

const { authLogout } = typesGlobalState;

const Error401 = () => {
  const navigate = useNavigate();

  const { dispatchAuth } = useContext(AuthContext);

  useEffect(() => {
    dispatchAuth({ type: authLogout });
    navigate('/login');
    stRemoveIsAuth();
    stRemovePersonalInfo();
    stRemoveToken();
    stRemoveListPermisos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contenedor-page-error flex justify-center items-center h-screen w-screen flex-col bg-white absolute top-0 gap-5"></div>
  );
};

export default Error401;
