import { colors } from '../../styles/theme';

const { general, secondary, primary } = colors;

const TableState = ({
  className = '',
  size = 8,
  marginRight = 12,
  state = '',
}) => {
  const renderColor = () => {
    let color = null;
    switch (state) {
      case 'ACTIVO':
        color = '#2DA54B';
        break;
      case 'INACTIVO':
        color = '#EB2341';
        break;
      case 'VIGENTE':
        color = '#2DA54B';
        break;
      case 'EXPIRADO':
        color = '#EB2341';
        break;
      case 'Cotización':
        color = general;
        break;
      case 'Negociación':
        color = secondary;
        break;
      case 'Documentación':
        color = primary;
        break;
      case 'Pago':
        color = '#76BC21';
        break;
      case 'Emisión':
        color = '#EDA93B';
        break;
      case 'Ganada':
        color = '#2C7E21';
        break;
      case 'Dormida':
        color = '#AAAAAA';
        break;
      case 'Perdida':
        color = '#DD2254';
        break;
      default:
        color = 'gray';
        break;
    }
    return color;
  };

  const renderLabel = () => {
    let label = null;
    switch (state) {
      case 'ACTIVO':
        label = 'Activo';
        break;
      case 'INACTIVO':
        label = 'Inactivo';
        break;
      case 'VIGENTE':
        label = 'Vigente';
        break;
      case 'EXPIRADO':
        label = 'Expirado';
        break;
      case 'Cotización':
        label = 'Cotización';
        break;
      case 'Negociación':
        label = 'Negociación';
        break;
      case 'Documentación':
        label = 'Documentación';
        break;
      case 'Pago':
        label = 'Pago';
        break;
      case 'Emisión':
        label = 'Emisión';
        break;
      case 'Ganada':
        label = 'Ganada';
        break;
      case 'Dormida':
        label = 'Dormida';
        break;
      case 'Perdida':
        label = 'Perdida';
        break;
      default:
        label = 'No definido';
        break;
    }
    return label;
  };
  return (
    <div className={`flex justify-start items-center ${className}`}>
      <div
        style={{
          width: size,
          height: size,
          background: renderColor(),
          borderRadius: size,
          marginRight,
        }}
      ></div>
      {renderLabel()}
    </div>
  );
};

export default TableState;
