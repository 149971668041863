import TextCustom from '../TextCustom';
import { formatearFechaBitacora } from '../../../core/utils';

import './styles.css';

const ItemBitacora = ({ bitacora, colorSecondary, view, index }) => {
  const { etapa, usuario } = bitacora;
  return (
    <div className={`mb-5 ${index > 6 && !view ? 'hidden' : 'block'}`}>
      <div className="flex justify-between items-center">
        <TextCustom
          text={etapa?.nombre}
          color="general"
          className="fontPSemiBold text-base"
          component="p"
          variant="p"
        />
        <p className="text-sm fontPRegular color-general">
          Por:{' '}
          <span className="fontPSemiBold">
            {usuario?.nombre || 'Cotizador Asegurame'}
          </span>{' '}
          - {formatearFechaBitacora(bitacora?.fecha)}
        </p>
      </div>

      <div className={`mt-3 grid-item-bitacora bg-[#F8FBFD] rounded-[10px]`}>
        <p
          className="color-general fontPSemiBold py-3 px-6 rounded-[10px] text-center h-full"
          style={{ backgroundColor: colorSecondary?.codigo }}
        >
          Día {bitacora?.dia}
        </p>
        {bitacora.descripcion.includes(':') ? (
          <div>
            <TextCustom
              text={bitacora.descripcion.split(':')[0] + ':'}
              className="font-bold "
            />{' '}
            <TextCustom text={bitacora.descripcion.split(':')[1]} />
          </div>
        ) : (
          <TextCustom text={bitacora?.descripcion} />
        )}
      </div>
    </div>
  );
};

export default ItemBitacora;
