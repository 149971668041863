import dayjs from 'dayjs';

export const getLegalDate = () => {
  const today = new Date();
  const legalDate = new Date(
    today.getUTCFullYear() - 18,
    today.getUTCMonth(),
    today.getUTCDate(),
  );
  return legalDate;
};

export const formatearFecha = fecha => {
  const nuevaFecha = new Date(fecha);

  return nuevaFecha.toLocaleDateString('es-Es', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};

export const formatearFechaNacimiento = fechaData => {
  require('dayjs/locale/es');
  const fecha = fechaData;

  // convertir la fecha a un objeto de fecha de Day.js y establecer el idioma
  const fechaFormateada = dayjs(fecha, 'DD/MM/YYYY')
    .locale('es')
    .format('DD [de] MMMM, YYYY');

  return fechaFormateada;
};

export const calcularDias = (fecha1, fecha2) => {
  // Parsear las fechas en formato DD/MM/YYYY
  const fecha1Parsed = dayjs(fecha1, 'DD/MM/YYYY');
  const fecha2Parsed = dayjs(fecha2, 'DD/MM/YYYY');

  // Calcular la diferencia en días
  return fecha2Parsed.diff(fecha1Parsed, 'day');
};

export const formatearFechaBitacora = fecha => {
  require('dayjs/locale/es');
  require('dayjs/plugin/relativeTime');
  dayjs.extend(require('dayjs/plugin/relativeTime'));

  const fechaOriginal = fecha;

  // Obtener la fecha actual
  const fechaActual = dayjs();
  let fechaFormateada;

  // Si la fecha es hoy
  if (dayjs(fechaOriginal).isSame(fechaActual, 'day')) {
    fechaFormateada = dayjs(fechaOriginal).locale('es').format('[Hoy], h:mm A');

    // Si la fecha es ayer
  } else if (
    dayjs(fechaOriginal).isSame(fechaActual.subtract(1, 'day'), 'day')
  ) {
    fechaFormateada = dayjs(fechaOriginal)
      .locale('es')
      .format('[Ayer], h:mm A');
  } else {
    // Si la fecha es anterior a ayer
    fechaFormateada = dayjs(fechaOriginal)
      .locale('es')
      .format('D [de] MMMM, YYYY, h:mm A');
  }

  return fechaFormateada;
};


export const formatearFecha2 = fecha => {
  const date = new Date(fecha);

    const año = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, '0');
    const dia = String(date.getDate()).padStart(2, '0');

    return isValidDate(date) ? `${año}-${mes}-${dia}` : null;
}
 
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
