import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import {
  TextCustom,
  AlertCustom,
  Loader,
  TituloCard,
  LabelTexto,
  IOSSwitchCustom,
  SelectCustom,
} from '../../atoms';
import { TituloPerfil } from '../../molecules';
import { DialogResetPassword } from '../../organisms';

// hooks
import { useForm } from '../../../hooks/others';

// api
import {
  apiCambiarEstadoUsuario,
  apiDetalleUsuarios,
  // apiEditRolUser,
} from '../../../services';

// Utils
import {
  buscarPermiso,
  generarPasswordRamdon,
  renderInicialNombreAliado,
} from '../../../core/utils';
import { AuthContext } from '../../../hooks/context';
import { TableVistaUsuario } from '../../templates';
import { useActualizarRolUsuario } from '../../../hooks/usuarios/actualizarRolUser';
import { useGetRoles } from '../../../hooks/roles/useGetRoles';

const VistaUsuario = () => {
  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext, personalInfo} = auth;

  const navigate = useNavigate();
  const params = useParams();

  const [detalleUsuario, setDetalleUsuario] = useState({});
  const [cargando, setCargando] = useState(true);
  const [permisoPut, setPermisoPut] = useState(false);
  const [showAgregar, setShowAgregar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deshabilitar, setDeshabilitar] = useState(false);
  const [passwordTemporal, setPasswordTemporal] = useState('');
  const [rol, setRol] = useState('');
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  const { actualizarRol } = useActualizarRolUsuario(setAlert);
  const { roles } = useGetRoles();

  const handleEditarRol = async rol => {
    await actualizarRol(rol, params.id, usuario);
  };

  useEffect(() => {
    obtenerDetalleUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setRol(detalleUsuario.usuario?.tipoUsuario);
  }, [detalleUsuario]);

  useEffect(() => {
    setPermisoPut(buscarPermiso(permisosContext, 'usuarios', 'patch'));
  }, [permisosContext]);

  const obtenerDetalleUsuario = async () => {
    setDeshabilitar(true);
    const { success, data, statusCode } = await apiDetalleUsuarios(params.id);
    if (success) {
      setDetalleUsuario(data);
      setRol(data.usuario.tipoUsuario);
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
    setCargando(false);
    setDeshabilitar(false);
  };

  const handleBack = () => {
    navigate('/dashboard/configuration/usuarios');
  };

  const handleCambiarPassword = () => {
    setPasswordTemporal(generarPasswordRamdon());

    setShowAgregar(true);
  };

  const handleCambiarEstado = async () => {
    setDeshabilitar(true);
    const { success, message, statusCode } = await apiCambiarEstadoUsuario(
      params.id,
    );
    if (success) {
      setAlert({
        title: '¡Cambio de estado!',
        description: message,
        open: true,
        severity: 'success',
      });

      obtenerDetalleUsuario();
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'error',
      });
    }
    setDeshabilitar(false);
  };

  const handleRol = val => {
    setRol(val);
    handleEditarRol(val);
  };

  const { usuario, permisos } = detalleUsuario;

  return (
    <>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      {cargando && <Loader />}
      <div className="flex justify-between items-center">
        <TituloPerfil
          handleBack={handleBack}
          titulo={'Usuario: ' + usuario?.nombre}
        />
        {permisoPut && (
          <IOSSwitchCustom
            showLabel={false}
            value={usuario?.estado}
            setValue={handleCambiarEstado}
            height={32}
            width={60}
            disabled={deshabilitar}
          />
        )}
      </div>

      <div className="pt-10 grid grid-cols-5 gap-4 pb-2">
        <div className="grid col-span-3 gap-4 ">
          <div className="bg-card">
            <div className="grid-card-personal">
              <div className="relative flex justify-center items-center">
                {renderInicialNombreAliado(usuario?.nombre)}
                {/* <EditarIcon
                  className="cursor-pointer absolute w-[30px] h-[30px] btn-editar"
                  onClick={() => window.alert('editar foto perfil...')}
                /> */}
              </div>
              <div className="px-3 relative">
                <TituloCard titulo="Datos Personales" />
                <div className="bg-primary px-3 rounded-lg cursor-pointer absolute top-0 right-5 py-1 flex justify-center">
                  <span
                    className="color-white fontPSemiBold text-xs"
                    onClick={handleCambiarPassword}
                  >
                    Cambiar Contraseña
                  </span>
                </div>

                <div className="grid sm:grid-cols-2 2xl:grid-cols-2 items-center gap-x-2 gap-y-7 mt-6">
                  <div>
                    <TextCustom text="Nombre" className="text-sm" />
                    <TextCustom
                      text={`${usuario?.nombre}`}
                      component="p"
                      variant="p"
                      className="fontPSemiBold "
                    />
                  </div>
                  <div>
                    <TextCustom text="Correo Electrónico" className="text-sm" />
                    <br />
                    <TextCustom
                      text={usuario?.correo}
                      component="p"
                      variant="p"
                      className="fontPSemiBold text-max"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {personalInfo?.rol === 'Operador' ? (
          <div className="bg-card">
            <TituloCard titulo="Roles" />

            <div className="flex items-center h-5/6">
              <TextCustom
                text={usuario?.tipoUsuario ?? 'N/A'}
                className="fontPSemiBold underline cursor-pointer"
              />
            </div>
          </div>
        ) : (
          <div className="bg-card">
            <TituloCard titulo="Roles" />
            <div className="flex items-center h-5/6">
              <SelectCustom
                className=" w-full  max-sm:w-20 max-md:w-20 max-lg:w-20 max-xl:w-20"
                fontSize="14"
                value={rol}
                options={roles}
                setValue={handleRol}
              />
            </div>
          </div>
        )}

        <div className="col-span-1 bg-card">
          <TituloCard titulo="Estado" />
          <div className="p-5 flex items-center h-5/6">
            <LabelTexto
              color={{
                codigo: `${usuario?.estado ? '#76BC21' : '#EB2341'}`,
              }}
              texto={`${usuario?.estado ? 'Activo' : 'Inactivo'}`}
              className="fontPSemiBold underline cursor-pointer"
              key={'Activo'}
            />
          </div>
        </div>
      </div>
      {permisoPut && (
        <div className="mt-8">
          <TituloCard titulo="Permisos" />
          <div className="mt-6">
            <TableVistaUsuario permisosUsuario={permisos} />
          </div>
        </div>
      )}

      {/* ventanas modales */}
      <DialogResetPassword
        isOpenModal={showAgregar}
        setIsOpenModal={setShowAgregar}
        showAlert={showModal}
        setShowAlert={setShowModal}
        password={passwordTemporal}
      />
    </>
  );
};

export default VistaUsuario;
