import axios from 'axios';
import { endpoints } from '../../../common/endpoints';
import buildRequest from '../../buildRequest';
import { stGetToken } from '../../storage';

export const getApiCotizaciones = async (params) => {
  const url = `${process.env.REACT_APP_api}${endpoints.getCotizaciones}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
    pagination : {}
  };
  const { data: token } = stGetToken();

  const request = {
    ...params
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));
    const { data } = response;
    dataResponse.success = true;
    dataResponse.data = data.data.data;
    dataResponse.pagination = data.data.pagination
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};


export const getApiDetalleCotizacion = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getCotizaciones}/cotizador`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idCotizacion: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const getApiObtenerCotizacionesAlido = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getCotizacionesAliado}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const request = {
    idAliado: params,
  };

  const { data: token } = stGetToken();

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
