import { useContext } from 'react';

// Hooks
import { AuthContext } from '../../../hooks/context';

// Components
import { TextCustom } from '../../atoms';
import { AppBar, IconButton, Toolbar } from '@mui/material';

// Assets
import MenuIcon from '@mui/icons-material/Menu';

//style
import './style.css';

// Utils
import { renderColor } from '../../../core/utils';

const HeaderMenu = ({ drawerWidth, handleDrawerToggle }) => {
  const { auth } = useContext(AuthContext);

  const { personalInfo } = auth;

  return (
    <AppBar
      position="fixed"
      elevation={2}
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        backgroundColor: renderColor('white'),
        boxShadow: 'none',
        borderBottom: `1px solid ${renderColor('line')}`,
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <div className="w-full flex justify-end">
          <div className="flex items-center gap-3 pr-16">
            <div
              className="rounded-full w-9 h-9 flex items-center justify-center"
              style={{ backgroundColor: renderColor('fondo') }}
            >
              <TextCustom
                text={personalInfo.nombre.substring(0, 2).toUpperCase()}
                className="fontPSemiBold"
                color="general"
              />
            </div>
            <div>
              <TextCustom
                text={'Hola, '}
                className="fontPRegular text-right"
                color="general"
              />
              <TextCustom
                text={personalInfo.nombre}
                className="fontPSemiBold text-right"
                color="general"
              />
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMenu;
