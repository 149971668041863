// Components
import { IconButtonCustom, TextCustom } from '../../atoms';

// Assets
import { ReactComponent as PrevIcon } from '../../../assets/icons/tablas/PrevIcon.svg';

const TituloPerfil = ({
  handleBack = () => null,
  titulo = '',
  subtitulo = '',
  icono = null,
  className = '',
}) => {
  return (
    <div className={`flex gap-3 items-end ${className}`}>
      <IconButtonCustom
        icon={<PrevIcon />}
        size={30}
        className="p-0"
        onClick={handleBack}
      />
      <div className="flex flex-col justify-center">
        {subtitulo && (
          <TextCustom
            text={subtitulo}
            className="fontPSemiBold leading-4"
            color="general"
          />
        )}
        <TextCustom
          text={titulo}
          variant="h3"
          className="fontPSemiBold"
          color="general"
        />
      </div>
      <div className="flex items-end pb-2">{icono}</div>
    </div>
  );
};

export default TituloPerfil;
