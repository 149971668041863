// Utils
import { formatearFecha, renderTipoSeguro } from '../../../core/utils';

import { useQuery } from '@tanstack/react-query';

// Services
import { getApiCotizaciones } from '../../../services';
import { useOportunidad } from '../../../hooks/others';

const useQueryCotizaciones = (filterParams) => {
  const { categorias } = useOportunidad();
  const queryCotizaciones = useQuery({
    refetchOnWindowFocus: false,
    enabled: false,
    queryKey: ['cotizaciones'],
    queryFn: async () => {
      const cotizaciones = await getApiCotizaciones(filterParams);
       const {pagination} = cotizaciones;

      const cotizacionesFormateadas = cotizaciones.data.map(cotizacion =>
        formateoDataCotizacion(cotizacion),
      );

       //categorias necesarias para el filtro
       const categoriasFormateadas = categorias.map(categoria => ({
        label: categoria?.nombre,
        id: categoria?.nombre,
      }));

      return {
        cotizaciones: cotizacionesFormateadas,
        categorias: categoriasFormateadas,
        pagination: pagination
      }
    },
  });

  const formateoDataCotizacion = cotizacion => {
    const categoria = categorias.find(
      categoria => categoria._id === cotizacion?.seguro._id,
    );


    return {
      id_cotizacion: cotizacion._id,
      filtro: categoria.nombre,
      nombre_seguro: renderTipoSeguro(categoria.codigo, '', categoria.nombre),
      contacto: `${cotizacion.persona.primer_nombre} ${cotizacion.persona.segundo_apellido}   `,
      ultima_actualizacion: formatearFecha(cotizacion.updatedAt),
      fecha_creacion: formatearFecha(cotizacion.createdAt).toUpperCase(),
      aliado: cotizacion?.aliado?.nombre ?? '',
      id_aliado: cotizacion?.aliado?._id,
    };
  };

  return { queryCotizaciones };
};

export default useQueryCotizaciones;
