import axios from 'axios';
import { endpoints } from '../../../common/endpoints';
import buildRequest from '../../buildRequest';
import { stGetToken } from '../../storage/storageToken';

export const getApiOportunidades = async () => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidades}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  try {
    const response = await axios[method](url, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const getApiPerfilOportunidad = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidades}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    id: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const getApiOportunidadPorCotizacion = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidadCotizacion}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idCotizacion: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const getApiOportunidadesPorAliado = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidadAliado}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
    etapas: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idAliado: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.etapas = data.etapas;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const getApiOportunidadesPorContacto = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidadContacto}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
    etapas: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idContacto: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.etapas = data.etapas;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const putApiOportunidad = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidades}`;
  const method = 'patch';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    etapaAnterior: params.etapaAnterior,
    etapa: params.etapa,
    oportunidadId: params.oportunidad,
    planId: params.plan,
    mensajeBitacora: params.mensajeBitacora,
    razonEtapa: params.razonEtapa,
  };

  const queryParams = {
    isRetrocediendo: params.isRetrocediendo,
  };

  try {
    const response = await axios[method](
      url,
      request,
      buildRequest(token, queryParams),
    );

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.error = error.response.data.error;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const postApiOportunidad = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidades}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    oportunidadId: params.oportunidad,
    cotizacionId: params.cotizacion,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.error = error.response.data.error;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};


export const apiGenerarExcel = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.generarExcel}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    ...params
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));
    const {  data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
}


export const apiConteoOportunidadesEtapas = async () => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidadesEtapas}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();


  try {
    const response = await axios[method](url, buildRequest(token));
    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data.etapas;
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
}

export const apiOportunidadesPagination = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.getOportunidadesPagination}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
    pagination : {}
  };

  const { data: token } = stGetToken();

  const request = {
    ...params
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));
    const {  data } = response;
    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.pagination = data.data.pagination
  } catch (error) {
    dataResponse.data = error;
    dataResponse.statusCode = error.response?.status;
  }
  return dataResponse;
}