import { useEffect, useState } from 'react';

// Components
import { ButtonGroupCustom, TextCustom, TextInputCustom } from '../../atoms';

// Services
import { apiDivisas } from '../../../services';

// hooks
import { useOportunidad } from '../../../hooks/others';

//validaciones
import { typesValidation } from '../../../common/types';

const Divisas = () => {
  const { valorDivisa, setValorDivisa } = useOportunidad();
  const [obteniendoDivisa, setObteniendoDivisa] = useState(false);
  const [inputDivisa, setInputDivisa] = useState(valorDivisa);

  const optionsBtn = [
    {
      label: '$',
      moneda: 'USD',
    },
    {
      label: '₡',
      moneda: 'CRC',
    },
  ];

  useEffect(() => {
    const obtenerDivisas = async () => {
      setObteniendoDivisa(true);
      try {
        if (!valorDivisa) {
          const { data, success } = await apiDivisas();

          if (success) {
            setValorDivisa(data);
            setInputDivisa(data);
          }
        }

        // divisaRef.current = data?.quotes;
      } catch (error) {
        console.log(error);
      }
      setObteniendoDivisa(false);
    };

    obtenerDivisas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (inputDivisa) {
      setValorDivisa(inputDivisa);
    }
  };

  return (
    <div className="flex gap-3 items-center">
      {obteniendoDivisa ? (
        <TextCustom
          text="Obteniendo Divisas..."
          color="general"
          className="font-semibold"
        />
      ) : (
        <>
          <ButtonGroupCustom options={optionsBtn} />
          <TextInputCustom
            type="text"
            value={inputDivisa}
            setValue={setInputDivisa}
            borderStyle={true}
            className="input-divisa"
            maxLength={8}
            typesValidation={typesValidation.onlyNumber}
          />
          <div
            onClick={handleClick}
            className="btn-divisa fontPSemiBold text-white text-xs"
          >
            Aplicar
          </div>
        </>
      )}
    </div>
  );
};

export default Divisas;
