import { useEffect, useState } from "react";

// Assets
import { ReactComponent as NextIcon } from "../../../assets/icons/tablas/NextIcon.svg";
import { ReactComponent as PrevIcon } from "../../../assets/icons/tablas/PrevIcon.svg";
// Styles
import { IconButtonCustom } from "../../atoms";

// Utils
import { renderColor } from "../../../core/utils";
import { getPagination } from "../../../core/utils/pagination";

const TablePaginationDynamic = ({
  canPreviousPage = false,
  canNextPage = false,
  gotoPage = () => null,
  nextPage = () => null,
  pageIndex = 1,
  previousPage = () => null,
  filterParams,
  setFilterParams,
  totalPages = 0,
}) => {
  const [pagesShow, setPagesShow] = useState([]);
  
  const arrayPages = (totalPages) => {
    let arrayPages = [];
    for (let i = 0; i < totalPages; i++) {
      arrayPages.push(i);
    }

    return arrayPages;
  };

  useEffect(() => {
    const pages = arrayPages(totalPages);
    const pagination = getPagination(pages, pageIndex, 2);
    setPagesShow(pagination);
  }, [pageIndex, totalPages]);



  return (
    <div className="flex gap-2 justify-center items-center mt-5">
      <IconButtonCustom
        icon={<PrevIcon />}
        onClick={previousPage}
        disabled={!canPreviousPage}
        size="small"
        className="p-0"
      />
      {pagesShow.map((page, index) => (
        <div
          key={index}
          className={`flex justify-center items-center w-6 h-6 cursor-pointer rounded-full`}
          style={{
            color: `${
              pageIndex === page ? renderColor("white") : renderColor("general")
            }`,
            backgroundColor: `${
              pageIndex === page ? renderColor("general") : renderColor("fondo")
            }`,
          }}
          onClick={() => {
            if (page !== "...") {
              setFilterParams({ ...filterParams, page: pageIndex + 1});
            }
            gotoPage(page);
          }}
        >
          <span className="text-center fontPBold text-xs">
            {page !== "..." ? page + 1 : page}
          </span>
        </div>
      ))}
      <IconButtonCustom
        icon={<NextIcon />}
        onClick={() => {
          if (canNextPage) {
            setFilterParams({ ...filterParams, page: pageIndex + 1 });
             
            nextPage();
          }
        }}
        disabled={!canNextPage}
        size="small"
        className="p-0"
      />
    </div>
  );
};

export default TablePaginationDynamic;
