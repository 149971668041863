import { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import {
  LabelTexto,
  TextCustom,
  AlertCustom,
  ButtonCustom,
  Loader,
  TituloCard,
} from '../../atoms';
import {
  ButtonCopyClipboard,
  CardContacto,
  CardUsuariosAliado,
  Divisas,
  TituloPerfil,
  ChartCanalizacionVentas,
  ChartVentasPorMes,
  ChartOportunidadesPorEtapa,
} from '../../molecules';
import { DialogAgregarAliado } from '../../organisms';

// Hooks
import { useForm, useOportunidad } from '../../../hooks/others';
import { AuthContext } from '../../../hooks/context';

// Api
import { getApiDetalleAliado, putApiAliados } from '../../../services';

// Assets
import { ReactComponent as EditarIcon } from '../../../assets/icons/Icono-Editar.svg';

// Styles
import './style.css';

// Utils
import {
  buscarPermiso,
  formatearIdentificacion,
  renderInicialNombreAliado,
} from '../../../core/utils';

const PerfilAliado = () => {
  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  const navigate = useNavigate();
  const params = useParams();

  const { infoEtapas } = useOportunidad();

  const [detalleAliado, setDetalleAliado] = useState({});
  const [cargando, setCargando] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [permisoPut, setPermisoPut] = useState(false);

  const [permisoGetEtapas, setPermisoGetEtapas] = useState(false);
  const [toolTip, setToolTip] = useState('Copiar');
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  const inputRef = useRef(null);

  useEffect(() => {
    obtenerDetalleAliado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setPermisoPut(buscarPermiso(permisosContext, 'aliados', 'patch'));
    setPermisoGetEtapas(buscarPermiso(permisosContext, 'etapas', 'get'));
  }, [permisosContext]);

  const obtenerDetalleAliado = async () => {
    const { success, data, statusCode } = await getApiDetalleAliado(params.id);

    if (success) {
      setDetalleAliado(data.data);
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
    setCargando(false);
  };

  const handleBack = () => {
    navigate('/dashboard/aliados/');
  };

  const handleCopyClick = async () => {
    navigator.clipboard.writeText(inputRef.current.value);
    setToolTip('Copiado');

    setTimeout(() => {
      setToolTip('Copiar');
    }, 2000);
  };

  const handleFiltrarCotizaciones = aliado => {
    navigate(`/dashboard/cotizaciones?aliado=${aliado?.nombre}`);
  };
  const handleFiltrarOportunidades = aliado => {
    navigate(`/dashboard/oportunidades?aliado=${aliado?.nombre}`);
  };

  const handleSubmit = async params => {
    const respuesta = await putApiAliados(params);
    const { success, message, statusCode, error } = respuesta;

    if (success) {
      setShowModal(false);
      obtenerDetalleAliado();
      setAlert({
        title: '¡Aliado actualizado!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: error,
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  return (
    <>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      {cargando && <Loader />}
      <div className="flex justify-between items-center">
        <TituloPerfil handleBack={handleBack} titulo={detalleAliado?.nombre} />
        <div className="flex gap-3 items-center">
          {detalleAliado?.cotizaciones && (
            <ButtonCustom
              text="Ver cotizaciones"
              width="200px"
              height="50px"
              className="underline"
              onClick={() => handleFiltrarCotizaciones(detalleAliado)}
              ruta="cotizaciones"
              metodo="get"
            />
          )}
          {detalleAliado?.oportunidades && (
            <ButtonCustom
              text="Ver oportunidades"
              width="200px"
              height="50px"
              typeColor="general"
              className="underline"
              onClick={() => handleFiltrarOportunidades(detalleAliado)}
              ruta="oportunidades"
              metodo="get"
            />
          )}
          {permisoPut && (
            <EditarIcon
              className="cursor-pointer"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </div>

      <div className="pt-10 grid grid-cols-4 gap-4 pb-2">
        <div className="grid col-span-2 gap-4">
          <div className="bg-card">
            <div className="grid-card-personal">
              <div className="relative flex justify-center items-center">
                {renderInicialNombreAliado(detalleAliado?.nombre)}
                {/* <EditarIcon
                  className="cursor-pointer absolute w-[30px] h-[30px] btn-editar"
                  onClick={() => window.alert('editar foto perfil...')}
                /> */}
              </div>
              <div className="px-3">
                <TituloCard titulo="Datos Personales" />
                <div className="grid sm:grid-cols-2 2xl:grid-cols-2 items-center gap-x-2 gap-y-7 mt-6">
                  <div>
                    <TextCustom text="Nombre" className="text-sm" />
                    <br />
                    <TextCustom
                      text={`${detalleAliado?.nombre}`}
                      component="p"
                      variant="p"
                      className="fontPSemiBold"
                    />
                  </div>
                  <div>
                    <TextCustom text="N. de Cédula" className="text-sm" />
                    <br />
                    <TextCustom
                      text={formatearIdentificacion(
                        detalleAliado?.identificacion,
                      )}
                      component="p"
                      variant="p"
                      className="fontPSemiBold"
                    />
                  </div>
                  <div>
                    <TextCustom text="Código" className="text-sm" />
                    <br />
                    <TextCustom
                      text={`${detalleAliado?.codigo}`}
                      component="p"
                      variant="p"
                      className="fontPSemiBold"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-card w-[151%] ">
            <TituloCard titulo="Link" />
            <ButtonCopyClipboard
              toolTipMensaje={toolTip}
              handleCopyClick={handleCopyClick}
              inputRef={inputRef}
              valueToCopy={detalleAliado?.link_cotizacion}
              titulo={`${detalleAliado?.nombre} - Aliado`}
            />
          </div>
        </div>
        <div className="bg-card h-[61%] -z-10">
          <CardContacto
            correo={detalleAliado?.correo}
            telefono={detalleAliado?.telefono}
          />
        </div>
        <div className="bg-card">
          <CardUsuariosAliado />
        </div>
      </div>
      <div className="flex justify-between items-center my-8">
        <div>
          <TextCustom text="Tablero" variant="h6" className="fontPSemiBold" />
          {permisoGetEtapas && (
            <div className="flex gap-7">
              {infoEtapas.map(etapa => (
                <LabelTexto
                  color={etapa.colores.find(color => color.tipo === 'primary')}
                  texto={etapa.nombre}
                  className="fontPRegular text-sm"
                  key={etapa._id}
                />
              ))}
            </div>
          )}
        </div>
        <Divisas />
      </div>
      <div className="grid grid-cols-2 2xl:grid-cols-3 gap-5">
        <div className="card-tablero h-96 ">
          <ChartOportunidadesPorEtapa />
        </div>
        <div className="card-tablero h-96">
          <ChartVentasPorMes />
        </div>
        <div className="card-tablero h-96">
          <ChartCanalizacionVentas />
        </div>
      </div>
      {/* ventanas modales */}
      <DialogAgregarAliado
        isOpenModal={showModal}
        setIsOpenModal={setShowModal}
        aliado={detalleAliado}
        actionSubmit={handleSubmit}
      />
    </>
  );
};

export default PerfilAliado;
