import { useState } from "react";
import {
  renderBgEstado,
  renderIconEstado,
  renderColorEstado,
} from "../../../core/utils";
import { TextCustom } from "../../atoms";

const ConfirmacionSubidaArchivo = ({ 
    mensaje = "" 
}) => {
  // eslint-disable-next-line no-unused-vars
  const [estado, setEstado] = useState("aceptado");

  return (
    <>
      <TextCustom className="text-sm" />
      <div className="mt-4">
        <div
          className="flex rounded-md py-2 px-5 items-center gap-4"
          style={{ backgroundColor: renderBgEstado(estado) }}
        >
          <div className=" ">{renderIconEstado(estado)}</div>
          <div
            className="  fontPSemiBold text-sm cursor-pointer"
            style={{ color: renderColorEstado(estado) }}
          >
            {mensaje}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmacionSubidaArchivo;
