// Components
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextCustom } from '../';

// Utils
import { renderColor } from '../../../core/utils';

// Assets
import { ReactComponent as SelectIcon } from '../../../assets/icons/SelectIcon.svg';

const DatePickerDashboard = ({
  name = '',
  value = '',
  setValue = () => null,
  required = false,
  disabled = false,
  minDate = undefined,
  maxDate = undefined,
  msgError = '',
  className = '',
  fontSize = 18,
  open = false,
  setOpen = () => null,
  viewsPicker = ['year', 'month', 'day'],
}) => {
  const handleChange = inputValue => {
    setValue(inputValue);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <DatePicker
          views={viewsPicker}
          onClose={handleClose}
          open={open}
          value={value}
          label={name}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          className="w-100"
          inputFormat="DD/MM/YYYY"
          components={{
            SwitchViewIcon: SelectIcon,
          }}
          PopperProps={{
            sx: {
              '& .PrivatePickersMonth-root': {
                '&.Mui-selected': {
                  backgroundColor: renderColor('general'),
                  color: renderColor('white'),
                },
                '&.Mui-selected:hover': {
                  backgroundColor: renderColor('general'),
                },
                '&:disabled': {
                  opacity: '0.7',
                  color: renderColor('gray'),
                },
                '&:hover': {
                  background: renderColor('hover'),
                },
                color: renderColor('general'),
                textTransform: 'capitalize',
              },
              '& .PrivatePickersYear-root': {
                '& .Mui-selected': {
                  backgroundColor: renderColor('general'),
                  color: renderColor('white'),
                },
                '& .Mui-selected:hover': {
                  backgroundColor: renderColor('general'),
                },
                '& :hover': {
                  background: renderColor('hover'),
                },
                '& .PrivatePickersYear-yearButton': {
                  fontFamily: 'Poppins-SemiBold',
                  width: '85px',
                },
                color: renderColor('general'),
                textTransform: 'capitalize',
                flexBasis: '33%',
              },
              '& .MuiYearPicker-root': {
                padding: '10px 15px',
              },
              '& .MuiPickersCalendarHeader-root': {
                '& .MuiPickersArrowSwitcher-root': {
                  display: 'none',
                },
                '& .MuiPickersCalendarHeader-labelContainer': {
                  marginRight: 0,
                  letterSpacing: 0,
                  textTransform: 'uppercase',
                  color: renderColor('primary'),
                  fontFamily: 'Poppins-SemiBold',
                },
                paddingBottom: '10px',
                margin: '22px 15px 5px',
                justifyContent: 'center',
              },
            },
          }}
          renderInput={params => (
            <TextField
              label={name}
              size="large"
              required={required}
              style={{
                backgroundColor: 'transparent',
              }}
              sx={{
                '& legend': {
                  marginLeft: 2,
                  fontSize: fontSize * 0.82,
                },
                '& .MuiInputBase-root': {
                  '& fieldset': {
                    borderRadius: 5,
                    backgroundColor: 'transparent',
                    color: renderColor('texto'),
                  },
                  '&.Mui-focused fieldset': {
                    color: renderColor('texto'),
                    fontSize: fontSize,
                  },
                },
                '& .MuiInputLabel-asterisk': {
                  display: 'none',
                },
                '& .MuiInputLabel-shrink': {
                  marginLeft: 2,
                  color: renderColor('texto'),
                  fontSize: fontSize,
                  fontWeight: '600',
                  '& .MuiInputLabel-asterisk': {
                    color: renderColor('danger'),
                    display: 'inline',
                  },
                },
                backgroundColor: disabled
                  ? renderColor('ligthGray')
                  : renderColor('white'),
                borderRadius: 2,
                marginTop: 1,
              }}
              {...params}
            />
          )}
          disabled={disabled}
        />
      </LocalizationProvider>
      <TextCustom
        text={msgError}
        className="text-xs ml-1 mt-1 fontPRegular"
        color="danger"
      />
    </div>
  );
};

export default DatePickerDashboard;
