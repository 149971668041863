// Components
import { TextCustom, TituloCard } from '../../atoms';

// Assets
import { ReactComponent as TelIcon } from '../../../assets/icons/Icono-Telefono.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Icono-Correo.svg';
import { ReactComponent as PerfilIcon } from '../../../assets/icons/Icono-Perfil.svg';

// Hooks
import { useOportunidad } from '../../../hooks/others';

const CardContactoTab = () => {
  const { dataContacto } = useOportunidad();

  return (
    <div className="card-tabs">
      <TituloCard titulo="Datos de contacto" />
      <div className="pt-4 grid gap-4">
        <div className="grid gap-1">
          <TextCustom text="Nombre" className="text-sm" />
          <div className="flex gap-2 items-center">
            <PerfilIcon />
            <TextCustom
              text={dataContacto.nombre}
              className="fontPSemiBold text-max"
              component="p"
              variant="p"
            />
          </div>
        </div>
        <div className="grid gap-1">
          <TextCustom text="Correo Electrónico" className="text-sm" />
          <div className="flex gap-2 items-center">
            <EmailIcon />
            <TextCustom
              text={dataContacto.correo}
              className="fontPSemiBold text-max"
              component="p"
              variant="p"
            />
          </div>
        </div>
        <div className="grid gap-1">
          <TextCustom text="Teléfono" className="text-sm" />
          <div className="flex gap-2 items-center">
            <TelIcon />
            <TextCustom
              text={dataContacto.telefono}
              className="fontPSemiBold text-max"
              component="p"
              variant="p"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContactoTab;
