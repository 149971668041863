const apiUsername = process.env.REACT_APP_apiUsername;
const apiPassword = process.env.REACT_APP_apiPassword;
const auth = 'Basic ' + window.btoa(apiUsername + ':' + apiPassword);
const buildRequest = (token, request) => {
  let headers;
  if (token) {
    headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: request,
    };
  } else {
    headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: auth,
      },
      params: request,
    };
  }

  if (request instanceof FormData) {
    headers.headers['Content-Type'] = 'multipart/form-data';
  } else {
    headers.headers['Content-Type'] = 'application/json';
  }
  return headers;
};

export default buildRequest;
