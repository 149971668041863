import { renderInicialesNombre } from '../../../core/utils';

//assets
import { ReactComponent as EliminarIcon } from '../../../assets/icons/Icono-Eliminar.svg';

const UsuariosAsignados = ({
  usuario,
  handleEliminarUsuario,
  permisoDelete,
}) => {
  return (
    <div className="grid gap-3 mt-5 items-center grid-cols-5">
      <div className="col-span-4">{renderInicialesNombre(usuario?.nombre)}</div>
      <div className="flex justify-end items-center">
        {permisoDelete && (
          <EliminarIcon
            onClick={() => {
              handleEliminarUsuario(usuario._id);
            }}
            className="h-7 w-7 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default UsuariosAsignados;
