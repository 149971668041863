import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Loader, AlertCustom } from '../../atoms';
import { TituloPage } from '../../molecules';
import { DialogAgregarAliado } from '../../organisms';
import { TableCustom } from '../../templates';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Const
import { typesTableActions } from '../../../common/types';
import { columnsTableAliados } from '../../../common/tables';

// Api
import { getApiAliados, postApiAliados } from '../../../services';

// Functions
import {
  buscarPermiso,
  formatearIdentificacion,
  formatearTelefono,
  renderInicialesNombre,
} from '../../../core/utils';

// Hooks
import { useForm } from '../../../hooks/others';
import { AuthContext } from '../../../hooks/context';

const { tableView, linkButton } = typesTableActions;

const Aliados = () => {
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  const [showAgregar, setShowAgregar] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [aliados, setAliados] = useState([]);
  const [permiso, setPermiso] = useState(false);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  const handleAgregar = () => {
    setShowAgregar(true);
  };

  useEffect(() => {
    setPermiso(buscarPermiso(permisosContext, 'aliados', 'post'));
  }, [permisosContext]);

  useEffect(() => {
    obtenerAliados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerAliados = async () => {
    const { success, data, statusCode } = await getApiAliados();

    if (success) {
      const aliadosFormateadas = data.map(aliado => {
        const primerNombre = aliado.nombre.split(' ')[0] || '';
        const segundoNombre = aliado.nombre.split(' ')[1] || '';
        const primerApellido = aliado.nombre.split(' ')[2] || segundoNombre;
        const segundoApellido = aliado.nombre.split(' ')[3] || segundoNombre;

        const nombre1ConApellidos = `${primerNombre} ${primerApellido} ${segundoApellido}`;
        const nombre2ConApellidos = `${segundoNombre} ${primerApellido} ${segundoApellido}`;
        const nombre1ConApellido2 = `${primerNombre} ${segundoApellido}`;
        const nombre2ConApellido2 = `${segundoNombre} ${segundoApellido}`;

        return {
          id_aliado: aliado._id,
          nombre: renderInicialesNombre(aliado.nombre),
          filtro: aliado.nombre,
          identificacion: aliado.identificacion
            ? formatearIdentificacion(aliado.identificacion)
            : '-',
          telefono: aliado.telefono ? formatearTelefono(aliado.telefono) : '-',
          correo: aliado.correo,
          codigo: aliado.codigo,
          link: aliado.link_cotizacion,
          nombre1Apellidos: nombre1ConApellidos,
          nombre2Apellidos: nombre2ConApellidos,
          nombre1Apellido2: nombre1ConApellido2,
          nombre2Apellido2: nombre2ConApellido2,
        };
      });
      setAliados(aliadosFormateadas);
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
    setCargando(false);
  };

  const handleAction = (action, id, obj) => {
    switch (action) {
      case 'linkButton':
        navigator.clipboard.writeText(obj.link);
        break;
      case 'tableView':
        navigate(`/dashboard/aliados/${obj.id_aliado}`);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async params => {
    const respuesta = await postApiAliados(params);
    const { success, message, statusCode, error } = respuesta;

    if (success) {
      setShowAgregar(false);
      obtenerAliados();
     
      setAlert({
        title: '¡Aliado creado!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: error,
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  return (
    <div>
      {cargando && <Loader />}
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      <div className="flex justify-between items-center mb-10">
        <div className="flex gap-5 items-center">
          <TituloPage titulo="Aliados" />
          {permiso && (
            <div onClick={() => handleAgregar()}>
              <AddCircleIcon className="color-general text-5xl hover:cursor-pointer " />
            </div>
          )}
        </div>
      </div>
      <TableCustom
        data={aliados}
        columns={columnsTableAliados}
        identifierHidden={[
          'filtro',
          'nombre1Apellidos',
          'nombre2Apellidos',
          'nombre1Apellido2',
          'nombre2Apellido2',
        ]}
        identifierAction={'filtro'}
        actions={[linkButton, tableView]}
        actionClick={(action, id, obj) => handleAction(action, id, obj)}
        isSearch={true}
       
      
      />

      {/* ventanas modales */}
      <DialogAgregarAliado
        isOpenModal={showAgregar}
        setIsOpenModal={setShowAgregar}
        actionSubmit={handleSubmit}
      />
    </div>
  );
};

export default Aliados;
