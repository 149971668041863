export const stSetListPermisos = data => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    dataResponse.data = localStorage.setItem(
      'listPermisos',
      JSON.stringify(data),
    );
    dataResponse.success = true;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};

export const stGetListPermisos = () => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    const listPermisos = JSON.parse(localStorage.getItem('listPermisos'));
    if (listPermisos) {
      dataResponse.data = listPermisos;
      dataResponse.success = true;
    }
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};

export const stRemoveListPermisos = () => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    dataResponse.data = localStorage.removeItem('listPermisos');
    dataResponse.success = true;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};
