import axios from 'axios';
import { endpoints } from '../../../common/endpoints';
import buildRequest from '../../buildRequest';

//localstorage
import { stGetToken } from '../../storage';

export const apiSubirDocumentos = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.documentos}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  if (!token) return;

  const request = params;
  try {
    const response = await axios[method](
      url,
      request,
      buildRequest(token, request),
    );
    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const apiDescargarDocumentos = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.documentos}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    path: params.path,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
