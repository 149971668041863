import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
//components
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent, Tooltip } from '@mui/material';
import { AlertCustom, ButtonCustom, TextCustom } from '../../atoms';

//hooks
import { useForm } from '../../../hooks/others';

//assets
import { ReactComponent as LinkIcon } from '../../../assets/icons/Icono-Link.svg';
//api
import { apiResetPasswordUsuario } from '../../../services/apis/usuarios/apiUsuarios';

const DialogResetPassword = ({
  isOpenModal = false,
  setIsOpenModal = () => null,
  showAlert = false,
  setShowAlert = () => null,
  password = '',
}) => {
  const params = useParams();

  const [toolTip, setToolTip] = useState('Copiar');
  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });
  const inputRef = useRef(null);

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(inputRef.current.value);
    setToolTip('Copiado');

    setTimeout(() => {
      setToolTip('Copiar');
    }, 2000);
  };

  const handleSubmitPassword = async () => {
    const request = {
      id_usuario: params.id,
      password,
    };

    const { success, message, error } = await apiResetPasswordUsuario(request);
    navigator.clipboard.writeText(inputRef.current.value);
    setToolTip('Copiado');
    setShowAlert(true);
    if (success) {
      setAlert({
        title: '¡Contraseña actualizada!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      setAlert({
        title: error,
        description: message,
        open: true,
        severity: 'warning',
      });
    }

    setTimeout(() => {
      setShowAlert(false);
      setIsOpenModal(false);
    }, 2000);
  };

  return (
    <DialogCustom
      open={isOpenModal}
      setOpen={handleCloseModal}
      title={`Reestablecer contraseña `}
      disabledDismiss
    >
      <DialogContent style={{ width: 650 }}>
        <div className="mt-10 px-7">
          <Tooltip title={toolTip} arrow placement="top">
            <div>
              <TextCustom text="Contraseña Temporal" />
              <div
                className="rounded-md px-9 py-2 mt-2 flex gap-5 cursor-pointer "
                style={{ backgroundColor: '#F8FBFD' }}
                onClick={handleCopyClick}
              >
                <input
                  ref={inputRef}
                  style={{ position: 'absolute', left: '-9999px' }}
                  value={password}
                  type="submit"
                />
                <LinkIcon />
                <button className="fontPSemiBold color-general">
                  {password}
                </button>
              </div>
            </div>
          </Tooltip>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="mx-auto mt-20">
          <ButtonCustom
            text={`Crear`}
            typeColor="general"
            width="140px"
            onClick={handleSubmitPassword}
          />
        </div>
      </DialogActions>

      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={showAlert}
        setOpen={setShowAlert}
        severity={alert.severity}
      />
    </DialogCustom>
  );
};

export default DialogResetPassword;
