import { useEffect, useState } from 'react';

// Components
import { ButtonCustom, TextCustom, TituloCard } from '../../atoms';

// Assets
import { ReactComponent as ListoIcon } from '../../../assets/icons/Estados/AceptarIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/Estados/ObligatorioIcon.svg';

// Utils
import {
  renderBgEstado,
  renderColorEstado,
  renderIconEstado,
} from '../../../core/utils';

// Hooks
import { useOportunidad } from '../../../hooks/others';

const CardRequisitos = ({ requisito = '', codigo = '', values = '' }) => {
  const {
    etapaActual,
    setEtapaActual,
    dataEtapas,
    setDataEtapas,
    setEtapaActualizada,
    etapaActualizada,
  } = useOportunidad();

  const [value, setValue] = useState('');
  const [estado, setEstado] = useState('');
  const [mensaje, setMnsaje] = useState('');

  useEffect(() => {
    let newVal = '';

    if (values[codigo]) {
      newVal = values[codigo];
    }

    if (newVal === '') {
      setEstado('pendiente');
      setMnsaje('Pendiente de aceptación');
    } else if (newVal === 'aceptado') {
      setEstado('aceptado');
      setMnsaje('Aceptado');
    } else if (newVal === 'rechazado') {
      setEstado('obligatorio');
      setMnsaje('Rechazado');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, values]);

  const handleSetForm = val => {
    let newEtapa = etapaActual;
    let etapas = dataEtapas.map(etapa => {
      if (codigo in etapa.values) {
        etapa.values[codigo] = val;
      }
      if (etapa.idEtapa === newEtapa.idEtapa) {
        etapa.values = newEtapa.values;
      }
      if (etapa.idEtapa === etapaActualizada.idEtapa) {
        setEtapaActualizada(etapa);
      }
      return etapa;
    });

    setTimeout(() => {
      setDataEtapas(etapas);
    }, 2000);
    setTimeout(() => {
      setEtapaActual(newEtapa);
    }, 1000);

    setValue(val);
  };
  return (
    <div className="card-tabs">
      <TituloCard titulo={requisito.nombre} />
      <div className="mt-5">
        <TextCustom
          className="text-sm"
          text={
            requisito.descripcion ||
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
          }
        />
        <div className="grid grid-cols-2 gap-4 my-4">
          <ButtonCustom
            text="Aceptar"
            height="40px"
            width="100%"
            variant="text"
            className="sombra-btn justify-between px-4 text-sm fontPSemiBold rounded-md "
            typeColor="texto"
            endIcon={<ListoIcon className="w-6 h-6" />}
            onClick={() => handleSetForm('aceptado')}
          />
          <ButtonCustom
            text="Rechazar"
            height="40px"
            width="100%"
            variant="text"
            className="sombra-btn justify-between px-4 text-sm fontPSemiBold rounded-md "
            typeColor="texto"
            endIcon={<ErrorIcon className="w-6 h-6" />}
            onClick={() => handleSetForm('rechazado')}
          />
        </div>
        <TextCustom className="text-sm" text="Estado" />
        <div className="mt-4">
          <div
            className="grid grid-cols-8 rounded-md py-1 px-5 items-center"
            style={{ backgroundColor: renderBgEstado(estado) }}
          >
            <div className="col-span-1">{renderIconEstado(estado)}</div>
            <div
              className="col-span-7 fontPSemiBold text-sm"
              style={{ color: renderColorEstado(estado) }}
            >
              {mensaje}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRequisitos;
