import {  useEffect, useState } from 'react';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';

// Assets
import SearchIcon from '@mui/icons-material/Search';
// Components
import { SelectCustom, TextCustom, TextInputCustom } from '../../atoms';
import { TablePagination, TableHeaders, TableRows } from '../../molecules';
import { renderColor } from '../../../core/utils';

import { useLocation } from 'react-router-dom';
import { ButtonExportExcel } from '../../molecules/ButtonExportExcel';

const TableCustom = ({
  columns = [],
  data = [],
  isSearch = false,
  isFilter = false,
  identifierHidden = undefined,
  identifierAction = undefined,
  identifierSort = '',
  actionColumnTitle = '',
  actions = [],
  actionClick = () => null,
  isResetPagina = false,
  setIsResetPagina = () => null,
  filterHeader = true,
  optionsFilter = [],
  centrar = false,
  handleFiltroSelect = () => null,
  haveContenido = true,
  textIfNotContent = 'No hay contenido',
  dataExport = []
}) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    allColumns,
    pageOptions,
    gotoPage,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        sortBy: [
          {
            desc: false,
            id: identifierSort,
          },
        ],
        globalFilter: searchParams.get('aliado' ||'') || undefined,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { globalFilter, pageIndex } = state;
  const [filtro, setFiltro] = useState('');


  useEffect(()=> {
    if(localStorage.getItem('filtroSeleccionado') && location.pathname.includes("oportunidades")) {
      handleFiltro(JSON.parse(localStorage.getItem('filtroSeleccionado')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    if (identifierHidden) {
      const columnHide = allColumns.filter(column =>
        identifierHidden.includes(column.id),
      );

      columnHide.forEach(hide => hide?.toggleHidden(true));
      
      // columnHide?.toggleHidden(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length <= 10 && pageIndex > 0) {
      gotoPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (pageIndex > 0) {
      gotoPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

 
  const handleFiltro = val => {
    setFiltro(val);
    handleFiltroSelect(val);
    if(location.pathname.includes("oportunidades")) localStorage.setItem("filtroSeleccionado", JSON.stringify(val))
  };

  useEffect(() => {
    if (pageIndex > 0) {
      gotoPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="w-full relative">
      {(isFilter || isSearch) && (
        <div
          className={`flex flex-col xl:flex-row xl:absolute gap-4 justify-end items-center  filtro-tabla ${
            !haveContenido && 'pointer-events-none'
          }  ${centrar && '-top-44 -translate-y-1'}   `}
        >
          {isFilter && (
            <div className="flex gap-3 items-center">
              <TextCustom text="Filtro: " component="p" variant="p" />
              <SelectCustom
                options={optionsFilter}
                className="w-60 mt-0"
                setValue={handleFiltro}
                value={filtro}
                borderStyle={true}
                isFiltro={true}
              />
            </div>
          )}
          {isSearch && (
            <div>
              <TextInputCustom
                value={globalFilter}
                setValue={setGlobalFilter}
                className={`mb-2 w-1/2 xl:w-full`}
                iconStart={<SearchIcon />}
                paddingY="10px"
                borderStyle={true}
                placeholder="Buscar"
              />
            </div>
          )}
          {location.pathname.includes("oportunidades") && <ButtonExportExcel module="oportunidades" data={dataExport} /> }
          {location.pathname.includes("contactos") && <ButtonExportExcel module="contactos" data={dataExport} /> }
        </div>
      )}

      {haveContenido ? (
        <>
          <table
            className="w-full fontPRegular border-spacing-y-2 border-separate "
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    color: renderColor('white'),
                    backgroundColor: renderColor('general'),
                  }}
                >
                  <TableHeaders
                    headerGroup={headerGroup}
                    isActions={actions.length > 0}
                    actionColumnTitle={actionColumnTitle}
                    filterHeader={filterHeader}
                  />
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <TableRows
                identifierName={identifierAction}
                isActions={actions.length > 0}
                actions={actions}
                actionClick={actionClick}
                page={page}
                prepareRow={prepareRow}
              />
            </tbody>
          </table>
          {pageOptions.length > 1 && (
            <TablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              previousPage={previousPage}
              isResetPagina={isResetPagina}
              setIsResetPagina={setIsResetPagina}
            />
          )}
        </>
      ) : (
        <TextCustom
          text={textIfNotContent}
          color="general"
          className="font-bold text-center"
          variant="p"
          component="p"
        />
      )}
    </div>
  );
};

export default TableCustom;
