import { createContext, useContext, useEffect, useState } from 'react';
import { getApiCategorias, getApiEtapas } from '../../services';
import AuthContext from './authContext';

export const oportunidadContext = createContext();

const OportunidadProvider = ({ children }) => {
  const { auth } = useContext(AuthContext);

  const [infoEtapas, setInfoEtapas] = useState([]);
  const [etapa, setEtapa] = useState('');
  const [cuotas, setCuotas] = useState('');
  const [etapas, setEtapas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [actualizar, setActualizar] = useState(true);
  const [dataEtapas, setDataEtapas] = useState([]);
  const [etapaActual, setEtapaActual] = useState(null);
  const [etapaActualizada, setEtapaActualizada] = useState('');
  const [valorCotizacion, setValorCotizacion] = useState('');
  const [aseguradoraSeleccionada, setAseguradoraSeleccionada] = useState('');
  const [cotizacionSeleccionada, setCotizacionSeleccionada] = useState({});
  const [detalleCotizacion, setDetalleCotizacion] = useState('');
  const [numPoliza, setNumPoliza] = useState('-');
  const [numConsecutivo, setNumConsecutivo] = useState('-');
  const [numConfirmacion, setNumConfirmacion] = useState('-');
  const [linkCotizacion, setLinkCotizacion] = useState('-');
  const [razonEtapa, setRazonEtapa] = useState('');
  const [dataContacto, setDataContacto] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    preferenciaContacto: '',
  });
  const [aliado, setAliado] = useState({
    id: '',
    nombre: '',
  });
  const [categoria, setCategoria] = useState({
    codigo: '',
    nombre: '',
  });
  const [pago, setPago] = useState({
    cuotas: '',
    estadoPago: '',
    metodoPago: '',
    totalPagar: '',
  });
  const [divisaSeleccionada, setDivisaSeleccionada] = useState('CRC');
  const [valorDivisa, setValorDivisa] = useState(0);
  const [cotizaciones, setCotizaciones] = useState([]);

  const resetearContext = () => {
    setLinkCotizacion('');
    setEtapa('');
    setCuotas('');
    setEtapas([]);
    setActualizar(true);
    setDataEtapas([]);
    setEtapaActual(null);
    setEtapaActualizada('');
    setValorCotizacion('');
    setAseguradoraSeleccionada('');
    setCotizacionSeleccionada({});
    setDetalleCotizacion('');
    setNumPoliza('-');
    setNumConsecutivo('-');
    setNumConfirmacion('-');
    setRazonEtapa('');
    setDataContacto({
      nombre: '',
      correo: '',
      telefono: '',
      preferenciaContacto: '',
    });
    setAliado({
      id: '',
      nombre: '',
    });
    setCategoria({
      codigo: '',
      nombre: '',
    });
    setPago({
      cuotas: '',
      estadoPago: '',
      metodoPago: '',
      totalPagar: '',
    });
  };

  useEffect(() => {
    const obtenerEtapas = async () => {
      if (dataEtapas.length === 0) {
        if (auth.etapas !== undefined) {
          setInfoEtapas(auth.etapas);
        } else {
          const { success: successEtapas, data: dataEtapas } =
            await getApiEtapas();

          

          if (successEtapas) {
            setInfoEtapas(dataEtapas);
          }
        }
      }
    };

    obtenerEtapas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEtapas]);

  useEffect(() => {
    const obtenerCategorias = async () => {
      if (categorias.length === 0) {
        if (auth.categorias !== undefined) {
          setCategorias(auth.categorias);
        } else {
          const { success: successEtapas, data: dataCategorias } =
            await getApiCategorias({ listarResumen: true });

          if (successEtapas) {
            setCategorias(dataCategorias);
          }
        }
      }
    };

    obtenerCategorias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorias]);

  return (
    <oportunidadContext.Provider
      value={{
        etapa,
        setEtapa,
        dataContacto,
        setDataContacto,
        aliado,
        setAliado,
        dataEtapas,
        setDataEtapas,
        setEtapas,
        etapas,
        etapaActual,
        setEtapaActual,
        categoria,
        setCategoria,
        actualizar,
        setActualizar,
        cuotas,
        setCuotas,
        resetearContext,
        etapaActualizada,
        setEtapaActualizada,
        pago,
        setPago,
        infoEtapas,
        setInfoEtapas,
        categorias,
        setCategorias,
        setAseguradoraSeleccionada,
        aseguradoraSeleccionada,
        setValorCotizacion,
        valorCotizacion,
        setCotizacionSeleccionada,
        cotizacionSeleccionada,
        setDetalleCotizacion,
        detalleCotizacion,
        setNumPoliza,
        numPoliza,
        setNumConsecutivo,
        numConsecutivo,
        setNumConfirmacion,
        numConfirmacion,
        divisaSeleccionada,
        setDivisaSeleccionada,
        valorDivisa,
        setValorDivisa,
        setLinkCotizacion,
        linkCotizacion,
        razonEtapa,
        setRazonEtapa,
        cotizaciones,
        setCotizaciones,
      }}
    >
      {children}
    </oportunidadContext.Provider>
  );
};

export default OportunidadProvider;
