export const columnsTableUsuarios = [
  {
    Header: 'Id',
    accessor: 'filtro',
  },
  {
    Header: 'Nombre',
    accessor: 'nombre',
    sortType: (a, b) => {
      return a.original.filtro.localeCompare(
        b.original.filtro
      );
    },
    
    
  },
  {
    Header: 'Correo electrónico',
    accessor: 'correo',
    sortType: (a, b) => {
      return a.original.correo.localeCompare(b.original.correo);
    },
  },
  {
    Header: 'Roles',
    accessor: 'rol',
    sortType: (a, b) => {
      return a.original.rol.localeCompare(b.original.rol);
    },
  },
  {
    Header: 'Estado',
    accessor: 'estado',
    sortType: (a, b) => {
      return a.original.estado.localeCompare(b.original.estado);
    },
  },
];



export const columnsVistaUsuario = [
  {
    Header: 'Módulo',
    accessor: 'modulo',
  },
  {
    Header: 'Activar',
    accessor: 'activar',
  },
  {
    Header: 'Crear',
    accessor: 'crear',
  },
  {
    Header: 'Editar',
    accessor: 'editar',
  },
  {
    Header: 'Borrar',
    accessor: 'borrar',
  },
];

export const columnsTableAseguradora = [
  {
    Header: 'Id',
    accessor: 'id_aseguradora',
  },
  {
    Header: 'Nombre',
    accessor: 'nombre_aseguradora',
  },
  {
    Header: 'Código',
    accessor: 'codigo_aseguradora',
  },
  {
    Header: 'Estado',
    accessor: 'estado',
  },
];

export const columnsTableCotizaciones = [
  {
    Header: 'Id',
    accessor: 'filtro',
  },
  {
    Header: 'Tipo',
    accessor: 'nombre_seguro',
    sortType: (a, b) => {
      return a.original.filtro.localeCompare(b.original.filtro);
    },
  },
  {
    Header: 'Contacto',
    accessor: 'contacto',
    sortType: (a, b) => {
      return a.original.contacto.localeCompare(b.original.contacto);
    },
  },
  {
    Header: 'Fecha creada',
    accessor: 'fecha_creacion',
   
  },
  {
    Header: 'Aliado',
    accessor: 'aliado',
    sortType: (a, b) => {
      return a.original.aliado.localeCompare(b.original.aliado);
    },
  },
  {
    Header: 'Id_aliado',
    accessor: 'id_aliado',
  },
];

export const columnsTableAliados = [
  {
    Header: 'Id',
    accessor: 'filtro',
  },
  {
    Header: 'Nombre',
    accessor: 'nombre',
    sortType: (a, b) => {
      return a.original.filtro.localeCompare(b.original.filtro);
    },
  },
  {
    Header: 'Cédula',
    accessor: 'identificacion',
    sortType: (a, b) => {
      return a.original.identificacion.localeCompare(b.original.identificacion);
    },
  },
  {
    Header: 'Teléfono',
    accessor: 'telefono',
    sortType: (a, b) => {
      if (!a.original.telefono || !b.original.telefono) return 0;

      return a.original.telefono.localeCompare(b.original.telefono);
    },
  },
  {
    Header: 'Correo Electrónico',
    accessor: 'correo',
    sortType: (a, b) => {
      return a.original.correo.localeCompare(b.original.correo);
    },
  },
  {
    Header: 'Código',
    accessor: 'codigo',
    sortType: (a, b) => {
      return a.original.codigo.localeCompare(b.original.codigo);
    },
  },
  {
    Header: 'Nombre1Apellidos',
    accessor: 'nombre1Apellidos',
  },
  {
    Header: 'Nombre2Apellidos',
    accessor: 'nombre2Apellidos',
  },
  {
    Header: 'Nombre1Apellido2',
    accessor: 'nombre1Apellido2',
  },
  {
    Header: 'Nombre2Apellido2',
    accessor: 'nombre2Apellido2',
  },
];

export const columnsTableContacto = [
  {
    Header: 'Id',
    accessor: 'other',
  },
  {
    Header: 'Id_contacto',
    accessor: 'idContacto',
  },
  {
    Header: 'Nombre',
    accessor: 'nombre',
    sortType: (a, b) => {
      return a.original.contacto.localeCompare(b.original.contacto);
    },
  },
  {
    Header: 'Correo Electrónico',
    accessor: 'correo',
    sortType: (a, b) => {
      if (!a.original.correo || !b.original.correo) return 0;

      return a.original.correo.localeCompare(b.original.correo);
    },
  },
  {
    Header: 'Teléfono',
    accessor: 'telefono',
    sortType: (a, b) => {
      if (!a.original.telefono || !b.original.telefono) return 0;

      return a.original.telefono.localeCompare(b.original.telefono);
    },
  },
  {
    Header: 'Fecha de Nacimiento',
    accessor: 'fechaNacimiento',
    
  },
  {
    Header: 'Nombre1Apellidos',
    accessor: 'nombre1Apellidos',
  },
  {
    Header: 'Nombre2Apellidos',
    accessor: 'nombre2Apellidos',
  },
  {
    Header: 'Nombre1Apellido2',
    accessor: 'nombre1Apellido2',
  },
  {
    Header: 'Nombre2Apellido2',
    accessor: 'nombre2Apellido2',
  },
];

export const columnsTableCotizacionesC = [
  {
    Header: 'Id',
    accessor: 'idCotizacion',
  },
  {
    Header: 'Tipo',
    accessor: 'nombre_seguro',
  },
  {
    Header: 'Fecha creada',
    accessor: 'fecha_creacion',
  },
  {
    Header: 'Aliado',
    accessor: 'aliado',
  },
  {
    Header: 'Estado',
    accessor: 'estado',
  },
];
export const columnsTableOportunidades = [
  {
    Header: 'Id',
    accessor: 'filtro',
  },
  {
    Header: 'Id_oportunidad',
    accessor: 'id_oportunidad',
  },
  {
    Header: 'Tipo',
    accessor: 'nombre_seguro',
    sortType: (a, b) => {
      return a.original.filtro.localeCompare(b.original.filtro);
    },
  },
  {
    Header: 'Contacto',
    accessor: 'contacto',
    sortType: (a, b) => {
      return a.original.contacto.localeCompare(b.original.contacto);
    },
  },
  {
    Header: 'Fecha creada',
    accessor: 'fecha_creacion',
    
   
  },
  {
    Header: 'Aliado',
    accessor: 'aliado',
    sortType: (a, b) => {
      return a.original.aliado.localeCompare(
        b.original.aliado
      );
    },
  },
  {
    Header: 'Valor',
    accessor: 'valor',
   
  
    
  },
  {
    Header: 'Estado',
    accessor: 'estado',
  },
  {
    Header: 'Nombre1Apellidos',
    accessor: 'nombre1Apellidos',
  },
  {
    Header: 'Nombre2Apellidos',
    accessor: 'nombre2Apellidos',
  },
  {
    Header: 'Nombre1Apellido2',
    accessor: 'nombre1Apellido2',
  },
  {
    Header: 'Nombre2Apellido2',
    accessor: 'nombre2Apellido2',
  },
];

