export const colors = {
  texto: '#222222',
  general: '#003763',
  primary: '#0099B7',
  secondary: '#A01779',
  green: '#76BC21',
  error: '#ED3B6B',
  warning: '#EDA93B',
  white: '#FFFFFF',
  black: '#000000',
  neutral: '#F8FBFD',
  gray: '#3C3C3B',
  ligthGray: '#CDCDCD',
  bordes: '#E3E3E3',
  line: '#E5EBEF',
  fondo: '#E5F4F7',
  hoverSelect: '#0099b70f',
  sombra: '#0037631a',
  error_secondary: '#DD2254',
  gray_secondary: '#AAAAAA',
  hover: '#ecf6fa',
};
