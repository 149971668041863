export const stSetPersonalInfo = data => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    dataResponse.data = localStorage.setItem(
      'personalInfo',
      JSON.stringify(data),
    );
    dataResponse.success = true;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};

export const stGetPersonalInfo = () => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
    if (personalInfo) {
      dataResponse.data = personalInfo;
      dataResponse.success = true;
    }
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};

export const stRemovePersonalInfo = () => {
  const dataResponse = {
    success: false,
    message: '',
    data: null,
  };
  try {
    dataResponse.data = localStorage.removeItem('personalInfo');
    dataResponse.success = true;
  } catch (error) {
    dataResponse.message = error.message;
    dataResponse.data = error;
  }
  return dataResponse;
};
