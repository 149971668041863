import { useRef, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { LabelTexto, TextCustom } from '../../atoms';
import {
  CardActualizaciones,
  ChartVentasPorMes,
  ChartOportunidadesPorEtapa,
  ChartCanalizacionVentas,
  Divisas,
} from '../../molecules';

// Style
import './style.css';

// Assets
import ImagenTablero from '../../../assets/images/Tablero/Dashboad-Asegurame.png';

//Function
import { buscarPermiso, convertToMarkdownBold } from '../../../core/utils';

// Services
import { getApiEtapas } from '../../../services';

//hooks
import { AuthContext } from '../../../hooks/context';
import { useOportunidad } from '../../../hooks/others';

const Home = () => {
  //state
  const navigate = useNavigate();
  const [permisoGetEtapas, setPermisoGetEtapas] = useState(false);

  //ref
  const texto = useRef(null);

  //context
  const { auth } = useContext(AuthContext);
  const { personalInfo } = auth;
  const { permisos: permisosContext } = auth;
  const { setInfoEtapas, infoEtapas } = useOportunidad();

  useEffect(() => {
    setPermisoGetEtapas(buscarPermiso(permisosContext, 'etapas', 'get'));
  }, [permisosContext]);

  useEffect(() => {
    texto.current.innerHTML = convertToMarkdownBold(
      `Bienvenido a *Asegurame*. \n ¿Estás listo para comenzar a crear \n nuevas  Experiencias en seguros? *¡Coméncemos!*`,
      'text-white fontPRegular text-base',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const obtenerEtapas = async () => {
      const {
        success: successEtapas,
        data: dataEtapas,
        statusCode,
      } = await getApiEtapas();

      if (successEtapas) {
        setInfoEtapas(dataEtapas);
      } else {
        if (statusCode === 403) {
          navigate('/page-error-401');
          return;
        }

        navigate('/page-error-500', { state: { statusCode: statusCode } });
      }
    };

    obtenerEtapas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full contenedor-tablero pt-8 ">
      {/* {cargando && <Loader />} */}
      <div className="grid grid-tablero">
        <div className="bg-general px-8 grid grid-cont-general content-center">
          <div className="gap-3 flex flex-col justify-center pl-3">
            <div className="flex flex-col">
              <TextCustom
                text="Hola,"
                variant="h3"
                className="fontPRegular"
                color="white"
              />
              <TextCustom
                text={personalInfo.nombre}
                variant="h3"
                className="fontPSemiBold"
                color="white"
              />
            </div>
            <div ref={texto}></div>
          </div>
          <div>
            <img src={ImagenTablero} alt="Imagen tablero" />
            {/* <ImagenTablero /> */}
          </div>
        </div>
        <div>
          <TextCustom
            text="Actualizaciones"
            variant="h6"
            className="fontPSemiBold mb-3"
          />
          <div className="grid gap-3">
            {infoEtapas.map(etapa => (
              <CardActualizaciones
                numero={etapa.conteoOportunidades}
                color={etapa.colores.find(color => color.tipo === 'primary')}
                texto={etapa.nombre}
                nombre={etapa.nombre}
                key={etapa._id}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center my-14">
        <div>
          <TextCustom text="Tablero" variant="h6" className="fontPSemiBold " />
          {permisoGetEtapas && (
            <div className="flex gap-7">
              {infoEtapas.map(etapa =>
                etapa.codigo !== 'DOR' && etapa.codigo !== 'PER' ? (
                  <LabelTexto
                    color={etapa.colores.find(
                      color => color.tipo === 'primary',
                    )}
                    texto={etapa.nombre}
                    className="fontPRegular text-sm"
                    key={etapa._id}
                  />
                ) : null,
              )}
            </div>
          )}
        </div>
        <Divisas />
      </div>
      <div className="grid grid-cols-2 2xl:grid-cols-3 gap-5">
        <div className="card-tablero h-96 ">
          <ChartOportunidadesPorEtapa />
        </div>
        <div className="card-tablero h-96">
          <ChartVentasPorMes />
        </div>
        <div className="card-tablero h-96">
          <ChartCanalizacionVentas />
        </div>
      </div>
    </div>
  );
};

export default Home;
