import { colors } from '../../components/styles/theme';
import SeguroVehiculo from '../../assets/images/Productos/Seguro-Vehiculo.png';
import SeguroViaje from '../../assets/images/Productos/Seguro-Viaje.png';
import SeguroEnfermedades from '../../assets/images/Productos/Seguro-Medico.png';
import { LabelTexto, TextCustom } from '../../components/atoms';
import { formatearFechaNacimiento } from './dates';

// Iconos Estados
import { ReactComponent as AceptadoIcon } from '../../assets/icons/Estados/AceptarIcon.svg';
import { ReactComponent as ObligatorioIcon } from '../../assets/icons/Estados/ObligatorioIcon.svg';
import { ReactComponent as PendienteIcon } from '../../assets/icons/Estados/PendienteIcon.svg';
import { ReactComponent as OpcionalIcon } from '../../assets/icons/Estados/OpcionalIcon.svg';
import { useLocation } from 'react-router-dom';

const {
  general,
  primary,
  secondary,
  green,
  error,
  warning,
  white,
  texto,
  black,
  bordes,
  line,
  fondo,
  sombra,
  ligthGray,
  gray,
  hover,
} = colors;

export const renderColor = (typeColor = '') => {
  let customColor = undefined;
  switch (typeColor) {
    case 'texto':
      customColor = texto;
      break;
    case 'general':
      customColor = general;
      break;
    case 'primary':
      customColor = primary;
      break;
    case 'secondary':
      customColor = secondary;
      break;
    case 'success':
      customColor = green;
      break;
    case 'danger':
      customColor = error;
      break;
    case 'warning':
      customColor = warning;
      break;
    case 'black':
      customColor = black;
      break;
    case 'white':
      customColor = white;
      break;
    case 'bordes':
      customColor = bordes;
      break;
    case 'line':
      customColor = line;
      break;
    case 'fondo':
      customColor = fondo;
      break;
    case 'sombra':
      customColor = sombra;
      break;
    case 'ligthGray':
      customColor = ligthGray;
      break;
    case 'gray':
      customColor = gray;
      break;
    case 'hover':
      customColor = hover;
      break;
    default:
      customColor = undefined;
      break;
  }
  return customColor;
};
export const renderColorEtapa = (typeColor = '') => {
  switch (typeColor) {
    case 'Cotización':
      return '#003763';
    case 'Negociación':
      return '#A01779';
    case 'Documentación':
      return '#0099B7';
    case 'Pago':
      return '#76BC21';
    case 'Emisión':
      return '#EDA93B';
    case 'Dormida':
      return '#AAAAAA';
    case 'Perdida':
      return '#DD2254';
    case 'Ganada':
      return '#2C7E21';
    default:
      break;
  }
};

export const renderColorEstado = (typeColor = '') => {
  switch (typeColor) {
    case 'pendiente':
      return '#EDA93B';
    case 'aceptado':
      return '#003763';
    case 'opcional':
      return '#0099B7';
    case 'obligatorio':
      return '#DD2254';
    default:
      break;
  }
};

export const renderIconEstado = (typeColor = '') => {
  switch (typeColor) {
    case 'pendiente':
      return <PendienteIcon className="w-7 h-7" />;
    case 'aceptado':
      return <AceptadoIcon className="w-7 h-7" />;
    case 'opcional':
      return <OpcionalIcon className="w-6 h-6" />;
    case 'obligatorio':
      return <ObligatorioIcon className="w-7 h-7" />;
    default:
      break;
  }
};

export const renderBgEstado = (typeColor = '') => {
  switch (typeColor) {
    case 'pendiente':
      return '#FDF6EB';
    case 'aceptado':
      return '#F1F8E8';
    case 'opcional':
      return '#E5F4F7';
    case 'obligatorio':
      return '#FDEBF0';
    default:
      break;
  }
};

export const renderTipoSeguro = (
  codigoSeguro = '',
  size = 'sm',
  nombreSeguro = '',
) => {
  const seguroOncologico =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Oncologico/Icono-Small.svg';
  const seguroPlenisalud =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Productos/Plenisalud/Icono-Big.svg';

  switch (codigoSeguro) {
    case 'SEG-DA':
      return (
        <div className="flex gap-4 items-center">
          <img
            src={SeguroVehiculo}
            alt="SeguroVehiculo"
            className={`${
              size === 'xl'
                ? 'h-24 w-24'
                : size === 'sm'
                ? 'h-auto w-14'
                : 'h-auto w-11'
            }`}
          />
          <TextCustom text={nombreSeguro} />
        </div>
      );

    case 'SEG-V':
      return (
        <div className="flex gap-4 items-center">
          <img
            src={SeguroViaje}
            alt="SeguroViaje"
            className={`${
              size === 'xl'
                ? 'h-24 w-24'
                : size === 'sm'
                ? 'h-auto w-14'
                : 'h-auto w-8 mr-4'
            }`}
          />
          <TextCustom text={nombreSeguro} />
        </div>
      );

    case 'SEG-AEG':
      return (
        <div className="flex gap-4 items-center">
          <img
            src={SeguroEnfermedades}
            alt="SeguroEnfermedades"
            className={`${
              size === 'xl'
                ? 'h-24 w-24'
                : size === 'sm'
                ? 'h-auto w-14'
                : 'h-auto w-9'
            }`}
          />
          <TextCustom text={nombreSeguro} />
        </div>
      );
    case 'SEG-ONC':
      return (
        <div className="flex gap-4 items-center">
          <img
            src={seguroOncologico}
            alt="SeguroEnfermedades"
            className={`${
              size === 'xl'
                ? 'h-24 w-24'
                : size === 'sm'
                ? 'h-auto w-14'
                : 'h-auto w-9'
            }`}
          />
          <TextCustom text={nombreSeguro} />
        </div>
      );
    
    case 'SEG-PLENI':
      return (
        <div className="flex gap-4 items-center">
          <img
            src={seguroPlenisalud}
            alt="SeguroPlenisalud"
            className={`${
              size === 'xl'
                ? 'h-24 w-24'
                : size === 'sm'
                ? 'h-auto w-14'
                : 'h-auto w-9'
            }`}
          />
          <TextCustom text={nombreSeguro} />
        </div>
      );

    default:
      return null;
  }
};

export const renderInicialesNombre = nombre => {
  const nombres = nombre.split(' ');
  const primeraLetra = nombres[0].charAt(0);
  const segundaLetra = nombres.length > 1 ? nombres[1].charAt(0) : '';

  return (
    <div className="grid-iniciales gap-3 items-center">
      <div
        style={{ backgroundColor: renderColor('fondo') }}
        className="uppercase fontPSemiBold  bg-header-table flex items-center justify-center color-general circle-iniciales"
      >
        {primeraLetra + segundaLetra}
      </div>
      <TextCustom text={nombre} />
    </div>
  );
};

export const renderInicialNombreAliado = nombre => {
  return (
    <div className="flex gap-3 items-center justify-center">
      <div
        style={{ backgroundColor: renderColor('fondo') }}
        className="uppercase fontPSemiBold  bg-header-table flex items-center justify-center color-general circle-iniciales-aliado text-[80px]"
      >
        {nombre?.charAt(0)}
      </div>
    </div>
  );
};

export const renderEstados = () => {
  return (
    <>
      <LabelTexto
        color="secondary"
        texto="Negociación"
        className="fontPRegular"
      />
    </>
  );
};

export const renderDetalle = (label = '', valor = '', tipo = 'DET') => {

  const location =  useLocation();

  let textoValor = '';
  if (typeof valor === 'string') {
    textoValor = valor.toLowerCase();
  }

  let inicio = '';
  let regreso = '';
  if (label === 'fechaViaje') {
    const fechas = valor.split('-');
    inicio = fechas[0];
    regreso = fechas[1];
  }

  switch (label) {
    case 'fechaNacimiento':
      label = 'Fecha de nacimiento';
      textoValor = formatearFechaNacimiento(valor);
      break;
    case 'genero':
      label = 'Género';
      break;
    case 'Placa':
      textoValor = valor.toUpperCase();
      break;
    case 'placa':
      label = 'Placa';
      textoValor = valor.toUpperCase();
      break;
      case 'tipoVehiculo':
        label = 'Tipo Vehiculo';
        textoValor = valor.toUpperCase();
        break;
    case 'Modelo':
    label= 'Modelo';
    textoValor = valor.toUpperCase();
    break;
    case 'tipoPlan':
      label = 'Plan';
      break;
    case 'destinoViaje':
      label = 'Destino de viaje';
      textoValor = valor.map(item => item.value).join('-');
      break;
    case 'fechaRegresoOriginal':
      return (
        <div key="dias">
           <div className="flex items-center gap-[10px]">
              {location.pathname.includes("oportunidades") && <div className="w-3 h-3 bg-primary rounded-full"></div>}
              <TextCustom text="Regreso (Original)" className="text-sm" />
            </div>
            <TextCustom
              text={formatearFechaNacimiento(valor)}
              component="p"
              variant="p"
              className={`fontPSemiBold ${location.pathname.includes("oportunidades") && " ml-[22px]"}`}
              
            />
          </div>
      );
    case 'Salida':
      return (
        <div key="Fecha de salida">
          <TextCustom text={'Salida'} className="text-sm" />
          <br />
          <TextCustom
            text={formatearFechaNacimiento(valor)}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
      );
    case 'Regreso':
      return (
        <div key="Fecha de regreso">
          <TextCustom text={'Regreso (Optimizada)'} className="text-sm" />
          <br />
          <TextCustom
            text={formatearFechaNacimiento(valor)}
            component="p"
            variant="p"
            className="fontPSemiBold"
          />
        </div>
      );
    case 'fechaViaje':
      return (
        <>
          <div key="inicio">
            <div className="flex items-center gap-[10px]">
              <div className="w-3 h-3 bg-primary rounded-full"></div>
              <TextCustom text="Salida" className="text-sm" />
            </div>
            <TextCustom
              text={formatearFechaNacimiento(inicio)}
              component="p"
              variant="p"
              className="fontPSemiBold ml-[22px]"
            />
          </div>
          <div key="dias">
            <div className="flex items-center gap-[10px]">
              <div className="w-3 h-3 bg-primary rounded-full"></div>
              <TextCustom text="Regreso (Optimizada)" className="text-sm" />
            </div>
            <TextCustom
              text={formatearFechaNacimiento(regreso)}
              component="p"
              variant="p"
              className="fontPSemiBold ml-[22px]"
            />
          </div>
        </>
      );
    case 'fechaRegresoOptimizada':
    case 'fechaSalida':
      return null;
    default:
      if (label === 'modeloVehiculo') {
       return null;
      }
      
      break;
  }

  if (tipo === 'DET') {

    return (
      <div key={label}>
        <TextCustom text={label} className="text-sm" />
        <br />
        <TextCustom
          text={textoValor}
          component="p"
          variant="p"
          className="fontPSemiBold capitalize"
        />
      </div>
    );
  }
  return (
    <div key={label}>
      <div className="flex items-center gap-[10px]">
        <div className="w-3 h-3 bg-primary rounded-full"></div>
        <TextCustom text={label} className="text-sm" />
      </div>
      <TextCustom
        text={textoValor}
        component="p"
        variant="p"
        className="fontPSemiBold capitalize ml-[22px]"
      />
    </div>
  );
};
