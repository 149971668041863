import { cn } from "../../../core/utils/cn";
import TextCustom from "../../atoms/TextCustom";
import { useRef, useState } from "react";
import "./style.css";
import { ReactComponent as IconUpload } from "../../../assets/icons/UploadFile/Icono-Upload.svg";
import ConfirmacionSubidaArchivo from "../ConfirmacionSubidaArchivo";
import { LoaderInput } from "../../atoms";
import { apiSubirLista } from "../../../services/apis/blacklist/apiBlacklist";




const CardSubidaArchivo = ({ titulo = "", subtitulo = "", className = "", setSubidaArchivo  }) => {
  const fileInput = useRef(null);
 
  const [cargandoSubida, setCargandoSubida] = useState(false);
  const [, setlinkDocumentoPath] = useState("");
  const [nombreArchivo, setNombreArchivo] = useState("");
  



  const handleChange = (e) => {
    handleChangeFile(e.target.files);
  };

  const handleChangeFile = async (files) => {
    try {
      setCargandoSubida(true);


      const fechaActual = new Date(Date.now()).toISOString();

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("name", files[0].name);
      formData.append("fecha", fechaActual);
      formData.append("modulo", "blacklist");
      formData.append("id", "123123123123");

      const { data, success, message } = await apiSubirLista(formData);
     
      if (success) {
        setlinkDocumentoPath(data.url);
        const {date, filename, size, url} = data
        setSubidaArchivo({
          fecha: date,
          nombre: filename,
          tamaño: size,
          url: url,
          estado: true,
        });
      
        setNombreArchivo(data.fileName);

        
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
      setCargandoSubida(false);
    } finally {
      setCargandoSubida(false);
    }
  };

  return (
    <div className={cn(`bg-card  grid gap-5 gap-y-2`, className)}>
      <div className="flex flex-col">
        <TextCustom text={titulo} className="fontPSemiBold" />
        <span className="opacity-90">{subtitulo}</span>
      </div>

      <div
        className="cardConfirm flex flex-col justify-center items-center gap-2  cursor-pointer"
        onClick={() => fileInput.current.click()}
      >
        <input
          type="file"
          accept=".xlsx "
          className="hidden"
          ref={fileInput}
          onChange={handleChange}
        />
        {cargandoSubida === true && <LoaderInput />}
        {cargandoSubida === false && <IconUpload />}
        {cargandoSubida === false && (
          <TextCustom
            text="Subir Archivo"
            color="general"
            className="fontPBold"
          />
        )}
      </div>

      {nombreArchivo && <ConfirmacionSubidaArchivo mensaje={nombreArchivo} />}
    </div>
  );
};

export default CardSubidaArchivo;
