// Components
import { FormControl } from '@mui/material';

// Utils
import { renderColor } from '../../../core/utils';

const FormControlCustom = ({ children = null, required = false }) => {
  return (
    <FormControl
      size="large"
      required={required}
      fullWidth
      sx={{
        marginTop: 1,
        '& .MuiInputLabel-asterisk': {
          display: 'none',
        },
        '& .MuiInputLabel-shrink': {
          marginLeft: 2,
          color: renderColor('texto'),
          fontWeight: '600',
          '& .MuiInputLabel-asterisk': {
            color: renderColor('danger'),
            display: 'inline',
          },
        },
      }}
    >
      {children}
    </FormControl>
  );
};

export default FormControlCustom;
