import { useState } from 'react';

// Components
import {
  RadioButtonsCotizaciones,
  RadioButtonsCustom,
  TituloCard,
} from '../../atoms';
import {
  CardDocumento,
  CardNumber,
  CardPrecio,
  CardRadio,
} from '../../molecules';

// Hooks
import { useOportunidad } from '../../../hooks/others';

const FormPreguntas = ({
  pregunta = '',
  tipoDato = '',
  valor = '',
  values = '',
}) => {
  const {
    etapaActual,
    setEtapaActual,
    dataEtapas,
    setDataEtapas,
    cuotas,
    etapaActualizada,
    setEtapaActualizada,
    cotizacionSeleccionada,
    cotizaciones,
  } = useOportunidad();
  const [value, setValue] = useState('');

  const handleSetForm = val => {
    let newEtapa = { ...etapaActual };
    let etapas = dataEtapas.map(etapa => {
      if (valor in etapa.values) etapa.values[valor] = val;
      if (etapa.idEtapa === newEtapa.idEtapa) etapa.values = newEtapa.values;
      if (etapa.idEtapa === etapaActualizada.idEtapa) {
        setTimeout(() => {
          setEtapaActualizada(etapa);
        }, 2000);
      }

      return etapa;
    });
    setTimeout(() => {
      setDataEtapas(etapas);
    }, 2000);
    setTimeout(() => {
      setEtapaActual(newEtapa);
    }, 1000);
    setValue(val);
  };

  const handleSetFormDoc = (val, path) => {
    let newEtapa = { ...etapaActual };

    let etapas = dataEtapas.map(etapa => {
      if (valor in etapa.values) etapa.values[valor] = val;
      if (etapa.idEtapa === newEtapa.idEtapa) {
        etapa.values = newEtapa.values;
        etapa.datos = etapa.datos.map(pregunta => {
          if (pregunta._id === path._id) {
            return path;
          }
          return pregunta;
        });
      }
      if (etapa.idEtapa === etapaActualizada.idEtapa)
        setEtapaActualizada(etapa);

      return etapa;
    });

    setTimeout(() => {
      setDataEtapas(etapas);
    }, 2000);
    setTimeout(() => {
      setEtapaActual(newEtapa);
    }, 1000);
    setValue(val);
  };

  const renderPregunta = () => {
    let newVal = '';
    let options = [];

    if (values[valor]) {
      newVal = values[valor];
    }

    switch (tipoDato) {
      case 'tabla-radio':
        options = pregunta.respuestas.map(respuesta => {
          return {
            id: respuesta.idPlan,
            nombreAseg: respuesta.aseguradora.nombre,
            descripcion: respuesta.aseguradora.descripcion,
            logoAseg: respuesta.aseguradora.logo,
            colorAseguradora: respuesta.aseguradora.color,
            precioTotal: respuesta.precios.find(precio => precio.codigo === 'A')
              .valor,
            precioCuota:
              respuesta.precios.length === 1
                ? ''
                : respuesta.precios.find(precio => precio.codigo === 'M').valor,
          };
        });

        return (
          <RadioButtonsCustom
            value={value}
            setValue={handleSetForm}
            options={options}
            defaulValue={newVal}
            pregunta={pregunta.value}
          />
        );
      case 'radio-cotizaciones':
        if (!newVal) {
          newVal = cotizacionSeleccionada._id;
        }
        return (
          <RadioButtonsCotizaciones
            value={value}
            setValue={handleSetForm}
            options={cotizaciones}
            defaulValue={newVal}
          />
        );
      case 'documento':
        return (
          <CardDocumento
            pregunta={pregunta}
            requerido={pregunta.requerido}
            linkDocumento={pregunta.linkDoc}
            onChange={handleSetFormDoc}
            val={valor}
            defaulValue={newVal}
          />
        );
      case 'card-number':
        return (
          <CardNumber
            pregunta={pregunta}
            value={value}
            defaulValue={newVal}
            setValue={handleSetForm}      
          />
        );
      case 'precio':
        return (
          <CardPrecio
            pregunta={pregunta}
            value={value}
            defaulValue={newVal}
            setValue={handleSetForm}
            cuotas={cuotas}
            values={values}
          />
        );
      case 'card':
        return (
          <CardRadio
            pregunta={pregunta}
            value={value}
            defaulValue={newVal}
            setValue={handleSetForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="card-tabs">
      <TituloCard titulo={pregunta.titulo} />
      {renderPregunta()}
    </div>
  );
};

export default FormPreguntas;
