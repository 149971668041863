// Styles
import './style.css';
import { renderColorEstado } from '../../../core/utils';

const ButtonCardsText = ({
  enabled = true,
  id = '',
  label = '',
  value = '',
  estado = '',
  onClick = () => null,
  data = '',
  width = 50,
  height = 50,
}) => {
  const handleClick = e => {
    enabled && onClick(e);
  };

  return (
    <div
      className={`m-2 flex flex-col items-center card-buttom gap-3 ${
        value === id && 'bg-general'
      }`}
      style={{
        backgroundColor:
          value === id ? estado && renderColorEstado(estado) : '#fff',
      }}
      id={id}
      onClick={() => handleClick(data)}
    >
      <div
        className="flex justify-center items-center"
        style={{ width: width, height: height }}
      >
        <span
          className={`fontPSemiBold text-center ${
            value === id ? 'color-white' : 'color-general'
          }  text-sm`}
        >
          {label}
        </span>
      </div>
    </div>
  );
};

export default ButtonCardsText;
