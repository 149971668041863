import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
require('dayjs/locale/es');

// Services
import { apiDashboardOportunidadEtapas } from '../../../services';

// Components
import { Loader, NoDataGrafico, TextCustom } from '../../atoms';

// Assets
import { ReactComponent as IconoAgenda } from '../../../assets/icons/Icono-Agenda.svg';

import dayjs from 'dayjs';
import { useOportunidad } from '../../../hooks/others';

const ChartOportunidadesPorEtapa = () => {
  const { divisaSeleccionada, valorDivisa } = useOportunidad();

  const params = useParams();

  const [value, setValue] = useState([
    dayjs().startOf('day'), // Fecha de hoy
    dayjs().add(1, 'day').startOf('day'), // Fecha de hoy + 1 día
  ]);

  const divisaRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);
  const [isValorCotizacionLleno, setisValorCotizacionLleno] = useState(false);

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
        distributed: true,
        horizontal: true,
        columnWidth: 10,
        borderRadius: 0,
      },
    },

    dataLabels: {
      enabled: false,
      textAnchor: 'start',
      style: {
        colors: ['#fff'],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },

    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  });

  useEffect(() => {
    setCargando(true);
    if (valorDivisa !== 0) {
      obtenerOportunidadesPorEtapas({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, valorDivisa]);

  useEffect(() => {
    divisaRef.current = divisaSeleccionada;

    const dataAConvertir = series[0].data;
    const currency = valorDivisa;

    //convierto los datos a dolares
    if (divisaSeleccionada === 'USD') {
      const dataConvert = dataAConvertir.map(dat =>
        (+dat / currency).toFixed(2),
      );

      setSeries([{ data: dataConvert }]);
    } else {
      //convierto los datos a colones
      const dataConvert = dataAConvertir.map(dat =>
        (+dat * currency).toFixed(2),
      );

      setSeries([{ data: dataConvert }]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisaSeleccionada]);

  const obtenerOportunidadesPorEtapas = async parametrosFecha => {
    let param = {
      aliadoId: params.id ? params.id : null,
    };
    const currency = valorDivisa;

    if (Object.keys(parametrosFecha).length) {
      param = { ...param, ...parametrosFecha };
    }

    const { success, data } = await apiDashboardOportunidadEtapas(param);

    if (success) {
      //comprobar si viene datos
      let dataResumen = data;

      dataResumen = dataResumen.map(dat => {
        let newData = { ...dat };
        newData.ValorCotizacion =
          dat.ValorCotizacion.USD * currency + dat.ValorCotizacion.CRC;
        return newData;
      });

      const valorCotizacion = dataResumen.reduce(
        (acc, op) => acc + op.ValorCotizacion,
        0,
      );

      valorCotizacion
        ? setisValorCotizacionLleno(true)
        : setisValorCotizacionLleno(false);

      const oportunidadValores = dataResumen.map(op =>
        op.ValorCotizacion.toFixed(2),
      );

      if (param.fechaInicio) {
        if (divisaSeleccionada === 'USD') {
          const dataConvert = oportunidadValores.map(dat =>
            (+dat / currency).toFixed(2),
          );

          setSeries([{ data: dataConvert }]);
        } else {
          setSeries([{ data: oportunidadValores }]);
        }
      } else {
        setSeries([{ data: oportunidadValores }]);
      }

      //seteanado los label que vienen
      setOptions({
        ...options,
        xaxis: {
          categories: data.map(op => op.nombre),
          labels: {
            style: {
              colors: ['#AAAAAA'],
              fontSize: '12px',
              fontFamily: 'Poppins-Regular',
              fontWeight: 400,
            },
            formatter: function (value) {
              if (value >= 1e6) {
                return (
                  (value / 1e6).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) + 'M'
                );
              } else if (value >= 1e3) {
                return (
                  (value / 1e3).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) + 'k'
                );
              } else {
                return value.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              }
            },
          },
        },
        tooltip: {
          theme: 'dark',
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Poppins-Regular',
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return '';
              },
            },
            formatter: function (val, { dataPointIndex }) {
              const labels = data.map(op => op.nombre);
              const label = labels[dataPointIndex];
              return `${label}: ${new Intl.NumberFormat(
                divisaRef.current === 'USD' ? 'en-US' : 'es-CR',
                {
                  style: 'currency',
                  currency: divisaRef.current === 'USD' ? 'USD' : 'CRC',
                },
              ).format(val)}`;
            },
          },
        },
        colors: data
          .map(item => item.colores.filter(color => color.tipo === 'primary'))
          .flat()
          .map(color => color.codigo),
      });

      setCargando(false);
    }
  };

  const handleShowDatePicker = () => {
    setOpen(true);
  };

  const handleChange = valor => {
    if (valor[1]) {
      setValue(valor);
      const fechaPrimera = valor[0].format('YYYY-MM-DD');
      const fechaSegunda = valor[1].format('YYYY-MM-DD');
      const formatted = `${fechaPrimera} / ${fechaSegunda}`;
      setSelectedDate(formatted);

      const parametrosFecha = {
        fechaInicio: fechaPrimera,
        fechaFin: fechaSegunda,
      };

      obtenerOportunidadesPorEtapas(parametrosFecha);
    }
  };

  const handleOpenPicker = () => {
    setOpen(false); // Cerrar el DateRangePicker estableciendo el estado 'open' en false
  };
  return (
    <div className="h-full">
      <div className="flex justify-between items-center">
        <TextCustom text="Oportunidades por etapa" className="fontPSemiBold " />
        <div
          className="bg-[#E5EBEF] rounded-[10px] flex items-center py-0.5 px-4 gap-3 cursor-pointer relative"
          onClick={handleShowDatePicker}
        >
          <IconoAgenda fill="#003763" className="w-[13px] h-[13px]" />
          <TextCustom
            text={` ${selectedDate ? selectedDate : 'Hoy'}`}
            className="text-[#003763] text-xs"
          />

          <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
            <DateRangePicker
              value={value}
              onChange={newValue => handleChange(newValue)}
              open={open}
              onClose={handleOpenPicker}
              className="invisible absolute"
              calendarClassName="custom-calendar"
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="h-full">
        {cargando ? (
          <Loader type="full" />
        ) : isValorCotizacionLleno ? (
          <Chart options={options} series={series} type="bar" height={320} />
        ) : (
          <NoDataGrafico tituloGrafico="Oportunidades por etapa" />
        )}
      </div>
    </div>
  );
};

export default ChartOportunidadesPorEtapa;
