import { useEffect, useState } from 'react';

// Components
import { ButtonCustom, TextCustom, TextInputCustom } from '../../atoms';
import { DialogActions, DialogContent } from '@mui/material';
import { DialogCustom } from '../../templates';

// Hooks
import { useForm } from '../../../hooks/others';

// Validations
import { formValidCambiarPassword } from '../../../core/validations';

// Assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const DialogCambiarPassword = ({
  isOpenModal = false,
  setIsOpenModal = () => null,
  actionSubmit = () => null,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [passwordConfirmacion, setPasswordConfirmacion] = useState('');
  const [showPasswordActual, setShowPasswordActual] = useState(false);
  const [showPasswordNueva, setShowPasswordNueva] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //validaciones
  const [formErrors, setFormErrors, reset] = useForm({
    passwordOld: '',
    passwordNew: '',
    passwordConfirmacion: '',
  });
  const [formSuccess, setFormSuccess] = useForm({
    passwordOld: false,
    passwordNew: false,
    passwordConfirmacion: false,
  });

  useEffect(() => {
    resetValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleSubmit = () => {
    const validation = handleValidForm();

    if (validation.isValid) {
      setIsLoading(true);

      let params;

      params = {
        passwordOld,
        passwordNew,
        passwordConfirmacion,
      };
      actionSubmit(params, setIsLoading);
    }
  };

  //* funcion de validacion del formulario
  const handleValidForm = () => {
    let mensaje = '';
    const params = { passwordOld, passwordNew, passwordConfirmacion };
    const { isValid, msgValid } = formValidCambiarPassword(params);

    if (validarObjeto(msgValid.errors)) mensaje = msgValid.errors.passwordNew;

    setFormErrors(msgValid.errors);
    setFormSuccess(msgValid.success);
    return { isValid, mensaje };
  };

  const validarObjeto = objeto => {
    if (
      Object.prototype.hasOwnProperty.call(objeto, 'passwordNew') &&
      objeto.passwordNew.trim() !== ''
    ) {
      for (var key in objeto) {
        if (
          key !== 'passwordNew' &&
          Object.prototype.hasOwnProperty.call(objeto, key) &&
          objeto[key].trim() !== ''
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const resetValues = () => {
    reset();
    setPasswordOld('');
    setPasswordNew('');
    setPasswordConfirmacion('');
    setShowPassword(false);
    setShowPasswordActual(false);
    setShowPasswordNueva(false);
  };

  const renderIcon = input => {
    if (input) {
      return (
        <VisibilityOff
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'transparent',
            color: '#999999',
          }}
        />
      );
    } else {
      return (
        <Visibility
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'transparent',
            color: '#999999',
          }}
        />
      );
    }
  };

  const formatData = (e, value) => {
    const valorInput = e.target.value;

    if (formErrors[value] && valorInput) {
      setFormErrors({ ...formErrors, [value]: '' });
    }
  };

  return (
    <DialogCustom
      open={isOpenModal}
      setOpen={handleCloseModal}
      title={`Cambiar contraseña `}
      disabledDismiss
    >
      <DialogContent style={{ width: 900 }}>
        <div className="md:grid md:grid-cols-2 gap-x-10 mt-10 px-7">
          <div className="mt-5 mb-5">
            <TextCustom text="Contraseña actual" required />
            <TextInputCustom
              placeholder="Contraseña actual"
              borderStyle={true}
              value={passwordOld}
              setValue={setPasswordOld}
              className="mb-2"
              msgError={formErrors.passwordOld}
              success={formSuccess.passwordOld}
              type={showPasswordActual ? 'text' : 'password'}
              iconEnd={renderIcon(showPasswordActual)}
              iconOnClick={() => setShowPasswordActual(!showPasswordActual)}
              iconMode="button"
              iconTypeColor="white"
              onKeyUp={event => formatData(event, 'passwordOld')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Contraseña nueva" required />
            <TextInputCustom
              placeholder="Contraseña nueva"
              borderStyle={true}
              value={passwordNew}
              setValue={setPasswordNew}
              className="mb-2"
              msgError={formErrors.passwordNew}
              success={formSuccess.passwordNew}
              type={showPasswordNueva ? 'text' : 'password'}
              iconEnd={renderIcon(showPasswordNueva)}
              iconOnClick={() => setShowPasswordNueva(!showPasswordNueva)}
              iconMode="button"
              iconTypeColor="white"
              onKeyUp={event => formatData(event, 'passwordNew')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Confirmar contraseña" required />
            <TextInputCustom
              placeholder="Confirmar contraseña"
              borderStyle={true}
              value={passwordConfirmacion}
              setValue={setPasswordConfirmacion}
              className="mb-2"
              msgError={formErrors.passwordConfirmacion}
              success={formSuccess.passwordConfirmacion}
              type={showPassword ? 'text' : 'password'}
              iconEnd={renderIcon(showPassword)}
              iconOnClick={() => setShowPassword(!showPassword)}
              iconMode="button"
              iconTypeColor="white"
              onKeyUp={event => formatData(event, 'passwordConfirmacion')}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="mx-auto mt-10">
          <ButtonCustom
            text={'Guardar'}
            typeColor="general"
            width={'150px'}
            onClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default DialogCambiarPassword;
