import Switch from 'react-switch';

// Utils
import { renderColor } from '../../../core/utils';

const IOSSwitchCustom = ({
  value = false,
  setValue = () => null,
  disabled = false,
  onColor = 'general',
  offColor = 'fondo',
  height = 28,
  width = 60,
  onLabel = 'ON',
  offLabel = 'OFF',
  className = '',
  showLabel = true,
}) => {
  const handleChange = () => {
    setValue(!value);
  };

  return (
    <div
      className={`m-1 ${className}`}
      style={{ borderColor: renderColor('primary'), border: 1 }}
    >
      <Switch
        onChange={handleChange}
        checked={value}
        onColor={disabled ? renderColor('ligthGray') : renderColor(onColor)}
        offColor={disabled ? renderColor('ligthGray') : renderColor(offColor)}
        height={height}
        width={width}
        disabled={disabled}
        uncheckedIcon={
          <div
            className="flex justify-center items-center fontPNormal"
            style={{
              width: '100%',
              height: '100%',
              color: renderColor('texto'),
            }}
          >
            {showLabel && (
              <span className="text-xs text-center">{offLabel}</span>
            )}
          </div>
        }
        checkedIcon={
          <div
            className="flex justify-center items-center"
            style={{ width: '100%', height: '100%' }}
          >
            {showLabel && (
              <span
                className="text-xs text-center pl-3"
                style={{ color: renderColor('white') }}
              >
                {onLabel}
              </span>
            )}
          </div>
        }
      />
    </div>
  );
};

export default IOSSwitchCustom;
