import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { Divider, Radio, RadioGroup } from '@mui/material';
import { ControlLabelCustom, TextCustom } from '../';

// Core
import { formatearFechaNacimiento, renderColor } from '../../../core/utils';

// Assets
import { ReactComponent as Checked } from '../../../assets/icons/CheckedRadio.svg';
import { ReactComponent as IconRadio } from '../../../assets/icons/RadioIcon.svg';

// Hooks
import { useOportunidad } from '../../../hooks/others';

// Services
import { postApiOportunidad } from '../../../services';

const RadioButtonsCotizaciones = ({
  value = '',
  setValue = () => null,
  options = [],
  isRow = false,
  disabled = false,
  size = undefined,
  typeColor = '',
  fontSize = null,
  defaulValue = '',
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { setDetalleCotizacion, setCotizacionSeleccionada } = useOportunidad();
  const [disabledRadio, setDisabledRadio] = useState(disabled);

  useEffect(() => {
    handleSetValues(defaulValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaulValue]);

  const handleSetValues = val => {
    setValue(val);
  };

  const handleChangeCotizacion = async event => {
    setDisabledRadio(true);

    const cotizacion = options.find(
      detalle => detalle._id === event.target.value,
    );
    setDetalleCotizacion(cotizacion?.detalleSeguro);
    setCotizacionSeleccionada(cotizacion);

    handleSetValues(event.target.value);

    const data = {
      oportunidad: params.id,
      cotizacion: event.target.value,
    };

    await postApiOportunidad(data);
    setDisabledRadio(false);
  };

  const renderFecha = fechaCreacion => {
    const fecha = new Date(fechaCreacion);

    const dia = fecha.getUTCDate();
    const mes = fecha.getUTCMonth() + 1;
    const año = fecha.getUTCFullYear();

    const fechaFormateada = `${dia < 10 ? '0' : ''}${dia}/${
      mes < 10 ? '0' : ''
    }${mes}/${año}`;

    return formatearFechaNacimiento(fechaFormateada);
  };

  const handleNavidgate = id => {
    navigate('/dashboard/cotizaciones/' + id);
  };

  return (
    <div className="py-5 grid gap-3">
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        row={isRow}
        value={value}
        onChange={handleChangeCotizacion}
      >
        <div className="grid grid-cols-3 gap-4">
          {options.map((option, index) => {
            const detalleSeguroName = Object.keys(option.detalleSeguro)[0];
            const detalleSeguroKeyValue = Object.values(
              option.detalleSeguro,
            )[0];

            // El value puede ser un array de objetos o una cadena
            const detalleSeguroValue = Array.isArray(detalleSeguroKeyValue)
              ? detalleSeguroKeyValue[0].value
              : detalleSeguroKeyValue;

            return (
              <div
                className="px-4 py-3 items-center rounded-xl relative"
                style={{ boxShadow: '1px 1px 20px #E5EBEF' }}
                key={index}
              >
                <div className="grid grid-cols-4 items-center gap-2">
                  <div className="col-span-3 grid">
                    <TextCustom
                      text="Cotización"
                      className={`fontPRegular text-xs`}
                    />
                    <TextCustom
                      text={option.codigo}
                      className={`fontPSemiBold`}
                    />
                  </div>
                  <div className="col-span-1 flex justify-end">
                    <ControlLabelCustom
                      key={index}
                      clase="mr-0"
                      value={option._id}
                    >
                      <Radio
                        disabled={disabledRadio}
                        size={size}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize },
                          '&.Mui-checked': {
                            color: renderColor(typeColor),
                          },
                          color: renderColor(typeColor),
                        }}
                        icon={<IconRadio width={'22px'} />}
                        checkedIcon={<Checked width={'22px'} />}
                      />
                    </ControlLabelCustom>
                  </div>
                </div>
                <Divider className="pt-1" style={{ borderColor: '#E5EBEF' }} />
                <div className={`flex justify-between pb-10 pt-4`}>
                  <div className="grid">
                    <TextCustom
                      text={detalleSeguroName}
                      className={`fontPSemiBold text-sm capitalize`}
                    />
                    <TextCustom
                      text={detalleSeguroValue}
                      className={`fontPSemiBold text-sm capitalize`}
                    />
                  </div>
                  <div className="grid">
                    <TextCustom text="Fecha" className="fontPRegular text-xs" />
                    <TextCustom
                      text={renderFecha(option.updatedAt)}
                      className="fontPSemiBold text-sm"
                    />
                  </div>
                </div>
                <div
                  onClick={() => handleNavidgate(option._id)}
                  className="bg-general cursor-pointer absolute left-0 bottom-0 w-full rounded-b-xl p-2 text-center text-white fontPSemiBold text-sm"
                >
                  Ver más
                </div>
              </div>
            );
          })}
        </div>
      </RadioGroup>
    </div>
  );
};

export default RadioButtonsCotizaciones;
