// Components
import { TextCustom } from '../../atoms';

// Style
import './style.css';

const CardActualizaciones = ({
  numero = '',
  color = '',
  texto = '',
  nombre = '',
}) => {
  const renderTexto = texto => {
    switch (texto) {
      case 'Cotización':
        return 'Oportunidades en Cotizaciones';
      case 'Documentación':
        return 'Oportunidades en Documentación';
      case 'Ganada':
        return 'Oportunidades Ganadas';

      default:
        return;
    }
  };

  return nombre === 'Dormida' ||
    nombre === 'Perdida' ||
    nombre === 'Pago' ||
    nombre === 'Emisión' ||
    nombre === 'Negociación' ? null : (
    <div className="grid card-actualizaciones card-tablero">
      <TextCustom
        text={numero ? numero : '0'}
        variant="h3"
        className="fontPBold text-center"
        colorHexa={color.codigo}
      />
      <TextCustom text={renderTexto(texto)} className="fontPSemiBold" />
    </div>
  );
};

export default CardActualizaciones;
