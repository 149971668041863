//Styles
import './styles.css';

//assets
import { ReactComponent as IconoLoader } from '../../../assets/icons/Loader/Cotizar.svg';

const Loader = ({ type = 'screen' }) => {
  return (
    <div
      className={`${type === 'screen' ? 'loader-modal-page' : 'h-full'} z-50 `}
    >
      <div
        id="contenedor"
        className={`${
          type === 'screen' ? 'h-screen contenedor-loader' : 'h-full'
        }`}
      >
        <IconoLoader />
      </div>
    </div>
  );
};

export default Loader;
