import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Loader } from "../../atoms";
import { TituloPage } from "../../molecules";
import TablePaginationServerSide from "../../templates/TablePaginationServerSide";

// Const
import { columnsTableCotizaciones } from "../../../common/tables";
import { typesTableActions } from "../../../common/types";

//Queries
import useQueryCotizaciones from "../../../hooks/queries/cotizaciones/useQueryCotizaciones";

// Services
import { categoriasFormateadas } from "../../../core/utils/functions";

const { tableView } = typesTableActions;

const Cotizaciones = () => {
  const navigate = useNavigate();
  const [filtro] = useState(JSON.parse(sessionStorage.getItem(`filterCotizaciones`)) || "",);
  const [value] = useState(JSON.parse(sessionStorage.getItem(`searchCotizaciones`)) || "",);
  const [fecha] = useState(JSON.parse(sessionStorage.getItem(`dateCotizaciones`)) || "",);

  const [filterParams, setFilterParams] = useState({
    limit: 10,
    page: 0,
    ...(filtro && { codSeguro: filtro }),
    ...(value && { search: value }),
    ...(fecha && { fecha_inicio: fecha.split(" ")[0], fecha_fin: fecha.split(" ")[1] }),
  });

  const { queryCotizaciones } = useQueryCotizaciones(filterParams);
  
  const { refetch } = queryCotizaciones;
  const categorias = categoriasFormateadas();
  const cotizacionesFiltradas = queryCotizaciones?.data?.cotizaciones ?? [];
  const totalPages = queryCotizaciones?.data?.pagination?.totalPages || 0;
  
  useEffect(() => {
    const shouldDelay = "search"|| "codSeguro" || "fecha_inicio" || "codEtapa" in filterParams;

    if (shouldDelay) {
      const getData = setTimeout(() => {
        refetch();
      }, 500);
      return () => clearTimeout(getData);
    }
  }, [filterParams]);
  
  const handleAction = (action, id, obj) => {
    navigate(`/dashboard/cotizaciones/${obj.id_cotizacion}`);
  };

  const handleFiltroSelect = valueSelect => {
    if (valueSelect === "") {
      // No hay filtro seleccionado, mostrar todas las cotizaciones
      setFilterParams({ ...filterParams, codSeguro: "" });
    } else {
      // Filtrar las cotizaciones por el valor seleccionado
      setFilterParams({ ...filterParams, codSeguro: valueSelect });
    }
  };

  return (
    <div>
      {queryCotizaciones.isPending && <Loader />}
      <div className="flex justify-between items-center mb-10">
        <TituloPage titulo="Cotizaciones" />
      </div>
      {/* <Suspense fallback={<Loader />}> */}
      <TablePaginationServerSide
        data={cotizacionesFiltradas}
        columns={columnsTableCotizaciones}
        identifierHidden={["id_aliado", "filtro"]}
        identifierAction={"filtro"}
        actions={[tableView]}
        actionClick={(action, id, obj) => handleAction(action, id, obj)}
        filterHeader={true}
        isSearch={true}
        isFilter={true}
        isLoading={queryCotizaciones.isPending}
        optionsFilter={categorias || []}
        handleFiltroSelect={handleFiltroSelect}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        totalPages={totalPages}
        type="Cotizaciones"
        isFilterDate={true}
        haveContenido={cotizacionesFiltradas.length > 0}
        textIfNotContent="No hay cotizaciones por mostrar"
      />
      {/* </Suspense> */}
      
    </div>
  );
};

export default Cotizaciones;
