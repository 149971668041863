// Componentes
import TextCustom from '../TextCustom';

const MensajeError = ({ mensaje = '', alignError = 'left' }) => {
  return (
    <div
      className={`flex gap-2 ${
        alignError === 'left' ? 'justify-start' : 'justify-center'
      } tems-center mt-3`}
    >
      <TextCustom text={mensaje} className="text-xs fontPRegular color-error" />
    </div>
  );
};

export default MensajeError;
