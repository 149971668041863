import { useEffect, useState } from 'react';

// Components
import { ButtonCardsText, TextCustom } from '../../atoms';

// Hooks
import { useOportunidad } from '../../../hooks/others';

const CardNumber = ({
  pregunta = '',
  value = '',
  setValue = () => null,
  defaulValue = '',
}) => {
  const [options, setOptions] = useState([]);
  const { setCuotas } = useOportunidad();

  useEffect(() => {
    let newOptions = pregunta.respuestas.map(respuesta => {
      const option = {
        id: respuesta._id,
        label: respuesta.cuotas,
      };
      return option;
    });

    const objetoOrdenado = newOptions.sort((a, b) => a.label - b.label);
    setOptions(objetoOrdenado);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaulValue && options.length > 0) {
      let newCuota = options.find(opc => opc.id === defaulValue);
      handleClick(newCuota);
    } else if (value === '' && options.length > 0) {
      handleClick(options[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleClick = val => {
    setCuotas(val);
    setValue(val.id);
  };
  return (
    <div className="mt-5">
      <TextCustom className="text-sm" text={pregunta.descripcion} />
      <div className="flex my-4">
        {options.map(respuesta => (
          <ButtonCardsText
            label={respuesta.label}
            id={respuesta.id}
            value={value}
            onClick={handleClick}
            key={respuesta.label}
            data={respuesta}
          />
        ))}
      </div>
    </div>
  );
};

export default CardNumber;
