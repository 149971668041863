import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AlertCustom,
  Loader,
  SelectCustom,
  TextCustom,
  TituloCard,
} from '../../atoms';
import {
  getUsuariosAliado,
  deleteApiQuitarUsuarioAliado,
  postApiAgregarUsuarioAliado,
} from '../../../services';
import { UsuariosAsignados } from '../../molecules';
import { useForm } from '../../../hooks/others';
import { buscarPermiso } from '../../../core/utils';
import { AuthContext } from '../../../hooks/context';

const CardUsuariosAliado = () => {
  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  const params = useParams();
  const navigate = useNavigate();

  const [usuariosAsignados, setUsuariosAsignados] = useState([]);
  const [usuariosSinAsignar, setUsuariosSinAsignar] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({});
  const [permisoDelete, setPermisoDelete] = useState(false);
  const [permisoPost, setPermisoPost] = useState(false);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  useEffect(() => {
    obtenerUsuarios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setPermisoDelete(buscarPermiso(permisosContext, 'aliados', 'delete'));
    setPermisoPost(buscarPermiso(permisosContext, 'aliados', 'post'));
  }, [permisosContext]);

  const obtenerUsuarios = async () => {
    const { success, data, statusCode } = await getUsuariosAliado(params.id);

    if (success) {
      setUsuariosAsignados(data.usuariosAsignados);

      const usuariosFormateados = data.usuarioSinAsignar.map(user => ({
        label: user?.nombre,
        id: user?._id,
      }));

      setUsuariosSinAsignar(usuariosFormateados);
      setCargando(false);
    } else {
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
  };

  const handleEliminarUsuario = async idUsuario => {
    const { success, message } = await deleteApiQuitarUsuarioAliado({
      idAliado: params.id,
      idUsuario,
    });

    if (success) {
      obtenerUsuarios();

      setAlert({
        title: '¡Usuario eliminado!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };
  const handleAgregarUsuario = async idUsuario => {
    setUsuarioSeleccionado(idUsuario);

    const { success, message } = await postApiAgregarUsuarioAliado({
      idAliado: params.id,
      idUsuario,
    });

    if (success) {
      obtenerUsuarios();

      setAlert({
        title: '¡Usuario asignado!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  return (
    <>
      {cargando && <Loader />}

      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      <TituloCard titulo="Usuarios" />
      <div className="flex flex-col justify-center gap-y-6 m-2 mt-6 break-words">
        {permisoPost && (
          <SelectCustom
            options={usuariosSinAsignar}
            className="w-full"
            value={usuarioSeleccionado.id}
            setValue={e => handleAgregarUsuario(e)}
            isFiltro={true}
            borderStyle={true}
            placeholder="Buscar"
          />
        )}

        <div>
          <TextCustom text="Asignados" className="text-sm" component="p" />
          {!usuariosAsignados?.length ? (
            <TextCustom
              text="Sin usuarios asignados"
              className="fontPSemiBold"
              component="p"
            />
          ) : (
            usuariosAsignados?.map(usuario => (
              <UsuariosAsignados
                usuario={usuario}
                key={usuario._id}
                handleEliminarUsuario={handleEliminarUsuario}
                permisoDelete={permisoDelete}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default CardUsuariosAliado;
