// Components
import { CardConfiguracion, TituloPage } from '../../molecules';

// Assets
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GppGoodIcon from '@mui/icons-material/GppGood';

const Configuration = () => {
  // Fake Data
  const arrayModulosConfig = [
    {
      id: 'usuarios',
      titulo: 'Usuarios',
      descripcion: 'Lista de usuarios',
      icono: <AccountCircleIcon className="text-6xl color-general" />,
      ruta: 'usuarios',
      metodo: 'get',
    },
    {
      id: 'blacklist',
      titulo: 'Lista Control',
      descripcion: 'Actualizar lista negra',
      icono: <AccountCircleIcon className="text-6xl color-general " />,
      ruta: 'blacklist',
      metodo: 'post',
    },

    {
      id: 'aseguradoras',
      titulo: 'Aseguradoras',
      descripcion: 'Lorem Ipsum is simply dummy text of the printing',
      icono: <GppGoodIcon className="text-6xl color-general" />,
      ruta: 'aseguradoras',
    },

    {
      id: 'monedas',
      titulo: 'Monedas',
      descripcion: 'Lorem Ipsum is simply dummy text of the printing',
      icono: <AttachMoneyIcon className="text-6xl color-general" />,
      ruta: 'monedas',
    },
  ];

  return (
    <div>
      <TituloPage titulo="Configuración" />

      <div>
        <div className="grid grid-cols-3 gap-8 mt-5">
          {arrayModulosConfig.map(modulo => (
            <CardConfiguracion
              id={modulo.id}
              key={modulo.id}
              titulo={modulo.titulo}
              descripcion={modulo.descripcion}
              icono={modulo.icono}
              ruta={modulo.ruta}
              metodo={modulo.metodo}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
