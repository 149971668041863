import { memo } from 'react';

// Components
import { InputLabel, Select } from '@mui/material';
import { FormControlCustom, MensajeError, MenuItemCustom } from '../';

// Utils
import { renderColor } from '../../../core/utils';

// Assets
import { ReactComponent as SelectIcon } from '../../../assets/icons/SelectIcon.svg';
import { ReactComponent as SelectIconBlack } from '../../../assets/icons/SelectIcon-black.svg';

// Styles
import './style.css';

const SelectCustom = ({
  name = null,
  options = [],
  value = '',
  setValue = () => null,
  msgError = '',
  success = false,
  disabled = false,
  required = false,
  fontSize = 18,
  className = '',
  defaultValue = '',
  borderStyle = false,
  placeholder = '',
  isFiltro = false,
  altura = '',
  overflow = '',
}) => {
  const handleChange = event => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  return (
    <div
      className={`flex flex-col ${
        isFiltro ? 'select-custom' : ''
      } ${className}`}
    >
      <FormControlCustom required={required}>
        <InputLabel id="demo-simple-select-label" style={{ fontSize }}>
          {name}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label={name}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          className={`w-full bg-negro text-base ${altura ? altura : 'h-auto'} `}
          size="large"
          disabled={disabled}
          inputProps={{ style: { padding: '13.5px 14px' } }}
          IconComponent={isFiltro ? SelectIconBlack : SelectIcon}
          placeholder={placeholder}
          displayEmpty
          MenuProps={{
            PaperProps: {
              style: {
                overflowY: overflow ? overflow : 'scroll',
                scrollbarWidth: 'thin',
                borderRadius: 10,
              },
              className: `text-[#003763]`,
            },
            className: 'z-[2100] mui-md:z-[1300] text-[#003763]',
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: renderColor('general'),
              fontFamily: 'Poppins-SemiBold',
              // paddingLeft: '10px',
            },
            //cuando hace hover
            '&.MuiOutlinedInput-root': {
              '&:hover > fieldset': {
                border:
                  msgError.length > 0 || success ? 2 : borderStyle ? 1 : 0,
                borderColor:
                  msgError.length > 0
                    ? renderColor('danger')
                    : success
                    ? renderColor('general')
                    : borderStyle
                    ? renderColor('bordes')
                    : 'transparent',
              },
            },

            '& .MuiSelect-select': {
              padding: isFiltro ? '10px 14px' : '15.5px 14px',
            },
            '& MuiPaper-root': {
              marginTop: 1,
              maxHeight: '300px',
            },
            '& MuiMenu-paper': {
              background: renderColor('danger'),
            },
            '& legend': {
              marginLeft: 2,
              fontSize: fontSize * 0.82,
            },
            '& fieldset': {
              borderRadius: 5,
              border: msgError.length > 0 || success ? 2 : borderStyle ? 1 : 0,
              borderColor:
                msgError.length > 0
                  ? renderColor('danger')
                  : success
                  ? renderColor('general')
                  : borderStyle
                  ? renderColor('bordes')
                  : 'transparent',
              color: renderColor('texto'),
              boxShadow:
                !borderStyle && '0px 6px 40px ' + renderColor('sombra'),
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: renderColor('general'),
              color: renderColor('texto'),
            },
            '&.MuiFormControl-fullWidth': {
              marginTop: 0,
            },
            backgroundColor: disabled
              ? renderColor('ligthGray')
              : renderColor('white'),
            borderRadius: 5,
            marginTop: 0,
          }}
        >
          <MenuItemCustom
            className={`${!placeholder && 'hidden'} text-base`}
            disabled
            value=""
          >
            <span
              className="fontPSemiBold text-sm md:text-base"
              style={{ color: 'rgba(0,55,99,0.4)', letterSpacing: '0' }}
            >
              {placeholder}
            </span>
          </MenuItemCustom>
          {options.map((option, index) => (
            <MenuItemCustom key={index} value={option?.id}>
              {option?.label}
            </MenuItemCustom>
          ))}
        </Select>
      </FormControlCustom>

      {msgError && <MensajeError mensaje={msgError} />}
    </div>
  );
};

export default memo(SelectCustom);
