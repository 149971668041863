import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import { AlertCustom, CheckBoxCustom, IOSSwitchCustom } from '../../atoms';

import { renderColor } from '../../../core/utils';
import { apiAsignarEliminarPermisoUsuario } from '../../../services/apis/usuarios/apiUsuarios';
import { useForm } from '../../../hooks/others';

const TableVistaUsuario = ({ permisosUsuario = [] }) => {
  const params = useParams();

  const [permisosModulos, setPermisosModulos] = useState({});
  const [dataInicial] = useState([
    {
      modulo: 'cotizaciones',
    },
    {
      modulo: 'oportunidades',
    },
    {
      modulo: 'personas',
    },
    {
      modulo: 'usuarios',
    },
    {
      modulo: 'aliados',
    },
  ]);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  useEffect(() => {
    const nuevaPermisos = dataInicial.map(modulo => {
      const permisos = permisosUsuario.find(
        permiso => permiso.nombre === modulo.modulo,
      );
      if (permisos) {
        return {
          ...modulo,
          activar: permisos.permisos.includes('get'),
          crear: permisos.permisos.includes('post'),
          editar: permisos.permisos.includes('patch'),
          borrar: permisos.permisos.includes('delete'),
        };
      } else {
        return {
          ...modulo,
          activar: false,
          crear: false,
          editar: false,
          borrar: false,
        };
      }
    });
    setPermisosModulos(
      nuevaPermisos.reduce(
        (acc, modulo) => ({ ...acc, [modulo.modulo]: modulo }),
        {},
      ),
    );
  }, [dataInicial, permisosUsuario]);

  const handlePermisosChange = async (modulo, permiso) => {
    let metodo;

    switch (permiso) {
      case 'activar':
        metodo = 'get';
        break;
      case 'crear':
        metodo = 'post';
        break;
      case 'borrar':
        metodo = 'delete';
        break;
      case 'editar':
        metodo = 'patch';
        break;

      default:
        break;
    }

    const request = {
      id_usuario: params.id,
      nombre_permiso: modulo,
      metodo,
    };

    const { success, message } = await apiAsignarEliminarPermisoUsuario(
      request,
    );

    if (success) {
      setAlert({
        title: '¡Permiso actualizado!',
        description: message,
        open: true,
        severity: 'success',
      });

      setPermisosModulos(prevState => ({
        ...prevState,
        [modulo]: {
          ...prevState[modulo],
          [permiso]: !prevState[modulo][permiso],
        },
      }));

      if (permiso === 'activar' && permisosModulos[modulo][permiso]) {
        setPermisosModulos(prevState => ({
          ...prevState,
          [modulo]: {
            ...prevState[modulo],
            crear: false,
            editar: false,
            borrar: false,
          },
        }));
      }
    } else {
      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  return (
    <>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      <div className="m-5 mt-6 ">
        <table className="w-full fontPRegular border-spacing-y-2 border-separate ">
          <thead>
            <tr
              style={{
                color: renderColor('white'),
                backgroundColor: renderColor('general'),
              }}
            >
              <th className="px-3 pl-10 py-2 flex items-center">Modulo</th>
              <th className="px-3 pl-10 py-2 text-left">Activar</th>
              <th className="px-3 pl-10 py-2 text-left">Crear</th>
              <th className="px-3 pl-10 py-2 text-left">Editar</th>
              <th className="px-3 pl-10 py-2 text-left">Borrar</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(permisosModulos).map((data, index) => (
              <tr key={index} className="shadow-table">
                <td className="px-3 py-4 pl-5 2xl:pl-10 fontPSemiBold">
                  {data.modulo === 'personas'
                    ? 'Contactos'
                    : data.modulo.charAt(0).toUpperCase() +
                      data.modulo.slice(1)}
                </td>
                <td className="px-3 py-4 pl-5 2xl:pl-10 ">
                  {data.activar !== undefined && (
                    <IOSSwitchCustom
                      value={data.activar}
                      setValue={() =>
                        handlePermisosChange(data.modulo, 'activar')
                      }
                      showLabel={false}
                    />
                  )}
                </td>
                <td className="px-3 py-4 pl-5 2xl:pl-10">
                  {data.crear !== undefined && (
                    <CheckBoxCustom
                      value={data.crear}
                      setValue={() =>
                        handlePermisosChange(data.modulo, 'crear')
                      }
                      disabled={!data.activar}
                      typeColor="general"
                    />
                  )}
                </td>
                <td className="px-3 py-4 pl-5 2xl:pl-10">
                  {data.editar !== undefined && (
                    <CheckBoxCustom
                      value={data.editar}
                      setValue={() =>
                        handlePermisosChange(data.modulo, 'editar')
                      }
                      disabled={!data.activar}
                      typeColor="general"
                    />
                  )}
                </td>
                <td className="px-3 py-4 pl-5 2xl:pl-10">
                  {data.borrar !== undefined && (
                    <CheckBoxCustom
                      value={data.borrar}
                      setValue={() =>
                        handlePermisosChange(data.modulo, 'borrar')
                      }
                      disabled={!data.activar}
                      typeColor="general"
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableVistaUsuario;
