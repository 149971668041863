import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';

// Hooks
import { AuthContext } from '../../../hooks/context';

// Const
import { typesGlobalState } from '../../../common/types';

const { authLogout } = typesGlobalState;

const Error500 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, dispatchAuth } = useContext(AuthContext);

  const Error500Image =
    'https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Errors/Error500+.webp';

  useEffect(() => {
    if (location.state) {
      if (location.state.statusCode === 403) {
        dispatchAuth({ type: authLogout });
        navigate('/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    if (auth.isLogin) {
      navigate('/dashboard/home');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="contenedor-page-error flex justify-center items-center h-screen w-screen flex-col bg-white absolute top-0 gap-5">
      <img src={Error500Image} alt="Error500Image" className="img-500" />
      <TextCustom
        text="ERROR 500"
        className="fontPMedium color-primary text-xl pt-1"
      />
      <TextCustom
        text="Parece que hemos encontrado un error"
        variant="h2"
        className="fontPBold color-general"
      />
      <TextCustom
        text="Perdona las molestias, ya estamos trabajando para solucionarlo."
        className="fontPRegular"
      />
      <ButtonCustom
        text="Regresar"
        height="auto"
        width="200px"
        onClick={handleGoBack}
      />
    </div>
  );
};

export default Error500;
