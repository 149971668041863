import { useEffect } from 'react';

// Components
import { Divider, Radio, RadioGroup } from '@mui/material';
import { ControlLabelCustom, TextCustom } from '../';

// Core
import { renderColor } from '../../../core/utils';

import { ReactComponent as Checked } from '../../../assets/icons/CheckedRadio.svg';
import { ReactComponent as IconRadio } from '../../../assets/icons/RadioIcon.svg';
import { useOportunidad } from '../../../hooks/others';

const RadioButtonsCustom = ({
  value = false,
  setValue = () => null,
  options = [],
  isRow = false,
  disabled = false,
  size = undefined,
  typeColor = '',
  fontSize = null,
  defaulValue = '',
  pregunta = '',
}) => {
  const {setAseguradoraSeleccionada} = useOportunidad();

  useEffect(() => {
    handleSetValues(defaulValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaulValue]);

  const handleSetValues = val => {
    if (pregunta === 'plan') {
      const plan = options.find(data => data.id === val);
      if (plan !== undefined) {
        setAseguradoraSeleccionada(plan.descripcion);
      }
    }
    setValue(val);
  };

  const handleChange = event => {
    handleSetValues(event.target.value);
  };

  return (
    <div className="py-5 grid gap-3">
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        row={isRow}
        value={value}
        onChange={handleChange}
      >
        <div className="grid grid-cols-3 gap-4">
          {options.map((option, index) => (
            <div
              className="px-4 py-3 items-center rounded-xl"
              style={{ boxShadow: '1px 1px 20px #E5EBEF' }}
              key={index}
            >
              <div className="grid grid-cols-4 items-center gap-2">
                <div className="col-span-3">
                  <img
                    src={option.logoAseg}
                    alt="Logo aseguradora"
                    className="w-[130px] h-auto"
                  />
                </div>
                <div className="col-span-1 flex justify-end">
                  <ControlLabelCustom
                    key={index}
                    clase="mr-0"
                    value={option.id}
                  >
                    <Radio
                      disabled={disabled}
                      size={size}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize },
                        '&.Mui-checked': {
                          color: renderColor(typeColor),
                        },
                        color: renderColor(typeColor),
                      }}
                      icon={<IconRadio width={'22px'} />}
                      checkedIcon={<Checked width={'22px'} />}
                    />
                  </ControlLabelCustom>
                </div>
              </div>
              <Divider className="pt-1" style={{ borderColor: '#E5EBEF' }} />
              <div
                className={`flex ${
                  option.precioCuota ? 'justify-between' : 'justify-center'
                } pb-3 pt-4`}
              >
                <div className="grid">
                  <TextCustom
                    text={option.precioTotal}
                    className={`text-center fontPSemiBold text-[${option.colorAseguradora}]`}
                  />
                  <TextCustom
                    text="Prima total + IVA"
                    className="text-center text-sm"
                  />
                </div>
                {option.precioCuota && (
                  <div className="grid">
                    <TextCustom
                      text={option.precioCuota}
                      className="text-center"
                    />
                    <TextCustom
                      text="Cuota mensual"
                      className="text-center text-sm"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default RadioButtonsCustom;
