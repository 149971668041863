import { memo } from 'react';

// Components
import { Dialog } from '@mui/material';
import { TextCustom } from '../../atoms';
import { DialogTitleCustom } from '../../molecules';

const DialogCustom = ({
  children = null,
  disabledDismiss = false,
  disabledIconClose = false,
  onDismiss = () => null,
  open = false,
  setOpen = () => null,
  title = '',
  className = '',
  colorHexa = '',
}) => {
  const handleClose = () => {
    onDismiss();
    setOpen(false);
    return false;
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => !disabledDismiss && handleClose()}
      maxWidth={false}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
        },
        '& .MuiDialogContent-root': {
          padding: '40px 40px 0px',
        },
        '& .MuiDialogActions-root': {
          padding: '40px',
          paddingTop: '0',
        },
      }}
      className={`dialog-modal ${className}`}
    >
      <DialogTitleCustom
        onClose={handleClose}
        disabledIconClose={disabledIconClose}
        colorHexa={colorHexa}
      >
        <TextCustom
          text={title}
          variant="h4"
          className="fontPMedium  text-white"
        />
      </DialogTitleCustom>
      {children}
    </Dialog>
  );
};

export default memo(DialogCustom);
