import { typesValidation } from '../../common/types';
import { validInputEmail } from './validateInput';

const { validateEmail } = typesValidation;

export const validFormEditUsuario = user => {
  const dataResponse = {
    isValid: true,
    msgValid: {
      errors: null,
      success: null,
    },
  };
  let isValid = true;
  let inputsError = {
    email: '',
  };
  let inputsSuccess = {
    email: true,
  };

  if (!user.nombre) {
    inputsError.nombre = 'Nombre de usuario no ha sido asiganado.\n';
    inputsSuccess.nombre = false;
    isValid = false;
  }

  if (!user.correo) {
    inputsError.correo = 'Correo no ha sido asignado.\n';
    inputsSuccess.correo = false;
    isValid = false;
  }

  if (!inputsError.correo && !validInputEmail(user.correo, validateEmail)) {
    inputsError.correo = 'Correo no válido.\n';
    inputsSuccess.correo = false;
    isValid = false;
  }
  dataResponse.isValid = isValid;
  dataResponse.msgValid.errors = inputsError;
  dataResponse.msgValid.success = inputsSuccess;
  return dataResponse;
};
