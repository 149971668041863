import axios from 'axios';
import { endpoints } from '../../../common/endpoints';
import buildRequest from '../../buildRequest';

//localstorage
import { stGetToken } from '../../storage';

export const apiLogin = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.login}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const request = {
    email: params.email,
    password: params.password,
  };

  try {
    const response = await axios[method](url, request, buildRequest());

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const apiCrearUsuario = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.usuarios}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    nombre: params.nombre,
    correo: params.correo,
    password: params.password,
    id_rol: params.rol,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.error = error.response.data.error;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const apiListarUsuarios = async () => {
  const url = `${process.env.REACT_APP_api}${endpoints.usuarios}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  if (!token) return;

  try {
    const response = await axios[method](url, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const apiDetalleUsuarios = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.usuarios}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    id_usuario: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const apiCambiarEstadoUsuario = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.usuariosCambioEstado}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    id_usuario: params,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.message = data.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const apiAsignarEliminarPermisoUsuario = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.permisos}`;

  const method = 'patch';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    id_usuario: params.id_usuario,
    nombre_permiso: params.nombre_permiso,
    metodo: params.metodo,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.message = data.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const apiResetPasswordUsuario = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.resetPassword}`;

  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    error: '',
    data: [],
  };
  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    id_usuario: params.id_usuario,
    passwordNueva: params.password,
    passwordAnterior: params?.passwordAnterior,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.message = data.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.error = error.response.data.error;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const apiEditPerfil = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.perfil}`;

  const method = 'patch';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    nombre: params?.nombre,
    correo: params?.correo,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.message = data.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};


export const apiEditRolUser = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.editRolUser}`;

  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const { data: token } = stGetToken();

  if (!token) return;

  const request = {
    nombreRol: params.nombreRol,
    userId: params.userId
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
    dataResponse.message = data.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};