import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import {
  AlertCustom,
  ButtonCustom,
  TextInputCustom,
  TextCustom,
  CheckBoxCustom,
} from '../../atoms';

// Hooks
import { AuthContext } from '../../../hooks/context';
import { useForm } from '../../../hooks/others';

// Servicios de autenticacion y API
import { apiLogin } from '../../../services';
import {
  stSetIsAuth,
  stSetListPermisos,
  stSetPersonalInfo,
  stSetToken,
} from '../../../services/storage';

// Const
import { typesActionsAuth, typesValidation } from '../../../common/types';

// Core
import { formValidLogin, validInputEmail } from '../../../core/validations';

// Assets
import './style.css';
import ImageIniciarSesion from '../../../assets/images/Login/CRM-Asegurame.png';
import { ReactComponent as Logo } from '../../../assets/images/Logo-Asegurame.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const { authLogin } = typesActionsAuth;

const Login = () => {
  const navigate = useNavigate();

  const { dispatchAuth } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recuerdame, setRecuerdame] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Validations
  const [showAlert, setShowAlert] = useState(false);
  const [formErrors, setFormErrors, resetFormErrors] = useForm({
    email: '',
    password: '',
  });
  const [formSuccess, setFormSuccess, resetFormSuccess] = useForm({
    email: false,
    password: false,
  });
  const [alert, setAlert, resetAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
  });

  const resetForm = () => {
    resetFormErrors();
    resetFormSuccess();
    resetAlert();
    setEmail('');
    setPassword('');
  };

  const handleLogin = async () => {
    //verficando que llaya pasado las validaciones
    if (handleValidForm()) {
      const params = { email, password };

      try {
        //aqui ya retornaria la informacion del usuario
        const dataResponse = await apiLogin(params);
        const { data, success, message } = dataResponse;
        if (!success) {
          setShowAlert(true);
          setAlert({
            title: '¡Ha ocurrido un error!',
            description: message,
            severity: 'error',
          });
          return;
        }
        // almacenando el usuario
        const payload = {
          personalInfo: data.usuario,
          token: data.token,
          permisos: data.permisos,
          categorias: data.categorias,
          etapas: data.etapas,
        };
        dispatchAuth({ type: authLogin, payload });
        resetForm();
        //mandar los datos para que los almacene en localStorage
        guardarSesion(payload);

        //verificar si el usuario necesita cambiar el password
        if (payload.personalInfo.needResetPassword) {
          redirectNeedResetPassword();
        }
      } catch (error) {
        setShowAlert(true);
        setAlert({
          title: '¡Ha ocurrido un error!',
          description:
            'Inténtalo nuevamente o contacta al equipo de Desarrollo.',
          severity: 'error',
        });
      }
    }
  };

  const redirectNeedResetPassword = () => {
    navigate('/dashboard/perfil', { state: { openModal: true } });
  };

  const handleValidForm = () => {
    const params = { email, password };
    const responseValid = formValidLogin(params);
    const { isValid, msgValid } = responseValid;
    setFormErrors(msgValid.errors);
    setFormSuccess(msgValid.success);

    return isValid;
  };

  //Funcion para guardar la sesion del usuario
  const guardarSesion = payload => {
    stSetIsAuth(true);
    stSetPersonalInfo(payload.personalInfo);
    stSetToken(payload.token);
    stSetToken(payload.token);
    stSetListPermisos(payload.permisos);
  };

  const formatData = (e, value) => {
    const valorInput = e.target.value;

    if (formErrors[value] && valorInput) {
      if (
        value === 'email' &&
        !validInputEmail(valorInput, typesValidation.validateEmail)
      ) {
        setFormErrors({
          ...formErrors,
          [value]: 'Formato de correo electrónico no válido.',
        });
      } else {
        setFormErrors({ ...formErrors, [value]: '' });
      }
    }
  };

  const renderIcon = () => {
    if (showPassword) {
      return (
        <VisibilityOff
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'transparent',
            color: '#999999',
          }}
        />
      );
    } else {
      return (
        <Visibility
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: 'transparent',
            color: '#999999',
          }}
        />
      );
    }
  };

  return (
    <div className="grid-home">
      <div className="flex justify-center items-center h-100">
        <div className="flex flex-col contenedor-form-login  gap-4 px-6 py-8">
          <Logo className="mb-5" />
          <div className="flex flex-col my-4 relative">
            <AlertCustom
              title={alert.title}
              description={alert.description}
              open={showAlert}
              setOpen={setShowAlert}
              severity={alert.severity}
            />

            <div className="flex flex-col gap-4 rounded-xl relative">
              <div>
                <TextCustom text="Correo electrónico" />
                <TextInputCustom
                  placeholder="Ingresa tu correo electrónico"
                  borderStyle={true}
                  type="email"
                  value={email}
                  setValue={setEmail}
                  msgError={formErrors.email}
                  success={formSuccess.email}
                  onKeyUp={event => formatData(event, 'email')}
                />
              </div>
              <div className="mt-5">
                <TextCustom text="Contraseña" />
                <TextInputCustom
                  placeholder="Ingresa tu contraseña"
                  borderStyle={true}
                  type={showPassword ? 'text' : 'password'}
                  iconEnd={renderIcon()}
                  iconOnClick={() => setShowPassword(!showPassword)}
                  iconMode="button"
                  iconTypeColor="white"
                  value={password}
                  setValue={setPassword}
                  onEnter={handleLogin}
                  msgError={formErrors.password}
                  success={formSuccess.password}
                  onKeyUp={event => formatData(event, 'password')}
                  className="w-full"
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="flex items-center">
                  <CheckBoxCustom
                    value={recuerdame}
                    setValue={setRecuerdame}
                    typeColor="general"
                  />
                  <TextCustom text="Recuérdame" />
                </div>

                <div className="text-center md:text-right">
                  {/* <Link to={'/'}>
                    <TextCustom
                      text="¿Olvidaste tu contraseña?"
                      className="fontPSemiBold"
                      color="general"
                    />
                  </Link> */}
                </div>
              </div>
              <ButtonCustom
                text="Iniciar"
                onClick={handleLogin}
                className=" py-3 mt-5"
                typeColor="general"
                width="100%"
              />
            </div>
            {/* {loader && <Loader mode="modal" type="full" />} */}
          </div>
        </div>
      </div>
      <div className="bg-general">
        <div className="flex justify-center items-center h-full">
          <img src={ImageIniciarSesion} alt="Imagen Inicio de Sesion" />
        </div>
      </div>
    </div>
  );
};

export default Login;
