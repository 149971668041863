import { apiEditRolUser } from '../../services';

export const useActualizarRolUsuario = (setAlert) => {
  const actualizarRol = async (rol, idUser) => {
    const paramsEdit = {
      nombreRol: rol,
      userId: idUser,
    };

    try {
        const { success } = await apiEditRolUser(paramsEdit);
        if (success) {
          setAlert({
            title: '¡Usuario Editado!',
            description: 'El Rol del usuario ha sido editado.',
            open: true,
            severity: 'success',
          });

          return success
        } else {
          setAlert({
            title: 'Error al editar Usuario',
            description: 'El rol ya está asignado correctamente.',
            open: true,
            severity: 'success',
          });
        }
      
        return false
    } catch (error) {
      console.error('Error al editar el rol:', error);

      setAlert({
        title: '¡Error de servidor!',
        description: 'Hubo un problema al intentar editar el rol.',
        open: true,
        severity: 'error',
      });

      return false
    }
  };

  return { actualizarRol, setAlert};
};
