//Componentes
import { DialogCustom } from '../../templates';
import { TextCustom, ButtonCustom } from '../../atoms';
import { DialogActions, DialogContent } from '@mui/material';

const ModalPermiso = ({ isOpen = false, setIsOpen = () => null , modulo=""}) => {
  return (
    <DialogCustom open={isOpen} setOpen={setIsOpen} colorHexa="#FFFFFF">
      <DialogContent className="w-[630px]">
        <TextCustom text="Permiso denegado" className="fontPRegular font-bold text-2xl flex justify-center items-center text-primary"/>
        <p className="text-center text-base fontPRegular pt-4">
        Estimado usuario, actualmente no tiene permisos para acceder al módulo de {modulo}, favor contactarse con el administrador del sistema.
        </p>
      </DialogContent>
      <DialogActions className='flex justify-center items-center pb-8 pt-6'>
        <div className="flex flex-col">
          <ButtonCustom
            text="Aceptar"
            variant="contained"
            typeColor="general"
            width={'150px'}
            onClick={setIsOpen}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default ModalPermiso;
