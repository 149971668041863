import { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";


// Assets
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import { ReactComponent as IconoAgenda } from "../../../assets/icons/Calendario/Icono-Agenda.svg";

// Components
import { Loader, SelectCustom, TextCustom, TextInputCustom } from "../../atoms";
import { TableRows } from "../../molecules";
import { renderColor } from "../../../core/utils";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { useLocation } from "react-router-dom";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

import { ButtonExportExcel } from "../../molecules/ButtonExportExcel";
import TablePaginationDynamic from "../../molecules/TablePaginationDynamic";
import TableHeadersPagination from "../../molecules/TableHeaderPagination";


const TablePaginationServerSide = ({
  columns = [],
  data = [],
  dataExport = null,
  isSearch = false,
  isFilter = false,
  identifierHidden = undefined,
  identifierAction = undefined,
  actionColumnTitle = "",
  actions = [],
  actionClick = () => null,
  isResetPagina = false,
  setIsResetPagina = () => null,
  filterHeader = true,
  optionsFilter = [],
  centrar = false,
  handleFiltroSelect = () => null,
  haveContenido = true,
  textIfNotContent = "No hay contenido",
  isLoading = false,
  filterParams,
  setFilterParams,
  totalPages = 0,
  type = "",
  isFilterDate = false,
}) => {
  const location = useLocation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
    allColumns,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: filterParams.page,
        pageSize: filterParams.limit,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination
  );

  const [filtro, setFiltro] = useState(JSON.parse(sessionStorage.getItem(`filter${type}`)) || "");
  const [value, setValue] = useState(JSON.parse(sessionStorage.getItem(`search${type}`)) || "");  
  const [fecha, setFecha] = useState(JSON.parse(sessionStorage.getItem(`date${type}`)) || "");

  const [bandera, setBandera] = useState(!!fecha);
  const [limit, setLimit] = useState("");
  const array = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const arrayLimit = array.map(array => ({
    label: array,
    id: array,
  }));

  useEffect(() => {
    if (identifierHidden) {
      const columnHide = allColumns.filter(column =>
        identifierHidden.includes(column.id),
      );

      columnHide.forEach(hide => hide?.toggleHidden(true));
      // columnHide?.toggleHidden(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatFecha = fecha => {
    const aux = fecha.split("-");
    const fechaFormat = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return fechaFormat.replace(/\s+/g, "");
  };

  useEffect(() => {
    if (fecha && bandera) {
      const fecha1 = formatFecha(fecha.split(" ")[0]);
      const fecha2 = formatFecha(fecha.split(" ")[1]);
      setFecha(`${fecha1} - ${fecha2}`);
    }
  }, [filterParams.fecha_inicio, filterParams.fecha_fin,]);
  
const handleFiltro = val => {
    setFiltro(val);
    handleFiltroSelect(val);
    if (val) {
      sessionStorage.setItem(`filter${type}`, JSON.stringify(val));
    } else {
      sessionStorage.removeItem(`filter${type}`);
    }
  };

  const handleLimit = val => {
    setLimit(val);
    setFilterParams({ ...filterParams, limit: val });
  };

  const handlePageChange = newPageIndex => {
    if (newPageIndex !== "...") {
      setFilterParams({ ...filterParams, page: newPageIndex + 1 });
      gotoPage(newPageIndex);
    }
  };

  const handleNextPage = () => {
    if (canNextPage) {
      setFilterParams({ ...filterParams, page: pageIndex + 2 });
      nextPage();
    }
  };

  const handlePreviousPage = () => {
    if (canPreviousPage) {
      setFilterParams({ ...filterParams, page: pageIndex });
      previousPage();
    }
  };

  const handleChange = valor => {
    if (valor[1]) {
      const fechaPrimera = valor[0].format("YYYY-MM-DD");
      const fechaSegunda = valor[1].format("YYYY-MM-DD");
      setBandera(false);
      if (valor) {
        sessionStorage.setItem(
          `date${type}`,
          JSON.stringify(fechaPrimera + " " + fechaSegunda),
        );
      } else {
        sessionStorage.removeItem(`date${type}`);
      }
      setFilterParams({
        ...filterParams,
        fecha_inicio: fechaPrimera,
        fecha_fin: fechaSegunda,
      });
    }
  };

  const handleSearch = val => {
    setValue(val);
    if (val) {
      sessionStorage.setItem(`search${type}`, JSON.stringify(val));
    } else {
      sessionStorage.removeItem(`search${type}`);
    }
  };

  return (
    <div className="w-full relative">
      {(isFilter || isSearch) && (
        <div
          className={`flex flex-col xl:flex-row xl:absolute gap-4 justify-end items-center  filtro-tabla ${
            centrar && "-top-44 -translate-y-1"
          }   `}
        >
          {isFilter && (
            <div className="flex gap-3 items-center">
              <TextCustom text="Filtro: " component="p" variant="p" />
              <SelectCustom
                options={optionsFilter}
                className="w-60 mt-0"
                setValue={handleFiltro}
                value={filtro}
                borderStyle={true}
                isFiltro={true}
              />
            </div>
          )}

          {isFilterDate && (
            <div className="flex gap-3 items-center">
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DateRangePicker
                  onChange={newValue => handleChange(newValue)}
                  name="allowedRange"
                  className="w-60 mt-0  "
                  sx={{
                    minWidth: 270,
                    "& .MuiOutlinedInput-root ": {
                      borderRadius: "50px",
                    },
                  }}
                  calendars={2}
                  slots={{ field: SingleInputDateRangeField }}
                  slotProps={{
                    textField: {
                      InputProps: { endAdornment: <IconoAgenda /> },
                      placeholder: bandera ? fecha : "Fechas",
                    },
                    field: {
                      clearable: true,
                      onClear: () => {
                        setFilterParams({
                          ...filterParams,
                          fecha_inicio: null,
                          fecha_fin: null,
                        });
                        sessionStorage.removeItem(`date${type}`);
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
          )}

          {isSearch && (
            <div>
              <TextInputCustom
                value={value}
                setValue={handleSearch}
                className={"mb-2 w-1/2 xl:w-full"}
                iconStart={<SearchIcon />}
                paddingY="10px"
                borderStyle={true}
                placeholder="Buscar"
                filtroServer={true}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
              />
            </div>
          )}
          {location.pathname.includes("oportunidades") && (
            <ButtonExportExcel
              module="oportunidades"
              width="4rem"
              height="3rem"
              data={dataExport}
            />
          )}
          {location.pathname.includes("contactos") && (
            <ButtonExportExcel
              module="contactos"
              width="4rem"
              height="3rem"
              data={dataExport}
            />
          )}
        </div>
      )}

      {haveContenido ? (
        <>
          <table
            className="w-full fontPRegular border-spacing-y-2 border-separate "
            {...getTableProps()}
            style={isLoading ? { display: "block" } : {}}
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    color: renderColor("white"),
                    backgroundColor: renderColor("general"),
                  }}
                >
                  <TableHeadersPagination
                    headerGroup={headerGroup}
                    isActions={actions.length > 0}
                    actionColumnTitle={actionColumnTitle}
                    filterHeader={filterHeader}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    type={type}
                  />
                </tr>
              ))}
            </thead>

            <tbody
              {...getTableBodyProps()}
              style={
                isLoading ? { display: "grid", placeContent: "center" } : {}
              }
            >
              {isLoading ? (
                <div className="h-[400px] ">
                  <Loader type="full" />
                </div>
              ) : (
                <TableRows
                  identifierName={identifierAction}
                  isActions={actions.length > 0}
                  actions={actions}
                  actionClick={actionClick}
                  page={page}
                  prepareRow={prepareRow}
                />
              )}
            </tbody>
          </table>

          <TablePaginationDynamic
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={handlePageChange}
            nextPage={handleNextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            previousPage={handlePreviousPage}
            isResetPagina={isResetPagina}
            setIsResetPagina={setIsResetPagina}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            totalPages={totalPages}
          />
        </>
      ) : (
        <>
          <table
            className="w-full fontPRegular border-spacing-y-2 border-separate "
            {...getTableProps()}
            style={isLoading ? { display: "block" } : {}}
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    color: renderColor("white"),
                    backgroundColor: renderColor("general"),
                  }}
                >
                  <TableHeadersPagination
                    headerGroup={headerGroup}
                    isActions={actions.length > 0}
                    actionColumnTitle={actionColumnTitle}
                    filterHeader={filterHeader}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    type={type}
                  />
                </tr>
              ))}
            </thead>
          </table>
          <TextCustom
            text={textIfNotContent}
            color="general"
            className="font-bold text-center py-16"
            variant="p"
            component="p"
          />
        </>
      )}
      <div className="flex gap-3 items-center">
        <TextCustom text="Filas por página: " component="p" variant="p" />
        <SelectCustom
          options={arrayLimit}
          className="w-20 mt-0"
          setValue={handleLimit}
          value={limit}
          borderStyle={true}
          isFiltro={true}
          placeholder="10"
        />
      </div>
    </div>
  );
};

export default TablePaginationServerSide;
