import TextCustom from '../TextCustom';

const NoDataGrafico = ({ tituloGrafico = '' }) => {
  return (
    <div className="flex flex-col justify-center items-center  h-full ">
      <img
        src="https://aseguramecr.s3.us-east-2.amazonaws.com/Imagenes/Administrativo/NoData.webp"
        alt="No hay ventas por mes"
        className="w-[140px] h-[140px]"
      />

      <TextCustom
        text="Aún no hay datos de"
        className="text-[#222222] fontPSemiBold text-base mt-5"
      />
      <TextCustom
        text={tituloGrafico}
        className="text-[#222222] fontPSemiBold text-base"
      />
    </div>
  );
};

export default NoDataGrafico;
