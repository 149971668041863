// Assets
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as SortIcon } from '../../../assets/icons/tablas/SortIcon.svg';

// Utils
import { renderColor } from '../../../core/utils';

const TableHeaders = ({
  actionColumnTitle = '',
  headerGroup = {},
  isActions = false,
  filterHeader,
}) => {
  return (
    <>
      
      {headerGroup.headers.map(column => (
    
        <th
          key={column.id}
          className={`px-3 pl-10 py-2 ${
            column.id === "nombre_seguro" ||
            column.id === "contacto" ||
            column.id === "fecha_creacion" ||
            column.id === "aliado" ||
            column.id === "fechaNacimiento"
              ? " w-[350px] "
              : column.id === "telefono"
              ? "w-[250px]  "
              : column.id === "correo"
              ? "w-[370px] "
              : column.id === "fecha_creacion" ||
                column.id === "valor" ||
                column.id === "estado"
              ? "w-[190px] "
              : ""
          } `}
          {...column.getHeaderProps(column.getSortByToggleProps())}
        >
          <span
            className={`flex items-center ${
              column.render('Header').length !== 0
            }`}
          >
            {column.render('Header')}
            {filterHeader && column.render('Header').length !== 0 ? (
              column.isSorted ? (
                column.isSortedDesc ? (
                  <ArrowDropUpIcon fill={renderColor('white')} />
                ) : (
                  <ArrowDropDownIcon fill={renderColor('white')} />
                )
              ) : (
                <SortIcon
                  fill={renderColor('white')}
                  className="w-3 h-3 ml-1"
                />
              )
            ) : (
              ''
            )}
          </span>
        </th>
      ))}
      {isActions && (
        <th className="pl-3 pr-6 py-2 text-end">{actionColumnTitle}</th>
      )}
    </>
  );
};

export default TableHeaders;
