import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Loader } from '../../atoms';
import { TituloPage } from '../../molecules';
import { TableCustom } from '../../templates';

// Const
import { columnsTableContacto } from '../../../common/tables';
import { typesTableActions } from '../../../common/types';

// Services Api
import { getContactos } from '../../../services';

// Funciones
import {
  formatearFechaNacimiento,
  renderInicialesNombre,
} from '../../../core/utils';

const { tableView } = typesTableActions;

const Contactos = () => {
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(true);
  const [contactoData, setContactoData] = useState([]);

  useEffect(() => {
    const obtenerContactos = async () => {
      const { success, data, statusCode } = await getContactos();

      if (success) {
        const contactos = data.map(contacto => {
          const primerNombre = contacto.nombre.split(' ')[0] || '';
          const segundoNombre = contacto.nombre.split(' ')[1] || '';
          const primerApellido = contacto.nombre.split(' ')[2] || segundoNombre;
          const segundoApellido =
            contacto.nombre.split(' ')[3] || segundoNombre;

          const nombre1ConApellidos = `${primerNombre} ${primerApellido} ${segundoApellido}`;
          const nombre2ConApellidos = `${segundoNombre} ${primerApellido} ${segundoApellido}`;
          const nombre1ConApellido2 = `${primerNombre} ${segundoApellido}`;
          const nombre2ConApellido2 = `${segundoNombre} ${segundoApellido}`;
          return {
            idContacto: contacto._id,
            nombre: renderInicialesNombre(contacto.nombre),
            contacto: contacto.nombre,
            telefono: '(506) ' + contacto.telefono,
            correo: contacto.correo,
            fechaNacimiento: contacto?.fecha_nacimiento
              ? formatearFechaNacimiento(contacto.fecha_nacimiento)
              : '-',
            other: contacto.nombre,
            nombre1Apellidos: nombre1ConApellidos,
            nombre2Apellidos: nombre2ConApellidos,
            nombre1Apellido2: nombre1ConApellido2,
            nombre2Apellido2: nombre2ConApellido2,
          };
        });

        setContactoData(contactos);
      } else {
        if (statusCode === 403) {
          navigate('/page-error-401');
          return;
        }

        navigate('/page-error-500', { state: { statusCode: statusCode } });
      }

      setCargando(false);
    };

    obtenerContactos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = (action, id) => {
    navigate(`/dashboard/contactos/${id}`);
  };

  return (
    <div>
      {cargando && <Loader />}
      <div className="grid grid-cols-2 items-center mb-8">
        <TituloPage titulo="Contactos" />
      </div>
      <TableCustom
        data={contactoData}
        dataExport={{fileName: 'contactos',headers: ["Nombre", "Correo Electrónico", "Teléfono", "Fecha de Nacimiento"], data: contactoData.map( c => 
          ({ "Nombre": c.other, "Correo Electrónico": c.correo, "Teléfono": c.telefono, "Fecha de Nacimiento": c.fechaNacimiento  }))}}
        columns={columnsTableContacto}
        identifierHidden={[
          'idContacto',
          'other',
          'nombre1Apellidos',
          'nombre2Apellidos',
          'nombre1Apellido2',
          'nombre2Apellido2',
        ]}
        identifierAction="idContacto"
        actions={[tableView]}
        actionClick={(action, id, obj) => handleAction(action, id, obj)}
        filterHeader={true}
        
        isSearch={true}
      />
    </div>
  );
};

export default Contactos;
