import { useEffect, useState } from 'react';

// Components
import { ButtonCustom, TextCustom, TextInputCustom } from '../../atoms';
import { DialogActions, DialogContent } from '@mui/material';
import { DialogCustom } from '../../templates';

const DialogMotivoEtapa = ({
  open = false,
  setOpen = () => null,
  accion = () => null,
  etapa = '',
}) => {
  const [motivo, setMotivo] = useState('');
  const [btnMotivo, setBtnMotivo] = useState(true);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    motivo ? setBtnMotivo(false) : setBtnMotivo(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motivo]);

  useEffect(() => {
    setMotivo('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAccept = async () => {
    setisLoading(true);
    await accion(etapa, motivo);
    setisLoading(false);
  };

  return (
    <DialogCustom
      open={open}
      setOpen={setOpen}
      title={`Oportunidad: ${etapa.label}`}
      colorHexa={etapa.colorPrimary}
      disabledDismiss
    >
      <DialogContent style={{ width: 700 }}>
        <div className="my-10">
          <TextCustom text={`Razón de ${etapa.label}`} />
          <TextInputCustom
            value={motivo}
            setValue={setMotivo}
            multiline={true}
            borderStyle={true}
          />
        </div>
      </DialogContent>
      <DialogActions className="justify-center">
        <ButtonCustom
          text="Aceptar"
          typeColor="general"
          onClick={handleAccept}
          width={'150px'}
          disabled={btnMotivo}
          loading={isLoading}
        />
      </DialogActions>
    </DialogCustom>
  );
};

export default DialogMotivoEtapa;
