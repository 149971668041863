import { useContext } from 'react';

// Components
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Assets
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Styles
import './styles.css';
import { checkAccess } from '../../../core/utils';
import { AuthContext } from '../../../hooks/context';

const DrawerItem = ({
  text = '',
  onClick = () => null,
  icon = null,
  isSelected = false,
  collapse = false,
  isCollapse = false,
  isBottom = false,
  ruta = '',
  metodo = '',
  access = true,
}) => {
  const handleClick = () => {
    onClick();
  };

  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  if (!localStorage.getItem('listPermisos')) {
    access = checkAccess(ruta, metodo, permisosContext);
  } else {
    access = checkAccess(ruta, metodo);
  }

  if (access) {
    return (
      <ListItem
        disablePadding
        onClick={handleClick}
        className={`drawer-item-color my-0 w-full pl-8 ${
          isSelected ? 'drawer-item-color-select' : ''
        }
        ${!isBottom && 'py-2'}`}
      >
        <div className="w-full py-2 flex cursor-pointer items-center">
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText className="txt-nav" primary={text} />
          {isCollapse ? collapse ? <ExpandLess /> : <ExpandMore /> : null}
        </div>
      </ListItem>
    );
  }
};

export default DrawerItem;
