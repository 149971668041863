export const formValidDatosPersonales = (p, valor, dataKey) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  let validaciones = [];

  const valorDato = p.value ? p.value : p.codigo;

  if (valorDato === dataKey) {
    if (p.requerido && !valor) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (valorDato === 'correo' && valor && !regexEmail.test(valor)) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (valorDato === 'telefono' && valor && valor.length !== 9) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (
      valorDato === 'numeroIdentificacion' &&
      valor &&
      valor.length !== 9
    ) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (
      valor &&
      valorDato !== 'correo' &&
      valorDato !== 'telefono' &&
      valor.length > 30
    ) {
      validaciones.push({ campo: dataKey, error: true });
    } else if (p.requerido && valor === 'rechazado') {
      validaciones.push({ campo: dataKey, error: true });
    } else if (p.requerido && valor === 'pendiente') {
      validaciones.push({ campo: dataKey, error: true });
    }
  }
  return validaciones;
};
