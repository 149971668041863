export const drawerWidth = 290;

export const modulos = [
  {
    nombre: 'Cotizaciones',
    id: 'Cotizaciones',
  },
  {
    nombre: 'Seguros',
    id: 'Seguros',
  },
  {
    nombre: 'Coberturas',
    id: 'Coberturas',
  },
  {
    nombre: 'Requisitos',
    id: 'Requisitos',
  },
  {
    nombre: 'Promociones',
    id: 'Promociones',
  },
  {
    nombre: 'Usuarios',
    id: 'Usuarios',
  },
];

export const mensajesBitacora = dataSeguroContacto => {
  const { categoria, dataContacto, codigo } = dataSeguroContacto;

  const mensajes = {
    COT: `Se ha creado una cotización de ${categoria.nombre} para ${dataContacto.nombre}.`,
    EMI: 'Los documentos necesarios han sido aprobados. Se procederá a generar la póliza una vez recibido el pago.',
    DOC: 'Se ha solicitado la documentación requerida para avanzar en la contratación.',
    NEG: 'Se ha iniciado la negociación para discutir los términos y condiciones de la cotización.',
    DOR: 'La oportunidad ha sido marcada como ‘Dormida’ por lo que se pospondrá la contratación temporalmente.',
    PER: 'La oportunidad ha sido marcada como ‘Perdida’, tras la falta de respuesta o interés.',
    GAN: 'La oportunidad ha sido ganada tras completar todas las etapas del proceso. ¡Felicitaciones al equipo!',
    PAG: 'En espera la confirmación de pago para finalizar la emisión de la póliza.',
  };

  return mensajes[codigo];
};
