import { Link } from 'react-router-dom';

// Components
import { TextCustom } from '../../atoms';

// Assets
import './style.css';
import { checkAccess } from '../../../core/utils';

const CardConfiguracion = ({
  titulo = '',
  descripcion = '',
  icono = '',
  ruta = '',
  metodo = '',
  access = true,
}) => {
  access = checkAccess(ruta, metodo);
  if (access) {
    return (
      <Link to={`/dashboard/configuration/${ruta}`}>
        <div className="card-configuracion p-5 grid">
          <div className="flex justify-center items-center">{icono}</div>
          <div>
            <TextCustom text={titulo} className="text-lg fontPSemiBold" />{' '}
            <br />
            <TextCustom text={descripcion} className="text-sm fontPNormal" />
          </div>
        </div>
      </Link>
    );
  }
};

export default CardConfiguracion;
