import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Components
import { AlertCustom, Loader, TextCustom, TituloCard } from '../../atoms';
import {
  CardContacto,
  CardDatosPersonales,
  TituloPerfil,
} from '../../molecules';
import { TableCustom } from '../../templates';

// Service
import { getDetalleContacto, patchActualizarContacto } from '../../../services';

// Utils
import {
  buscarPermiso,
  formatearFecha,
  formatearFechaNacimiento,
  formatearIdentificacion,
  renderTipoSeguro,
} from '../../../core/utils';

// Const
import { columnsTableCotizacionesC } from '../../../common/tables';
import { typesTableActions } from '../../../common/types';

// Hooks
import { AuthContext } from '../../../hooks/context';
import { DialogContacto } from '../../organisms';
import { useForm } from '../../../hooks/others';

const { tableView } = typesTableActions;

const PerfilContacto = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  const [detalleContacto, setDetalleContacto] = useState([]);
  const [nombre, setNombre] = useState('');
  const [oportunidades, setOportunidades] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [permisoGetOportunidad, setPermisoGetOportunidad] = useState(false);
  const [permisoGetCotizaciones, setPermisoGetCotizaciones] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  useEffect(() => {
    setPermisoGetOportunidad(
      buscarPermiso(permisosContext, 'oportunidades', 'get'),
    );
    setPermisoGetCotizaciones(
      buscarPermiso(permisosContext, 'cotizaciones', 'get'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permisosContext]);

  useEffect(() => {
    obtenerDetalleCotizacion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const obtenerDetalleCotizacion = async () => {
    const { success, data, statusCode } = await getDetalleContacto(params.id);

    if (success) {
      const oportunidadFormateada = data.oportunidades.map(oportunidad => ({
        id_oportunidad: oportunidad?._id,
        nombre_seguro: renderTipoSeguro(
          oportunidad?.categoria?.codigo,
          '',
          oportunidad?.categoria?.nombre,
        ),
        filtro: oportunidad?.categoria?.nombre,
        contacto: oportunidad?.contacto?.primer_nombre,
        fecha_creacion: formatearFecha(oportunidad.createdAt).toUpperCase(),
        aliado: oportunidad?.aliado?.nombre,
        estado: oportunidad?.etapa?.nombre,
      }));
      setOportunidades(oportunidadFormateada);

      const nombreCompleto = `${data.primer_nombre}${
        !data.segundo_nombre ? '' : ' ' + data.segundo_nombre
      } ${data.primer_apellido}${
        !data.segundo_apellido ? '' : ' ' + data.segundo_apellido
      }`;

      setNombre(nombreCompleto);
      setDetalleContacto(data);
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }

    setCargando(false);
  };

  const handleBack = () => {
    navigate('/dashboard/contactos');
  };

  const handleOportunidadContacto = () => {
    navigate('/dashboard/oportunidades', {
      state: detalleContacto?.primer_nombre,
    });
  };

  const handleFiltrarCotizaciones = contacto => {
    const filtro = contacto.primer_nombre + ' ' + contacto.primer_apellido;
    navigate('/dashboard/cotizaciones', { state: filtro });
  };

  const handleAction = (action, id, obj) => {
    navigate(`/dashboard/oportunidades/${obj.id_oportunidad}`);
  };

  const handleShowModal = res => {
    setShowModal(res);
  };

  const actionSubmit = async params => {
    const respuesta = await patchActualizarContacto(params);
    const { success, message, statusCode } = respuesta;

    if (success) {
      setShowModal(false);
      obtenerDetalleCotizacion();
      setAlert({
        title: 'Listo',
        description: 'Contacto actualizado',
        open: true,
        severity: 'success',
      });
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  return (
    <div>
      {cargando && <Loader />}

      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      <TituloPerfil handleBack={handleBack} titulo={nombre} />
      <div className="pt-10 grid grid-cols-4 gap-4">
        <div className="bg-card col-span-2">
          <CardDatosPersonales
            primerNombre={detalleContacto?.primer_nombre}
            segundoNombre={
              detalleContacto?.segundo_nombre
                ? detalleContacto?.segundo_nombre
                : '-'
            }
            identificacion={formatearIdentificacion(
              detalleContacto?.numero_identificacion,
            )}
            primerApellido={detalleContacto?.primer_apellido}
            segundoApellido={
              detalleContacto?.segundo_apellido
                ? detalleContacto?.segundo_apellido
                : '-'
            }
            nacimiento={
              detalleContacto?.fecha_nacimiento
                ? formatearFechaNacimiento(detalleContacto?.fecha_nacimiento)
                : '-'
            }
          />
        </div>
        <div className="bg-card">
          <CardContacto
            correo={detalleContacto?.correo}
            telefono={detalleContacto?.telefono}
            editable={true}
            handleShowModal={handleShowModal}
          />
        </div>
        <div className="gap-4 grid">
          <div className="bg-card">
            <TituloCard titulo="Número de cotizaciones" />
            {permisoGetCotizaciones ? (
              <div
                className="flex items-center h-5/6"
                onClick={() => handleFiltrarCotizaciones(detalleContacto)}
              >
                <TextCustom
                  text={
                    detalleContacto.cotizaciones?.length
                      ? detalleContacto.cotizaciones.length + ' Cotizaciones'
                      : '0 Cotizaciones'
                  }
                  className="fontPSemiBold underline cursor-pointer"
                />
              </div>
            ) : (
              <div className="flex items-center h-5/6">
                <TextCustom
                  text={
                    detalleContacto.cotizaciones
                      ? detalleContacto.cotizaciones + ' Cotizaciones'
                      : '0 Cotizaciones'
                  }
                  className="fontPSemiBold "
                />
              </div>
            )}
          </div>
          <div className="bg-card">
            <TituloCard titulo="Oportunidades" />
            {permisoGetOportunidad ? (
              <div
                className="flex items-center h-5/6 cursor-pointer"
                onClick={() => handleOportunidadContacto(detalleContacto?._id)}
              >
                <TextCustom
                  text={` ${oportunidades.length} Oportunidades`}
                  className="fontPSemiBold underline "
                />
              </div>
            ) : (
              <div className="flex items-center h-5/6">
                <TextCustom
                  text={` ${detalleContacto.oportunidades} Oportunidades`}
                  className="fontPSemiBold  "
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {permisoGetOportunidad && (
        <div className="mt-8 bg-card">
          <TituloCard titulo="Oportunidades" />
          <div className="m-5 mt-6">
            {oportunidades.length > 0 ? (
              <TableCustom
                data={oportunidades}
                columns={columnsTableCotizacionesC}
                identifierHidden="idCotizacion"
                identifierAction="idCotizacion"
                actions={[tableView]}
                actionClick={(action, id, obj) => handleAction(action, id, obj)}
                filterHeader={false}
              />
            ) : (
              <TextCustom
                text="No hay oportunidades"
                className="text-center font-bold"
                component="p"
              />
            )}
          </div>
        </div>
      )}

      <DialogContacto
        isOpenModal={showModal}
        setIsOpenModal={setShowModal}
        contacto={{
          correo: detalleContacto.correo,
          telefono: detalleContacto.telefono,
          id: detalleContacto._id,
        }}
        actionSubmit={actionSubmit}
      />
    </div>
  );
};

export default PerfilContacto;
