import { useContext } from 'react';

// Components
import { Button, CircularProgress } from '@mui/material';
import { Tooltip } from "@mui/material";


// Core
import { checkAccess, renderColor } from '../../../core/utils';
import { AuthContext } from '../../../hooks/context';

const ButtonCustom = ({
  text = '',
  onClick = () => null,
  variant = 'contained',
  className = '',
  startIcon = null,
  endIcon = null,
  disabled = false,
  typeColor = 'primary',
  textTransform = 'none',
  width = '',
  height = '',
  ruta = '',
  metodo = '',
  access = true,
  loading = false,
  disableTooltip = true
}) => {
  const { auth } = useContext(AuthContext);
  const { permisos: permisosContext } = auth;

  if (!localStorage.getItem('listPermisos')) {
    access = checkAccess(ruta, metodo, permisosContext);
  } else {
    access = checkAccess(ruta, metodo);
  }

  if (access) {
    return (
      <Tooltip title = "Exportar" disableHoverListener={disableTooltip}> 
           <div className="relative">
        <Button
          color="primary"
          variant={variant}
          onClick={onClick}
          className={`fontPSemiBold px-4 ${
            loading ? 'pointer-events-none' : ''
          } ${className}`}
          startIcon={startIcon}
          endIcon={endIcon}
          disabled={disabled}
          sx={{
            textTransform: textTransform,
            backgroundColor:
              variant === 'contained' && !disabled
                ? renderColor(typeColor)
                : undefined,
            borderColor:
              (variant === 'outlined' || variant === 'text') && !disabled
                ? renderColor(typeColor)
                : undefined,
            color:
              (variant === 'outlined' || variant === 'text') && !disabled
                ? renderColor(typeColor)
                : undefined,
            borderRadius: '100px',
            fontSize: '16px',
            boxShadow: 'none',
            width: width ? width : 230,
            height: height ? height : 50,
            padding: '9px 16px',
            "& .MuiButton-startIcon": {margin: "0px"},
            '&:hover': {
              backgroundColor:
                variant === 'contained' && !disabled
                  ? `${renderColor(typeColor)}CF`
                  : (variant === 'outlined' || variant === 'text') && !disabled
                  ? `${renderColor(typeColor)}0F`
                  : undefined,
              borderColor:
                (variant === 'outlined' || variant === 'text') && !disabled
                  ? renderColor(typeColor)
                  : undefined,
            },
          }}
        >
          {text}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: renderColor(typeColor),
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </div>
      
      </Tooltip>
 
    );
  }
};

export default ButtonCustom;
