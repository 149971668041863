// Components
import { TextCustom } from '../../atoms';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Utils
import { renderColor } from '../../../core/utils';

const TituloPage = ({ titulo = '', action = null }) => {
  return (
    <div className="flex gap-3">
      <TextCustom
        text={titulo}
        className="fontPSemiBold"
        variant="h2"
        color="general"
      />
      {action !== null && (
        <div className="flex items-center justify-end">
          <AddCircleIcon
            className="text-5xl cursor-pointer"
            onClick={action}
            style={{ color: renderColor('general') }}
          />
        </div>
      )}
    </div>
  );
};

export default TituloPage;
