import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//components
import {
  AlertCustom,
  ItemBitacora,
  Loader,
  TextCustom,
  TextInputCustom,
  TituloCard,
} from '../../atoms';

// Services
import { getApiBitacora, postApiBitacora } from '../../../services';

// Hooks
import { useForm, useOportunidad } from '../../../hooks/others';

// Utils
import { buscarPermiso, checkAccess } from '../../../core/utils';

const optionsBtn = [
  {
    texto: 'Contacto 1',
  },
  {
    texto: 'Contacto 2',
  },
  {
    texto: 'No responde',
  },
];

const CardBitacora = ({ access = true, ruta = '', metodo = '' }) => {
  const { etapaActualizada, etapaActual, setRazonEtapa } = useOportunidad();
  const navigate = useNavigate();
  const params = useParams();

  const permisos = JSON.parse(localStorage.getItem('listPermisos'));
  access = checkAccess(ruta, metodo);

  const [btnSeleccionado, setbtnSeleccionado] = useState('');
  const [detalleBitacora, setDetalleBitacora] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [comentario, setComentario] = useState('');
  const [permisoPost, setPermisoPost] = useState(false);
  const [view, setView] = useState(false);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  useEffect(() => {
    setPermisoPost(buscarPermiso(permisos, 'bitacora_oportunidad', 'post'));
  }, [permisos]);

  useEffect(() => {
    obtenerDetalleBitacora();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etapaActualizada]);

  const obtenerDetalleBitacora = async () => {
    const { success, data, statusCode } = await getApiBitacora(params.id);

    if (success) {
      setDetalleBitacora(data);
      data.map(bitacora => {
        let descripcion = bitacora.descripcion;

        if (
          descripcion.includes('Razon:') &&
          etapaActual?.label === bitacora.etapa.nombre
        ) {
          setRazonEtapa(descripcion.split('Razon: ')[1]);
          return descripcion;
        }
      });
    } else {
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
    setCargando(false);
  };

  const handleEnter = async () => {
    if (!comentario) {
      setAlert({
        title: '¡Agrega un comentario!',
        description:
          'Se requiere un comentario para poder guardar el mensaje en la bitácora.',
        open: true,
        severity: 'error',
      });
      return;
    }

    const request = {
      comentario,
      oportunidadId: params.id,
      etapaId: etapaActual.idEtapa,
    };

    const { success, message } = await postApiBitacora(request);

    if (success) {
      setbtnSeleccionado('');
      setComentario('');
      setAlert({
        title: '¡Comentario agregado!',
        description: 'Se agregó el comentario correctamente.',
        open: true,
        severity: 'success',
      });

      obtenerDetalleBitacora();
    } else {
      setAlert({
        title: 'Error',
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  const hadleClick = async accion => {
    setbtnSeleccionado(accion);
    setComentario(accion + ': ');
  };

  const handleBlur = () => {
    if (!comentario && btnSeleccionado) {
      setComentario('');
      setbtnSeleccionado('');
    }
  };

  const handleViewMore = () => {
    setView(!view);
  };

  if (access) {
    return (
      <div className="card-tabs">
        {cargando && <Loader />}

        <AlertCustom
          title={alert.title}
          description={alert.description}
          open={alert.open}
          resetValues={alertReset}
          severity={alert.severity}
        />

        <TituloCard titulo="Bitácora" />

        {permisoPost && (
          <>
            <div className="mt-5 flex gap-2">
              {optionsBtn.map(option => (
                <p
                  key={option.texto}
                  className={` py-0.5 px-5 rounded-full fontPRegular hover:cursor-pointer text-sm fontPSemiBold  ${
                    option.texto === btnSeleccionado
                      ? 'bg-[#003763] text-white'
                      : 'bg-[#DDDDDD] text-[#666666]'
                  }`}
                  onClick={() => hadleClick(option.texto)}
                >
                  {option.texto}
                </p>
              ))}
            </div>

            <TextInputCustom
              borderStyle={true}
              placeholder="Escribe un comentario"
              paddingY="12px"
              className="mt-2"
              onEnter={e => handleEnter(e)}
              value={comentario}
              setValue={setComentario}
              onBlur={() => handleBlur()}
            />
          </>
        )}

        <div className={`mt-8`}>
          {detalleBitacora.length ? (
            <>
              {detalleBitacora.map((bitacora, index) => (
                <ItemBitacora
                  bitacora={bitacora}
                  key={bitacora._id}
                  colorSecondary={bitacora.etapa.colores.find(
                    color => color.tipo === 'secondary',
                  )}
                  index={index}
                  view={view}
                />
              ))}
              {detalleBitacora.length > 6 && (
                <div className="text-center" onClick={handleViewMore}>
                  <TextCustom
                    text={`${view ? 'Ver menos' : 'Ver más'}`}
                    className="cursor-pointer fontPSemiBold underline"
                    color="general"
                  />
                </div>
              )}
            </>
          ) : (
            <TextCustom
              text="No hay historial"
              color="general"
              className="fontPSemiBold"
            />
          )}
        </div>
      </div>
    );
  }
};

export default CardBitacora;
