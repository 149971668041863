// Components
import TextCustom from '../TextCustom';

const LabelTexto = ({ color = '', texto = '', className = '' }) => {
  return (
    <div className="space-x-2">
      <span
        className="w-3 h-3 rounded-full inline-block"
        style={{ backgroundColor: color.codigo }}
      ></span>
      <TextCustom text={texto} className={className} />
    </div>
  );
};

export default LabelTexto;
