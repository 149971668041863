import { useCallback, useState } from 'react';
import XLSX from 'xlsx';
import { ButtonCustom } from '../../atoms';

import { ReactComponent as IconoExportar } from '../../../assets/icons/Icono-Exportar.svg';
import { DialogExportarOportunidades } from '../../organisms';
import { apiGenerarExcel } from '../../../services/apis/oportunidades/apiOportunidades';
import { formatearFecha } from '../../../core/utils/dates';

const mappedResults = (data) => {

    return data.map( op => {
        return {
            "Tipo": op.categoria.nombre,
            "Contacto": `${op.contacto ?.primer_nombre !== ' ' ? op.contacto ?.primer_nombre : '' }${op.contacto?.segundo_nombre !== '' ? op.contacto?.segundo_nombre: ''} ${op.contacto?.primer_apellido} ${op.contacto?.segundo_apellido}`.trim(),
            "Fecha creada": formatearFecha(op.createdAt),
            "Aliado": op.aliado.nombre,
            "Valor": Number.parseFloat(op.valor_cotizacion).toFixed(2),
            "Estado": op.etapa.nombre,
            "Moneda": op.cotizacionSeleccionada?.moneda.simbolo,
            "Fecha Cierre Ganada": formatearFecha(op.fechaCierreGanada) !== "Invalid Date" ? formatearFecha(op.fechaCierreGanada) : "" 
          }
    })

} 
export const ButtonExportExcel = ({data, module, width = "9rem", height = "3rem", texto = ""}) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false)

    const exportFileXLSX = useCallback((results = null, module = null) => {
       
        const ws = results ? XLSX.utils.json_to_sheet(results) : XLSX.utils.json_to_sheet(data.data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
       
        let maxLengths = {}

        let iterateData = module === "Oportunidades" ? results : data.data
        iterateData.forEach(row => {
            Object.keys(row).forEach(key => {
                const length = row[key]?.toString().length || 0;
                if (!maxLengths[key] || maxLengths[key] < length) {
                    maxLengths[key] = length;
                }
            });
        });
        
        const maxWidthArray = Object.keys(maxLengths).map(key => ({ wch: maxLengths[key] }));
        ws["!cols"] = [...maxWidthArray]
        XLSX.writeFile(wb, `${data.fileName}.xlsx`, { compression: true });
    }, [data]);
    
    const exportFile = (module) => {
        switch (module) {
            case "oportunidades" : {
                setShowModal(true)  
                break;
            }
            case "contactos" : {
                exportFileXLSX();
                break;
            }
        }
    }

    const handleSubmit = async (params) => {   
        setLoading(true)
        let { data: dataResult } = await apiGenerarExcel(params)
        
        exportFileXLSX(mappedResults(dataResult.data), "Oportunidades");
        setShowModal(false)
        setLoading(false)
    }

    return (
        <>
            
        <ButtonCustom
          text={texto}
          typeColor="general"
          width={width}
          height={height}
          startIcon={<IconoExportar />}
          onClick={() => exportFile(module)}
          disableTooltip = {false}
        />

            <DialogExportarOportunidades 
                isOpenModal={showModal}
                setIsOpenModal={setShowModal}
                actionSubmit={handleSubmit}
                isLoading={loading}
            />
        </>
    )
}