export const dataPerdida = (dataEtapa = '', dataEtapas = '') => {
  let newData = dataEtapas.map(etapa => {
    let newEtapa = { ...etapa };
    newEtapa.lock = true;

    if (newEtapa.orden === dataEtapa.orden) {
      newEtapa.disabledTab = false;
      newEtapa.disabledSelect = false;
    } else {
      newEtapa.disabledTab = true;
      newEtapa.disabledSelect = true;
    }

    if (!etapa.values) {
      newEtapa.values = {};
    }
    return newEtapa;
  });

  return newData.sort((a, b) => a.orden - b.orden);
};

export const dataDormida = (dataEtapa = '', dataEtapas = '') => {
  let newData = dataEtapas.map(etapa => {
    let newEtapa = { ...etapa };
    newEtapa.lock = true;
    newEtapa.checked = false;

    if (newEtapa.orden === dataEtapa.orden) {
      newEtapa.lock = false;
    }
    if (newEtapa.checked || newEtapa.type) {
      newEtapa.disabledTab = false;
      newEtapa.disabledSelect = false;
    } else {
      newEtapa.disabledTab = true;
      newEtapa.disabledSelect = true;
    }

    if (
      dataEtapa.codigo === 'GAN' &&
      (newEtapa.codigo === 'PER' || newEtapa.codigo === 'DOR')
    ) {
      newEtapa.mostrarTab = false;
    }

    if (dataEtapa.codigo === 'DOR' && newEtapa.codigo === 'COT') {
      newEtapa.disabledTab = false;
      newEtapa.disabledSelect = false;
    }

    if (!etapa.values) {
      newEtapa.values = {};
    }
    return newEtapa;
  });

  return newData.sort((a, b) => a.orden - b.orden);
};

export const dataInicial = (dataEtapa = '', dataEtapas = '', etapaActual) => {
  let newData = dataEtapas.map(etapa => {
    let disabledSelect = true;
    let disabledTab = true;
    let lock = true;
    let checked = false;

    if (
      etapa.idEtapa === etapaActual._id ||
      etapa.orden < etapaActual.orden ||
      etapa.type
    ) {
      disabledSelect = false;
      disabledTab = false;
    }

    if (etapa.orden < etapaActual.orden) {
      checked = true;
    }

    if (etapa.idEtapa === etapaActual._id) lock = false;
    if (etapa.orden === etapaActual.orden + 1) {
      disabledSelect = false;
    }
    if (dataEtapa.codigo === 'GAN' && etapa.codigo === 'GAN')
      etapa.mostrarTab = true;
    if (
      dataEtapa.codigo === 'GAN' &&
      (etapa.codigo === 'PER' || etapa.codigo === 'DOR')
    )
      etapa.mostrarTab = false;

    let newEtapa = { ...etapa };
    newEtapa.disabledSelect = disabledSelect;
    newEtapa.disabledTab = disabledTab;
    newEtapa.lock = lock;
    newEtapa.checked = checked;
    if (!etapa.values) {
      newEtapa.values = {};
    }
    return newEtapa;
  });

  return newData.sort((a, b) => a.orden - b.orden);
};

export const dataActualizar = (dataEtapa = '', dataEtapas = '', pago) => {
  let newData = dataEtapas.map(etapa => {
    let newDataEtapa = etapa;
    etapa.lock = true;
    etapa.checked = false;

    if (dataEtapa.codigo === 'PER' || dataEtapa.codigo === 'DOR') {
      etapa.disabledTab = false;
      etapa.disabledSelect = false;
    } else {
      if (etapa.orden === dataEtapa.orden + 1) {
        etapa.disabledSelect = false;
        etapa.disabledTab = true;
      } else {
        if (etapa.orden < dataEtapa.orden || etapa.orden === dataEtapa.orden) {
          etapa.disabledTab = false;
          etapa.disabledSelect = false;
          etapa.checked = true;
        } else if (!etapa.type) {
          etapa.disabledTab = true;
          etapa.disabledSelect = true;
        }
      }
    }

    if (etapa.orden === dataEtapa.orden) {
      etapa.lock = false;
    }

    if (dataEtapa.codigo === 'GAN' && etapa.codigo === 'GAN')
      etapa.mostrarTab = true;
    if (
      dataEtapa.codigo === 'GAN' &&
      (etapa.codigo === 'PER' || etapa.codigo === 'DOR')
    )
      etapa.mostrarTab = false;

    if (etapa.codigo === 'PAG') {
      etapa.values = { ...etapa.values, ...pago };
    }

    return newDataEtapa;
  });

  return newData.sort((a, b) => a.orden - b.orden);
};
