import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import {
  DatePickerDashboard,
  Loader,
  NoDataGrafico,
  TextCustom,
} from '../../atoms';

import { ReactComponent as IconoAgenda } from '../../../assets/icons/Icono-Agenda.svg';
import { apiVentasPorMes } from '../../../services/apis/dashboard/apiDashboard';
import { useOportunidad } from '../../../hooks/others';

const ChartVentasPorMes = () => {
  const params = useParams();
  const { divisaSeleccionada, valorDivisa } = useOportunidad();

  const divisaRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [cargando, setCargado] = useState(false);
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'bar',
    },
    colors: ['#003763'],
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        style: {
          color: '#AAA',
          fontSize: '12px',
          fontFamily: 'Poppins-Regular',
          fontWeight: 400,
        },
        formatter: function (value) {
          if (value >= 1e6) {
            return (
              (value / 1e6).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }) + 'M'
            );
          } else if (value >= 1e3) {
            return (
              (value / 1e3).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }) + 'k'
            );
          } else {
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          }
        },
      },
    },
    xaxis: {
      categories: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      labels: {
        style: {
          color: '#003763',
          fontSize: '12px',
          fontFamily: 'Poppins-Regular',
          fontWeight: 'bold',
        },
      },
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: 'Poppins-Regular',
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return '';
          },
        },
        formatter: function (val, { dataPointIndex }) {
          const labels = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ];
          const label = labels[dataPointIndex];

          return `${label}:   ${new Intl.NumberFormat(
            divisaRef.current === 'USD' ? 'en-US' : 'es-CR',
            {
              style: 'currency',
              currency: divisaRef.current === 'USD' ? 'USD' : 'CRC',
            },
          ).format(val)}`;
        },
      },
    },
  });
  const [isValorCotizacionLleno, setisValorCotizacionLleno] = useState(false);

  useEffect(() => {
    setCargado(true);
    if (valorDivisa !== 0) {
      obtenerDataGrafico();
    }
  }, [selectedDate, params, valorDivisa]);

  useEffect(() => {
    divisaRef.current = divisaSeleccionada;

    const dataAConvertir = series[0].data;
    const currency = valorDivisa;
    //convierto los datos a dolares
    if (divisaSeleccionada === 'USD') {
      const dataConvert = dataAConvertir.map(dat =>
        (+dat / currency).toFixed(2),
      );
      setSeries([{ data: dataConvert }]);
    } else {
      //convierto los datos a colones
      const dataConvert = dataAConvertir.map(dat =>
        (+dat * currency).toFixed(2),
      );

      setSeries([{ data: dataConvert }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisaSeleccionada]);

  const obtenerDataGrafico = async () => {
    const currency = valorDivisa;

    setSeries([]);

    const param = {
      filtroYear: selectedDate ? dayjs(selectedDate).format('YYYY') : null,
      aliadoId: params.id ? params.id : null,
    };

    const { success, data } = await apiVentasPorMes(param);

    if (success) {
      let dataResumen = data;
      dataResumen.map(dat => {
        let newData = dat;
        newData.valorCotizacion =
          dat.totalValorCotizacion.USD * currency +
          dat.totalValorCotizacion.CRC;
      });

      const ventasMesValores = dataResumen.map(op =>
        op.valorCotizacion.toFixed(2),
      );

      if (param.filtroYear) {
        if (divisaSeleccionada === 'USD') {
          //dolares
          const dataConvert = ventasMesValores.map(dat =>
            (+dat / currency).toFixed(2),
          );

          setSeries([{ data: dataConvert }]);
        } else {
          setSeries([{ data: ventasMesValores }]);
        }
      } else {
        setSeries([{ data: ventasMesValores }]);
      }

      //compranod que haya al menos un valores en la data del grafico
      const valorCotizacion = data.reduce(
        (acc, op) => acc + op.valorCotizacion,
        0,
      );

      valorCotizacion
        ? setisValorCotizacionLleno(true)
        : setisValorCotizacionLleno(false);
    }

    setCargado(false);
  };

  const handleShowDatePicker = () => {
    setOpen(true);
  };

  const handleMinDate = () => {
    const currentDate = new Date();
    return currentDate.setFullYear(currentDate.getFullYear() - 5);
  };

  return (
    <div className="h-full">
      <div className="flex justify-between items-center">
        <TextCustom text="Ventas por mes" className="fontPSemiBold" />

        <div
          className="bg-[#E5EBEF] rounded-[10px] flex items-center py-0.5 px-4 gap-3 cursor-pointer relative"
          onClick={handleShowDatePicker}
        >
          <IconoAgenda fill="#003763" className="w-[13px] h-[13px]" />
          <TextCustom
            text={` ${
              selectedDate
                ? dayjs(selectedDate).format('YYYY')
                : new Date().getFullYear()
            }`}
            className="text-[#003763] text-xs"
          />
          <DatePickerDashboard
            open={open}
            setOpen={setOpen}
            className="invisible absolute"
            setValue={setSelectedDate}
            value={selectedDate}
            viewsPicker={['year']}
            minDate={handleMinDate()}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div className="h-full">
        {cargando ? (
          <Loader type="full" />
        ) : isValorCotizacionLleno ? (
          <Chart options={options} series={series} type="bar" height={320} />
        ) : (
          <NoDataGrafico tituloGrafico="Ventas por mes" />
        )}
      </div>
    </div>
  );
};

export default ChartVentasPorMes;
