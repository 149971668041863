export const endpoints = {
  login: 'usuarios/login',
  getCategorias: 'listarCategorias',
  usuarios: 'administrativo/usuarios',
  usuariosCambioEstado: 'administrativo/usuarios/estado',
  resetPassword: 'administrativo/usuarios/reset-password',
  permisos: 'administrativo/usuarios/permisos',
  aseguradora: 'aseguradoras',
  datos: 'aseguradoras/data',
  seguros: 'aseguradoras/perfil',
  postSeguros: 'aseguradoras/nuevo-tipo-seguro',
  planes: 'aseguradoras/perfil-seguro',
  postPlan: 'aseguradoras/crearPlan',
  getCotizaciones: 'cotizaciones',
  getCotizacionesAliado: 'cotizaciones/aliados',
  aliados: 'aliados',
  usuariosAliado: 'aliados/usuarios',
  aliados_agregar_usuario: 'aliados/agregar-usuario',
  aliados_quitar_usuario: 'aliados/eliminar-usuario',
  contactos: 'contactos',
  getOportunidades: 'oportunidades',
  getOportunidadCotizacion: 'oportunidades/cotizacion',
  getOportunidadAliado: 'oportunidades/aliado',
  getOportunidadContacto: 'oportunidades/contacto',
  getEtapas: 'etapas',
  bitacora: 'bitacora',
  roles: 'roles',
  perfil: 'administrativo/perfil',
  dashboardOportunidadEtapas: 'administrativo/grafica/oportunidad-etapa',
  dashboardVentasMes: 'administrativo/grafica/ventas-mes',
  dashboardCanalizacion: 'administrativo/grafica/canalizacion',
  documentos: 'documentos',
  divisas: 'divisas',
  generarExcel: 'oportunidades/generar-excel-by-filtros',
  getOportunidadesPagination : 'oportunidades/oportunidades-pagination',
  getOportunidadesEtapas : "oportunidades/conteo-oportunidades",
  blacklist: 'lista-negra/subir-archivo',
  urlBlacklist: 'lista-negra/obtener-archivo',
  correoVerificacion : 'correo-verificacion',
  editRolUser: 'administrativo/usuarios/roles'
};
