//react
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { AlertCustom, Loader } from '../../atoms';
import { TituloPage } from '../../molecules';
import { DialogUsuarios } from '../../organisms';
import { TableCustom } from '../../templates';
//assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

//types
import { columnsTableUsuarios } from '../../../common/tables';
import { typesTableActions } from '../../../common/types';

//api
import { apiCrearUsuario, apiListarUsuarios } from '../../../services';

// Hooks
import { useForm } from '../../../hooks/others';

// Funciones
import { buscarPermiso, renderInicialesNombre } from '../../../core/utils';

const { tableView } = typesTableActions;

const Usuarios = () => {
  //navigate
  const navigate = useNavigate();

  const permisos = JSON.parse(localStorage.getItem('listPermisos'));

  const [usuarios, setUsuarios] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [showAgregar, setShowAgregar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [permiso, setPermiso] = useState(false);

  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  useEffect(() => {
    setPermiso(buscarPermiso(permisos, 'usuarios', 'post'));
  }, [permisos]);

  const formateoDataUsuarios = usuario => {
    return {
      id: usuario?._id,
      filtro: usuario?.nombre,
      nombre: renderInicialesNombre(usuario?.nombre),
      correo: usuario?.correo,
      rol: usuario?.rol?.nombre ?? '-',
      estado: usuario?.estado ? 'ACTIVO' : 'INACTIVO',
    };
  };

  // OBTENER LOS USUARIOS
  useEffect(() => {
    obtenerUsuarios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerUsuarios = async () => {
    const { data, success, statusCode } = await apiListarUsuarios();

    if (success) {
      const usuariosFormateados = data.map(usuario =>
        formateoDataUsuarios(usuario),
      );
      setUsuarios(usuariosFormateados);
      setCargando(false);
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }
      navigate('/page-error-500', { state: { statusCode: statusCode } });
    }
  };

  const handleSubmit = async params => {
    const respuesta = await apiCrearUsuario(params);
    const { success, message, statusCode, error } = respuesta;

    if (success) {
      setShowAgregar(false);
      obtenerUsuarios();
      setAlert({
        title: '¡Usuario creado!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: error,
        description: message,
        open: true,
        severity: 'error',
      });
    }
  };

  const handleAgregar = () => {
    setShowAgregar(true);
  };

  const handleAction = (action, id, obj) => {
    switch (action) {
      case 'tableView':
        navigate(`/dashboard/configuration/usuarios/${obj.id}`);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {cargando && <Loader />}
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />

      <div className="flex justify-between items-center mb-10">
        <div className="flex gap-5 items-center">
          <TituloPage titulo="Usuarios" />
          {permiso && (
            <div onClick={() => handleAgregar()}>
              <AddCircleIcon className="color-general text-5xl hover:cursor-pointer " />
            </div>
          )}
        </div>
      </div>
      <TableCustom
        data={usuarios}
        columns={columnsTableUsuarios}
        identifierHidden="filtro"
        identifierAction={'filtro'}
        actions={[tableView]}
        actionClick={(action, id, obj) => handleAction(action, id, obj)}
        filterHeader={false}
        isSearch={true}
      />

      {/* ventanas modales */}
      <DialogUsuarios
        isOpenModal={showAgregar}
        setIsOpenModal={setShowAgregar}
        showAlert={showModal}
        setShowAlert={setShowModal}
        actionSubmit={handleSubmit}
      />
    </div>
  );
};

export default Usuarios;
