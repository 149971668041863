import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Components
import {
  Contactos,
  Configuration,
  Home,
  Oportunidades,
  Cotizaciones,
  Aliados,
  Usuarios,
  Perfil,
  VistaUsuario,
  PerfilCotizacion,
  PerfilAliado,
  PerfilContacto,
  PerfilOportunidades,
  Error401,
  ListaControl,
} from '../../pages';
import { DashboardLayout } from '../../templates';

// Context
import { OportunidadProvider } from '../../../hooks/context';

export const DashboardRouter = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <DashboardLayout>
      <OportunidadProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/configuration/usuarios" element={<Usuarios />} />
          <Route path="/configuration/blacklist" element={<ListaControl />} />
          <Route
            path="/configuration/usuarios/:id"
            element={<VistaUsuario />}
          />
   
          <Route path="/contactos" element={<Contactos />} />
          <Route path="/contactos/:id" element={<PerfilContacto />} />
          <Route path="/oportunidades" element={<Oportunidades />} />
          <Route path="/oportunidades/:id" element={<PerfilOportunidades />} />
          <Route path="/cotizaciones" element={<Cotizaciones />} />
          <Route path="/cotizaciones/:id" element={<PerfilCotizacion />} />
          <Route path="/aliados" element={<Aliados />} />
          <Route path="/aliados/:id" element={<PerfilAliado />} />
          <Route path="/page-error-401" element={<Error401 />} />
          <Route path="*" element={<Navigate to="/page-error-404" replace />} />
        </Routes>
      </OportunidadProvider>
    </DashboardLayout>
  );
};

export default DashboardRouter;
