import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { AlertCustom, LabelTexto, TextCustom, TituloCard } from '../../atoms';
import { TituloPage } from '../../molecules';
import { DialogCambiarPassword, DialogUsuarios } from '../../organisms';

// Const
import { typesActionsAuth } from '../../../common/types';

// Utils
import { buscarPermiso, renderInicialNombreAliado } from '../../../core/utils';

// Assets
import { ReactComponent as EditarIcon } from '../../../assets/icons/Icono-Editar.svg';

// Style
import './style.css';

// Context
import { AuthContext } from '../../../hooks/context';
import { useForm } from '../../../hooks/others';

// Services
import { apiEditPerfil, apiResetPasswordUsuario } from '../../../services';
import { stSetPersonalInfo } from '../../../services/storage';

const { authUpdate } = typesActionsAuth;
const Perfil = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { auth, dispatchAuth } = useContext(AuthContext);
  const { personalInfo } = auth;

  const [permisoPut, setPermisoPut] = useState(false);

  const [showAgregar, setShowAgregar] = useState(false);
  const [showEditar, setShowEditar] = useState(false);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  const permisos = JSON.parse(localStorage.getItem('listPermisos'));

  useEffect(() => {
    if (location.state?.openModal) {
      setShowAgregar(true);
    }
  }, [location]);

  useEffect(() => {
    setPermisoPut(buscarPermiso(permisos, 'perfil', 'patch'));
  }, [permisos]);

  const handleShowModal = () => {
    setShowAgregar(true);
  };

  const handleShowModalEditar = () => {
    setShowEditar(true);
  };

  const handleSubmitChangePassword = async (passwords, setActionLoading) => {
    const request = {
      id_usuario: personalInfo.id,
      password: passwords.passwordNew,
      passwordAnterior: passwords.passwordOld,
    };

    const { success, message, statusCode } = await apiResetPasswordUsuario(
      request,
    );
    if (success) {
      setShowAgregar(false);

      setAlert({
        title: '¡Contraseña actualizada!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'warning',
      });
    }

    setActionLoading(false);
  };

  const handleSubmitEditPerfil = async (data, setActionLoading) => {
    const request = {
      nombre: data.nombre,
      correo: data.correo,
    };

    const { success, message, statusCode } = await apiEditPerfil(request);

    if (success) {
      let payload = personalInfo;
      payload.nombre = data.nombre;
      payload.correo = data.correo;
      dispatchAuth({ type: authUpdate, payload });
      stSetPersonalInfo(payload);
      setShowEditar(false);

      setAlert({
        title: '¡Perfil editado!',
        description: message,
        open: true,
        severity: 'success',
      });
    } else {
      if (statusCode === 403) {
        navigate('/page-error-401');
        return;
      }

      setAlert({
        title: '¡Ha ocurrido un error!',
        description: message,
        open: true,
        severity: 'warning',
      });
    }
    setActionLoading(false);
  };

  return (
    <div>
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />
      <TituloPage titulo="Perfil" />
      <div className="grid grid-cols-4 2xl:grid-cols-3 my-10 gap-4">
        <div className="col-span-3 2xl:col-span-2">
          <div className="grid-card-perfil bg-card">
            <div className="relative flex justify-center items-center">
              {renderInicialNombreAliado(personalInfo.nombre)}
              {/* {permisoPut && (
                <EditarIcon
                  className="cursor-pointer absolute w-[30px] h-[30px] btn-editar"
                  onClick={() => window.alert('editar foto perfil...')}
                />
              )} */}
            </div>
            <div className="px-3 relative">
              <TituloCard
                titulo="Datos Personales"
                editar={permisoPut ? false : true}
              />
              <div className="px-3 rounded-lg cursor-pointer absolute top-0 right-5">
                <EditarIcon
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={handleShowModalEditar}
                />
              </div>
              <div className="grid grid-cols-4 2xl:grid-cols-3 items-center content-between gap-x-2 gap-y-7 mt-6">
                <div>
                  <TextCustom text="Nombre" className="text-sm" />
                  <br />
                  <TextCustom
                    text={personalInfo.nombre}
                    component="p"
                    variant="p"
                    className="fontPSemiBold"
                  />
                </div>
                <div className="col-span-2 2xl:col-auto ">
                  <TextCustom text="Correo" className="text-sm " />
                  <br />
                  <TextCustom
                    text={personalInfo.correo}
                    component="p"
                    variant="p"
                    className="fontPSemiBold text-max max-w-full"
                  />
                </div>
                <div>
                  <TextCustom text="Rol" className="text-sm" />
                  <br />
                  <TextCustom
                    text={`${personalInfo.rol ? personalInfo.rol : 'N/A'}`}
                    component="p"
                    variant="p"
                    className="fontPSemiBold"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 bg-card">
          <TituloCard titulo="Estado" />
          <div className="p-5 flex items-center h-5/6">
            <LabelTexto
              color={{
                codigo: `${personalInfo?.estado ? '#76BC21' : '#EB2341'}`,
              }}
              texto={`${personalInfo?.estado ? 'Activo' : 'Inactivo'}`}
              className="fontPSemiBold text-base"
              key={'Activo'}
            />
          </div>
        </div>
      </div>

      <div className="bg-card px-5 py-7">
        <div className="flex justify-between">
          <TextCustom
            text="Contraseña"
            color="general"
            className="fontPSemiBold"
          />
          <div className="bg-primary px-3 rounded-lg cursor-pointer flex items-center">
            <span
              className="color-white fontPSemiBold text-xs"
              onClick={handleShowModal}
            >
              Cambiar Contraseña
            </span>
          </div>
        </div>
      </div>
      <DialogCambiarPassword
        isOpenModal={showAgregar}
        setIsOpenModal={setShowAgregar}
        actionSubmit={handleSubmitChangePassword}
      />
      <DialogUsuarios
        isOpenModal={showEditar}
        setIsOpenModal={setShowEditar}
        actionSubmit={handleSubmitEditPerfil}
        usuario={personalInfo}
      />
    </div>
  );
};

export default Perfil;
