// Components
import { Link } from 'react-router-dom';

import { TituloCard } from '../../atoms';
import { useOportunidad } from '../../../hooks/others';

const CardAliadoTab = () => {
  const { aliado } = useOportunidad();

  return (
    <div className="card-tabs">
      <TituloCard titulo="Aliado" />
      <div className="pt-4">
        <Link
          to={`/dashboard/aliados/${aliado?.id}`}
          className="fontPBold underline cursor-pointer"
        >
          {aliado?.nombre ? aliado?.nombre : '-'}
        </Link>
      </div>
    </div>
  );
};

export default CardAliadoTab;
