// Components
import { DialogTitle } from '@mui/material';
import { IconButtonCustom } from '../../atoms';

// Assets
import { ReactComponent as CerrarIcon } from '../../../assets/icons/Icono-Cerrar.svg';

// Utils
import { renderColor } from '../../../core/utils';

const DialogTitleCustom = ({
  disabledIconClose = false,
  children = null,
  onClose = () => null,
  colorHexa = '',
}) => {
  return (
    <DialogTitle
      className=" m-0 text-3xl"
      style={{
        color: renderColor('white'),
        backgroundColor: colorHexa ? colorHexa : renderColor('general'),
        padding: '25px 40px',
      }}
    >
      <div className="flex justify-between items-center">
        {children}
        {!disabledIconClose && (
          <IconButtonCustom
            icon={<CerrarIcon className="w-[38px] h-[38px]" />}
            onClick={onClose}
            typeColor="primary"
            size={25}
            padding="0"
          />
        )}
      </div>
    </DialogTitle>
  );
};

export default DialogTitleCustom;
