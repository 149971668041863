import { useQuery } from "@tanstack/react-query";
import { apiConteoOportunidadesEtapas } from "../../../services";

const useQueryContarEtapasOportunidades = ()=>{
    const queryContarEtapasOportunidades = useQuery({
        refetchOnWindowFocus: false,
        queryKey : ["etapasOportunidades"],
        queryFn: async () =>{
            const conteo = await apiConteoOportunidadesEtapas();
        return {
            conteo
        }
        }
    });
    return {queryContarEtapasOportunidades}
}

export default useQueryContarEtapasOportunidades;