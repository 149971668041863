import { useEffect, useReducer } from 'react';

// Hooks
import { AuthContext } from './hooks/context';
import { authReducer } from './hooks/reducer';

//LocalStorage
import {
  stGetToken,
  stGetPersonalInfo,
  stGetListPermisos,
} from './services/storage';

// Components
import { AppRouter } from './components/routes';

import { typesActionsAuth } from './common/types';

const App = () => {
  const [auth, dispatchAuth] = useReducer(authReducer, {});

  const { authLogin } = typesActionsAuth;

  useEffect(() => {
    const { data: token } = stGetToken();
    const { data: personalInfo } = stGetPersonalInfo();
    const { data: permisos } = stGetListPermisos();

    if (token) {
      const payload = {
        personalInfo: personalInfo,
        token,
        permisos,
      };
      dispatchAuth({ type: authLogin, payload });
    }
  }, [authLogin]);

  return (
    <AuthContext.Provider value={{ auth, dispatchAuth }}>
      <AppRouter isAuth={auth?.isLogin} />
    </AuthContext.Provider>
  );
};

export default App;
