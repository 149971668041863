import { useEffect, useState } from 'react';

// Components
import { ButtonCustom, TextCustom, TextInputCustom } from '../../atoms';
import { DialogCustom } from '../../templates';
import { DialogActions, DialogContent } from '@mui/material';

// Hooks
import { useForm } from '../../../hooks/others';

//validaciones
import { typesValidation } from '../../../common/types';
import { validFormAliado, validInputEmail } from '../../../core/validations';
import { generarCodigoAliado } from '../../../core/utils';

const DialogAgregarAliado = ({
  isOpenModal = false,
  setIsOpenModal = () => null,
  aliado = null,
  actionSubmit = () => null,
}) => {
  const [id, setId] = useState(null);
  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [correo, setCorreo] = useState('');
  const [telefono, setTelefono] = useState('');
  const [codigoAliado, setCodigoAliado] = useState('');

  //validaciones
  const [formErrors, setFormErrors, reset] = useForm({
    nombre: '',
    cedula: '',
    correo: '',
    telefono: '',
    codigoAliado: '',
  });
  const [formSuccess, setFormSuccess] = useForm({
    nombre: false,
    cedula: false,
    correo: false,
    telefono: false,
    codigoAliado: false,
  });

  useEffect(() => {
    if (isOpenModal) {
      if (aliado) {
        setId(aliado?._id);
        setCedula(aliado?.identificacion);
        setNombre(aliado?.nombre);
        setTelefono(aliado?.telefono);
        setCorreo(aliado?.correo);
        setCodigoAliado(aliado?.codigo);
      } else {
        resetValues();
        setCodigoAliado(generarCodigoAliado());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleSubmit = () => {
    if (handleValidForm()) {
      let params;

      if (id) {
        //* mandar la informacion al endpoint
        params = {
          id,
          nombre,
          correo,
          telefono,
          identificacion: cedula,
          codigoAliado,
        };
      } else {
        //* mandar la informacion al endpoint
        params = {
          nombre,
          correo,
          cedula,
          telefono,
          codigoAliado,
        };
      }
      actionSubmit(params);
    }
  };

  //* funcion de validacion del formulario
  const handleValidForm = () => {
    const params = { nombre, cedula, correo, telefono, codigoAliado };
    const responseValid = validFormAliado(params);
    const { isValid, msgValid } = responseValid;
    setFormErrors(msgValid.errors);
    setFormSuccess(msgValid.success);
    return isValid;
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const resetValues = () => {
    reset();
    setId(null);
    setNombre('');
    setCedula('');
    setCorreo('');
    setTelefono('');
    setCodigoAliado('');
  };

  const formatData = (e, value) => {
    const valorInput = e.target.value;

    if (formErrors[value] && valorInput) {
      if (
        value === 'correo' &&
        !validInputEmail(valorInput, typesValidation.validateEmail)
      ) {
        setFormErrors({
          ...formErrors,
          [value]: 'Formato de correo electrónico no válido.',
        });
      } else {
        setFormErrors({ ...formErrors, [value]: '' });
      }
    }
  };

  const formatTel = e => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, '');

    const value = filteredValue;
    const regex = /^(\d{0,4})(\d{0,4})$/;
    const formattedValue = value.replace(regex, function (match, p1, p2) {
      if (p2.length > 0) {
        return `${p1}-${p2}`;
      } else {
        return p1;
      }
    });

    e.target.value = formattedValue;
    setTelefono(e.target.value);
  };

  return (
    <DialogCustom
      open={isOpenModal}
      setOpen={handleCloseModal}
      title={`${id ? 'Editar Aliado' : 'Agregar Aliado'} `}
      disabledDismiss
    >
      <DialogContent style={{ width: 900 }}>
        <div className="md:grid md:grid-cols-2 gap-x-10 mt-10 px-7">
          <div className="mt-5 mb-5">
            <TextCustom text="Nombre" required />
            <TextInputCustom
              placeholder="Nombre"
              borderStyle={true}
              value={nombre}
              setValue={setNombre}
              className="mb-2"
              typesValidation={typesValidation.lettersExtend}
              msgError={formErrors.nombre}
              success={formSuccess.nombre}
              type="text"
              onKeyUp={event => formatData(event, 'nombre')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="N. de Cédula" required />
            <TextInputCustom
              placeholder="Escribe el número de cédula"
              borderStyle={true}
              value={cedula}
              setValue={setCedula}
              className="mb-2"
              msgError={formErrors.cedula}
              success={formSuccess.cedula}
              type="text"
              maxLength={9}
              onKeyUp={event => formatData(event, 'cedula')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Correo Electrónico" required />
            <TextInputCustom
              placeholder="Escribe correo electrónico"
              borderStyle={true}
              value={correo}
              setValue={setCorreo}
              className="mb-2"
              msgError={formErrors.correo}
              success={formSuccess.correo}
              type="email"
              onKeyUp={event => formatData(event, 'correo')}
            />
          </div>
          <div className="mt-5 mb-5">
            <TextCustom text="Teléfono" />
            <TextInputCustom
              placeholder="Escribe número de teléfono"
              borderStyle={true}
              value={telefono}
              setValue={setTelefono}
              className="mb-2"
              msgError={formErrors.telefono}
              success={formSuccess.telefono}
              onKeyUp={event => formatTel(event, this)}
              maxLength={9}
            />
          </div>
          {!id && (
            <div className="mt-5 mb-5">
              <TextCustom text="Código de Aliado" />
              <TextInputCustom
                placeholder="Escribe código de aliado"
                borderStyle={true}
                value={codigoAliado}
                setValue={setCodigoAliado}
                className="mb-2"
                msgError={formErrors.codigoAliado}
                success={formSuccess.codigoAliado}
                maxLength={12}
                typesValidation={typesValidation.onlyAlphanumericExtend}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="mx-auto mt-10">
          <ButtonCustom
            text={`${id ? 'Guardar cambios' : 'Crear'} `}
            typeColor="general"
            width={`${id ? '200px' : '150px'} `}
            onClick={handleSubmit}
          />
        </div>
      </DialogActions>
    </DialogCustom>
  );
};

export default DialogAgregarAliado;
