import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import { AlertCustom, Loader, SelectEtapas } from '../../atoms';
import { TituloPerfil } from '../../molecules';
import { DialogCambiarEtapa, DialogMotivoEtapa } from '../../organisms';
import { TabsEtapas } from '../../templates';

// Hooks
import { useForm, useOportunidad } from '../../../hooks/others';

//API
import {
  getApiPerfilOportunidad,
  putApiOportunidad,
  enviarCorreoVerificacion,
} from '../../../services';

//utils
import {
  dataActualizar,
  dataDormida,
  dataInicial,
  dataPerdida,
  formatearTelefono,
} from '../../../core/utils';

import { ModalPermiso } from '../../organisms';

// Validaciones
import { formValidDatosPersonales } from '../../../core/validations/formValidDatosPersonales';

// Assets
import { ReactComponent as Unlock } from '../../../assets/icons/Icono-Unlock.svg';
import { ReactComponent as Lock } from '../../../assets/icons/Icono-Lock.svg';
import { ReactComponent as Ganada } from '../../../assets/icons/Estados/AceptarIcon.svg';
import { ReactComponent as ExternoIcon } from '../../../assets/icons/Icono-Externo.svg';
import { mensajesBitacora } from '../../../common/constants';

const PerfilOportunidades = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    setEtapa,
    etapa,
    dataContacto,
    setDataContacto,
    setDataEtapas,
    dataEtapas,
    setAliado,
    plan,
    etapaActual,
    setEtapaActual,
    etapaActualizada,
    setEtapaActualizada,
    setCategoria,
    pago,
    setValorCotizacion,
    setAseguradoraSeleccionada,
    setCotizacionSeleccionada,
    setDetalleCotizacion,
    setNumPoliza,
    setNumConsecutivo,
    setNumConfirmacion,
    categoria,
    setLinkCotizacion,
    cotizacionSeleccionada,
    setCotizaciones,
  } = useOportunidad();
  const [selectEtapa, setSelectEtapa] = useState('');
  const [selectedTab, setSelectedTab] = useState(1);
  const [paso, setPaso] = useState(1);
  const [cargando, setCargando] = useState(true);
  const [candado, setCandado] = useState(true);
  const [showCambiarEtapa, setShowCambiarEtapa] = useState(false);
  const [showMotivo, setShowMotivo] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [etapaModal, setEtapaModal] = useState('');
  const [modalPermiso, setModalPermiso] = useState(false);
  const [alert, setAlert, alertReset] = useForm({
    title: '',
    description: '',
    open: false,
    severity: 'info',
  });

  const handleBackToList = () => {
    setModalPermiso(false);
    navigate('/dashboard/cotizaciones');
  };

  useEffect(() => {
    const obtenerPerfilOportunidad = async () => {
      const { success, data, statusCode } = await getApiPerfilOportunidad(
        params.id,
      );
      if (success) {
        if (data.length === 0) {
          setModalPermiso(true);
        } else {
          let dataEtapa;
          // TODO: Existe la posibilidad de que la data de la etapa venga null del endpoint, por eso causa el error de que no se lee el campo IdEtapa
          let filteredEtapas = data.dataEtapas.filter(etapa => etapa !== null);
          let etapaA = filteredEtapas.find(
            etapa => etapa?.idEtapa === data.etapa._id,
          );

          if (etapaA) {
            switch (etapaA.codigo) {
              case 'DOR':
                dataEtapa = dataDormida(etapaA, data.dataEtapas);
                break;
              case 'PER':
                dataEtapa = dataPerdida(etapaA, data.dataEtapas);
                break;
              default:
                dataEtapa = dataInicial(etapaA, data.dataEtapas, data.etapa);
                break;
            }

            let etapaPaso = dataEtapa.find(
              etapa => etapa?.idEtapa === data.etapa._id,
            );

            const { label, orden, lock, codigo } = etapaPaso;

            let detalle = '';
            if (dataEtapa.length !== 0) {
              dataEtapa.map(input => {
                if (input?.detalle?.data) {
                  detalle = input.detalle.data;
                  return input.detalle.data;
                }
              });
            }

            if (data?.poliza) {
              setNumPoliza(data.poliza);
            }

            if (data?.pago) {
              setNumConfirmacion(data.pago.numeroAutorizacion);
              setNumConsecutivo(data.pago.consecutivoPago);
            }

            let valorCot = data.valor_cotizacion;

            if (data.categoria.codigo === 'SEG-V') {
              valorCot = valorCot.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              });
            } else {
              valorCot = valorCot.toLocaleString('es-CR', {
                style: 'currency',
                currency: 'CRC',
              });
            }

            setLinkCotizacion(
              data.cotizacionSeleccionada.linkRecuperacionCotizacion,
            );
            setDetalleCotizacion(detalle);
            setCategoria({
              codigo: data?.categoria?.codigo,
              nombre: data?.categoria?.nombre,
            });
            setPaso(orden);
            setSelectedTab(codigo === 'DOR' ? orden - 2 : orden - 1);
            setCandado(lock);
            setEtapaActual(etapaPaso);
            setEtapaActualizada(etapaPaso);
            setDataEtapas(dataEtapa);
            setEtapa(label);
            setSelectEtapa(label);
            setValorCotizacion(valorCot);
            setAseguradoraSeleccionada(
              data?.planes !== undefined
                ? data?.planes !== null
                  ? data.planes[0]?.aseguradora?.descripcion
                  : ''
                : '',
            );
            setCotizacionSeleccionada(data.cotizacionSeleccionada);
            setCotizaciones(data.cotizaciones);

            setDataContacto({
              nombre: `${data?.contacto?.primer_nombre} ${data?.contacto?.primer_apellido}`,
              correo: data?.contacto?.correo,
              telefono: data?.contacto?.telefono
                ? formatearTelefono(data?.contacto?.telefono)
                : '-',
              preferenciaContacto: data?.contacto?.preferenciaContacto
                ? data?.contacto?.preferenciaContacto
                : '-',
            });
            setAliado({
              nombre: data?.aliado?.nombre,
              id: data?.aliado?._id,
            });
          }
        }
      } else {
        if (statusCode === 403) {
          navigate('/page-error-401');
          return;
        }
        navigate('/page-error-500', { state: { statusCode: statusCode } });
      }
      setCargando(false);
    };

    obtenerPerfilOportunidad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handlePaso = async newPaso => {
    // Buscar Paso al que se va a dirigir
    let etapaPaso = dataEtapas.filter(etapa => etapa.label === newPaso)?.[0];

    if (
      !etapaPaso.checked &&
      etapaPaso.lock &&
      etapaActualizada.orden < etapaPaso.orden
    ) {
      //if (etapaActualizada.label !== etapaPaso.label)
      if (etapaPaso.codigo === 'PER' || etapaPaso.codigo === 'DOR') {
        setEtapaModal(etapaPaso);
        setShowMotivo(true);
      } else {
        await handleCambiarEtapa(etapaPaso);
      }
    } else {
      setCandado(etapaPaso.lock);
      setEtapaActual(etapaPaso);
      setPaso(etapaPaso.orden);
      setEtapa(etapaPaso.label);
      setSelectEtapa(etapaPaso.label);
      setSelectedTab(etapaPaso.orden);
    }
  };

  const handleCambiarEtapa = async (etapaPaso, reset = false) => {
    const { orden } = etapaPaso;

    if (handleValidForm(etapaActualizada?.values, orden) || reset) {
      await handleMultiCambiaEtapa({ etapaPaso, reset });
      await enviarCorreoVerificacion({ idOportunidad: params.id });
    } else {
      setAlert({
        title: '¡Datos Obligatorios!',
        description: 'Para continuar, es necesario completar todos los datos',
        open: true,
        severity: 'error',
      });
    }
  };

  const handleCambiarEtapaModal = async (etapaPaso, mensaje) => {
    handleMultiCambiaEtapa({ etapaPaso, mensaje });
  };

  const handleMultiCambiaEtapa = async dataEtapaParams => {
    const {
      etapaPaso: { label, idEtapa, orden, codigo },
    } = dataEtapaParams;

    setDisabledNext(true);

    if (codigo === 'PAG') {
      setCargando(true);
    }

    try {
      let newDataSelect;
      let newDataEtapa = { ...dataEtapas };

      let param = {
        etapaAnterior: {
          idEtapa: etapaActual.idEtapa,
          codigo: etapaActual.codigo,
          values: etapaActual.values,
        },
        etapa: idEtapa,
        oportunidad: params.id,
        plan,
        mensajeBitacora: mensajesBitacora({ dataContacto, categoria, codigo }),
        isRetrocediendo: dataEtapaParams.reset ? dataEtapaParams.reset : false,
        razonEtapa: dataEtapaParams?.mensaje,
      };

      const { success, data, message, error } = await putApiOportunidad(param);
      if (success) {
        if (data) {
          newDataEtapa = data.dataEtapa;

          if (codigo === 'PAG') {
            newDataEtapa = data.dataEtapa;
            setNumPoliza(data.poliza);
          } else {
            if (codigo === 'COT') {
              setAseguradoraSeleccionada('');
            }
          }
        }

        if (codigo === 'DOR') {
          newDataSelect = dataDormida(dataEtapaParams.etapaPaso, newDataEtapa);
          setShowMotivo(!showMotivo);
        } else if (codigo === 'PER') {
          newDataSelect = dataPerdida(dataEtapaParams.etapaPaso, newDataEtapa);
          setShowMotivo(!showMotivo);
        } else {
          newDataSelect = dataActualizar(
            dataEtapaParams.etapaPaso,
            newDataEtapa,
            pago,
          );
        }
        const objetoOrdenado = newDataSelect.sort((a, b) => a.orden - b.orden);
        const newEtapa = newDataSelect.filter(
          etapa => etapa.label === label,
        )?.[0];

        setLinkCotizacion(data.linkCotizacion);
        setCandado(dataEtapaParams.etapaPaso.lock);
        setEtapaActualizada(newEtapa);
        setEtapaActual(newEtapa);
        setCandado(newEtapa.lock);
        setDataEtapas(objetoOrdenado);
        setPaso(codigo === 'DOR' || codigo === 'PER' ? orden - 1 : orden);
        if (codigo === 'DOR' || codigo === 'PER') {
          setSelectedTab(codigo === 'DOR' ? orden - 2 : orden - 1);
        }
        setEtapa(label);
        setSelectEtapa(label);
      } else {
        setAlert({
          title: error ? error : '¡Ha ocurrido un error!',
          description: message
            ? message
            : 'Inténtalo nuevamente o contacta al equipo de Desarrollo.',
          open: true,
          severity: 'error',
        });
      }
    } finally {
      setDisabledNext(false);
      setCargando(false);
    }
  };

  const handleSetEtapa = () => {
    setShowCambiarEtapa(true);
  };

  const cambiarEtapa = async newEtapa => {
    let etapaPaso = dataEtapas.filter(etapa => etapa.label === newEtapa)?.[0];
    await handleCambiarEtapa(etapaPaso, true);
    setShowCambiarEtapa(false);
  };

  const handleValidForm = (valores, ordenEtapa) => {
    let isValid = true;
    let validaciones = [];

    if (ordenEtapa < paso) {
      return isValid;
    }

    const dataKeys = Object.keys(valores);
    dataKeys.forEach(dataKey => {
      const valor = valores[dataKey];

      etapaActualizada.datos.forEach(p => {
        validaciones = validaciones.concat(
          formValidDatosPersonales(p, valor, dataKey),
        );
      });
    });

    return validaciones.length === 0;
  };

  const handleBack = () => {
    navigate('/dashboard/oportunidades/');
  };

  const handleTabChange = async (event, newValue) => {
    const codigo = event.target.id;
    await handlePaso(event.target.firstChild.data);
    if (codigo !== 'DOR' && codigo !== 'PER') {
      setSelectedTab(newValue);
    }
  };

  const handleRecuperarCotizacion = cotizacionUrl => {
    window.open(cotizacionUrl, '_blank');
  };

  return (
    <>
      <ModalPermiso
        isOpen={modalPermiso}
        setIsOpen={handleBackToList}
        modulo="oportunidades"
      />
      {cargando && <Loader />}
      <AlertCustom
        title={alert.title}
        description={alert.description}
        open={alert.open}
        resetValues={alertReset}
        severity={alert.severity}
      />
      <div className="mb-10 overflow-hidden justify-between flex items-center">
        <TituloPerfil
          handleBack={handleBack}
          titulo={`Oportunidad: ${etapa}`}
          icono={
            etapaActualizada.codigo !== 'GAN' ? (
              etapaActualizada.codigo !== 'PER' &&
              (candado ? (
                <div
                  className="cont-iconLock cursor-pointer"
                  onClick={() => handleSetEtapa(etapa)}
                >
                  <Lock />
                </div>
              ) : (
                <div className="cont-iconLock">
                  <Unlock />
                </div>
              ))
            ) : (
              <Ganada className="w-10 h-10" />
            )
          }
          className="tituloOpc"
        />

        <div className="flex justify-between items-center gap-5">
          <ExternoIcon
            className="cursor-pointer"
            onClick={() =>
              handleRecuperarCotizacion(
                cotizacionSeleccionada?.linkRecuperacionCotizacion,
              )
            }
          />

          <SelectEtapas
            setValue={handlePaso}
            value={selectEtapa}
            options={dataEtapas}
            disabledBtn={disabledNext}
            metodo="patch"
            ruta="oportunidades"
          />
        </div>
      </div>

      <TabsEtapas
        paso={paso}
        setPaso={handlePaso}
        inputProps={dataEtapas}
        candado={candado}
        etapaActual={etapaActualizada}
        setEtapaActual={setEtapaActualizada}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <DialogCambiarEtapa
        open={showCambiarEtapa}
        setOpen={setShowCambiarEtapa}
        etapa={etapa}
        accion={cambiarEtapa}
      />
      <DialogMotivoEtapa
        open={showMotivo}
        setOpen={setShowMotivo}
        etapa={etapaModal}
        accion={handleCambiarEtapaModal}
      />
    </>
  );
};

export default PerfilOportunidades;
