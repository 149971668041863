
import { useEffect, useState } from 'react';
import { getRoles } from '../../services';

export const useGetRoles = () => {
  const [roles, setRoles] = useState([]);
  
  const fetchRoles = async () => {
    try {
      const response = await getRoles();
      const rolesArray = response.data.map((item) => ({
        id: item.nombre,
        label: item.nombre,
      }));

      setRoles(rolesArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    fetchRoles();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return { getRoles, roles };
};
