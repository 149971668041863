import { useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { renderColor } from "../../../core/utils";

const TableHeadersPagination = ({
  actionColumnTitle = "",
  headerGroup = {},
  isActions = false,
  filterHeader,
  filterParams,
  setFilterParams,
  type = "",
}) => {
  const [arrow, setArrow] = useState("desc");

  const handleSortClick = (column) => {
    const nextArrow = arrow === "desc" ? "asc" : "desc";
    setArrow(nextArrow);

    let valueField = "";
    if (type === "Oportunidades") {
      valueField = changeValuesOportunidades(column.id);


      
    } else if (type === "Cotizaciones") {
      valueField = changeValuesCotizaciones(column.id);
    
    }

    const objectOrder = { nameField: valueField, order: nextArrow };
   
    // Actualiza el estado de filterParams con el nuevo orden
    setFilterParams({
      ...filterParams,
      orderBy: objectOrder,
    });
    // Llama a la función de ordenamiento de la columna
  
  };

  const changeValuesOportunidades = (name) => {
  

    switch (name) {
      case "nombre_seguro":
        return "categoria";
      case "aliado_oportunidad":
        return "aliado";
      case "valor":
        return "valor";
      case "estado":
        return "etapa";
      case "contacto":
        return "contacto";
      case "fecha_creacion":
        return "createdAt";
      case "aliado":
        return "aliado";
    }
  };

  const changeValuesCotizaciones = (name) => {
    switch (name) {
      case "nombre_seguro":
        return "seguro";
      case "aliado_oportunidad":
        return "aliado";
      case "valor":
        return "valor";
      case "estado":
        return "etapa";
      case "contacto":
        return "persona";
      case "fecha_creacion":
        return "createdAt";
      case "aliado":
        return "aliado";
    }
  };

  return (
    <>
      {headerGroup.headers.map((column) => (
        <th
          key={column.id}
          className={`px-3 pl-10 py-2 ${
            column.id === "nombre_seguro" ||
            column.id === "contacto" ||
            column.id === "fecha_creacion" ||
            column.id === "aliado" ||
            column.id === "fechaNacimiento"
              ? " w-[350px] "
              : column.id === "telefono"
              ? "w-[250px]  "
              : column.id === "correo"
              ? "w-[370px] "
              : column.id === "fecha_creacion_oportunidad" ||
                column.id === "valor" ||
                column.id === "estado"
              ? "w-[190px] "
              : ""
          } `}
          {...column.getHeaderProps()}
        >
          <span
            className={`flex items-center ${
              column.render("Header").length !== 0
            }`}
          >
            {column.render("Header")}
            {filterHeader && column.render("Header").length !== 0 ? (
              <span onClick={() => handleSortClick(column)}>
                {arrow === "asc" ? (
                  <ArrowDropUpIcon fill={renderColor("white")} />
                ) : (
                  <ArrowDropDownIcon fill={renderColor("white")} />
                )}
              </span>
            ) : (
              <ArrowDropUpIcon fill={renderColor("white")} />
            )}
          </span>
        </th>
      ))}
      {isActions && (
        <th className="pl-3 pr-6 py-2 text-end">{actionColumnTitle}</th>
      )}
    </>
  );
};

export default TableHeadersPagination;
