// Components
import { MenuItem } from '@mui/material';

// Utils
import { renderColor } from '../../../core/utils';

const MenuItemCustom = ({
  children = null,
  value = '',
  disabled = false,
  ...rest
}) => {
  return (
    <MenuItem
      value={value}
      className="fontPRegular"
      sx={{
        '&: hover': {
          backgroundColor: renderColor('hoverSelect'),
          color: renderColor('general'),
        },
        '&.Mui-selected': {
          backgroundColor: renderColor('hoverSelect'),
          color: renderColor('general'),
        },
        '&.Mui-selected:hover': {
          backgroundColor: renderColor('hoverSelect'),
          color: renderColor('general'),
        },
      }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </MenuItem>
  );
};

export default MenuItemCustom;
