export const convertToMarkdownBold = (text = '', className = '') => {
  const bold = /\*(.*?)\*/gm;
  let html = text.replace(bold, '<span class="fontPSemiBold">$1</span>');
  html = html.replace('\n', '<br>');
  return `<span class="${className}">${html}</span>`;
};

export const fontBoldDetalleCotizacion = (detalle, index) => {
  return (
    <p
      className={` fontPRegular ${
        detalle.split(':')[0] === 'Placa' && 'uppercase'
      }   ${index !== 0 ? 'separador' : ''} `}
    >
      <span
        className={`fontPSemiBold  ${
          detalle.split(':')[0] === 'Placa' && 'capitalize'
        } `}
      >
        {detalle.split(':')[0]}: <br />
      </span>
      {detalle.split(':')[1]}
    </p>
  );
};

export const formatearIdentificacion = identificación => {
  return (
    identificación?.substring(0, 1) +
    '-' +
    identificación?.substring(1, 5) +
    '-' +
    identificación?.substring(5)
  );
};

export const formatearTelefono = telefono => {
  if (telefono.includes('-')) {
    const sinGuiones = telefono.replace('-', '');
    return (
      '(506) ' + sinGuiones?.substring(0, 4) + '-' + sinGuiones?.substring(4)
    );
  }

  return '(506) ' + telefono?.substring(0, 4) + '-' + telefono?.substring(4);
};

export const checkAccess = (ruta, metodo, permisosContext = []) => {
  let permisos =
    JSON.parse(localStorage.getItem('listPermisos')) ?? permisosContext;
  let access = false;

  if (ruta !== '' || metodo !== '') {
    permisos.forEach((permiso, index) => {
      if (permiso.nombre === ruta) {
        permisos[index].permisos.forEach(permisoMetodo => {
          if (permisoMetodo === metodo) {
            access = true;
            return access;
          }
        });
      }
    });
  } else {
    access = true;
  }
  return access;
};

export const buscarPermiso = (array, permiso, metodo) => {
  const permisoEncontrado = array.find(p => p.nombre === permiso);

  const tienePermiso =
    permisoEncontrado && permisoEncontrado.permisos.includes(metodo);

  return tienePermiso;
};

export const generarPasswordRamdon = () => {
  const numero = Math.random().toString(32).substring(2, 7);
  const fecha = Date.now().toString(32).substring(2, 7);

  return numero + fecha;
};

export const formatearCantidad = cantidad => {
  const nuevaCantidad = Number(cantidad).toLocaleString('HNL', {
    style: 'currency',
    currency: 'HNL',
  });

  return nuevaCantidad;
};

// export const handleCotizar = () => {
//   window.open(process.env.REACT_APP_link_cotizador, '_blank');
// };

export const generarCodigoAliado = () => {
  const letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let letrasAleatorias = '';

  // Generar 3 letras aleatorias
  for (let i = 0; i < 3; i++) {
    const indiceAleatorio = Math.floor(Math.random() * letras.length);
    letrasAleatorias += letras.charAt(indiceAleatorio);
  }

  let numerosAleatorios = '';

  // Generar 3 números aleatorios
  for (let i = 0; i < 3; i++) {
    const numeroAleatorio = Math.floor(Math.random() * 10);
    numerosAleatorios += numeroAleatorio;
  }

  const resultado = letrasAleatorias + numerosAleatorios;
  return resultado;
};

export const categoriasFormateadas = () => {
  const seguros = [
    { id: '', label: 'Todo' },
    { id: 'SEG-DA', label: 'Deducible Automóvil' },
    { id: 'SEG-AEG', label: 'Enfermedades Graves' },
    { id: 'SEG-FUNE', label: 'Seguro de Funerario Plus' },
    { id: 'SEG-ONC', label: 'Seguro Oncológico Plus' },
    { id: 'SEG-PLENI', label: 'Seguro Plenisalud' },
    { id: 'SEG-VID', label: 'Seguro de Vida Plus' },
    { id: 'SEG-PM', label: 'Seguro de Protección Múltiple' },
    { id: 'SEG-V', label: 'Seguro de Viaje' },
  ];

  return seguros;
};


export const cambiarTituloCodigo = titulo => {
  let codEtapa = '';
  if (titulo === 'Cotización') {
    codEtapa = 'COT';
  }

  if (titulo === 'Documentación') {
    codEtapa = 'DOC';
  }

  if (titulo === 'Ganada') {
    codEtapa = 'GAN';
  }

  if (titulo === 'Dormida') {
    codEtapa = 'DOR';
  }

  if (titulo === 'Negociación') {
    codEtapa = 'NEG';
  }

  if (titulo === 'Pago') {
    codEtapa = 'PAG';
  }

  if (titulo === 'Emisión') {
    codEtapa = 'EMI';
  }

  if (titulo === 'Perdida') {
    codEtapa = 'PER';
  }

  if (titulo === '') {
    codEtapa = '';
  }

  return codEtapa;
};
