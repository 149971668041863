// Components
import { TextCustom } from '../../atoms';

// Style
import './style.css';

const Cotizacion = ({ cotizacion }) => {
  return (
    <div
      className="grid grid-col-cotizaciones items-center bg-card rounded-lg gap-6 p-4"
      style={{
        borderBottom: `10px solid ${
          cotizacion.aseguradora.color ? cotizacion.aseguradora.color : '#000'
        }`,
      }}
    >
      <img
        src={cotizacion.aseguradora.logo}
        alt="Logo aseguradra"
        className="w-full"
      />
      <div className="flex flex-col gap-4">
        {cotizacion?.precios.map((precio, index) =>
          precio.codigo === 'A' ? (
            <div key={index}>
              <TextCustom
                text={precio?.valor}
                component="p"
                variant="p"
                className={`fontPSemiBold text-[${cotizacion.aseguradora.color}] `}
              />
              <TextCustom
                text="Prima total + IVA"
                className="text-sm"
                component="p"
                variant="p"
              />
            </div>
          ) : (
            <div key={index}>
              <TextCustom
                text={precio?.valor ? precio?.valor : '-'}
                component="p"
                variant="p"
                className={`fontPSemiBold text-[${cotizacion.aseguradora.color}] `}
              />
              <TextCustom
                text="Cuota mensual"
                className="text-sm"
                component="p"
                variant="p"
              />
            </div>
          ),
        )}
      </div>

      {/* <span className="bg-general w-full absolute h-2 bottom-0 right-0"></span> */}
    </div>
  );
};

export default Cotizacion;
