import { Fragment, useRef, useState } from 'react';
import { ButtonEtapa } from '../../atoms';
import { cambiarTituloCodigo } from '../../../core/utils/functions';

const Etapa = ({
  etapas,
  setearFiltroGlobal
}) => {
  const buttonRef = useRef(null);
  const [activeEtapa, setActiveEtapa] = useState(JSON.parse(sessionStorage.getItem("etapaSeleccionada")) || '');  

  const handleChance = (titulo, conteoOportunidades) => {
    if(activeEtapa === titulo)  {
      setActiveEtapa(null);
      setearFiltroGlobal('');
      sessionStorage.removeItem("etapaSeleccionada")
      sessionStorage.removeItem("conteoOportunidades")
    } else {
      setActiveEtapa(titulo);
      const filtroCodigo = cambiarTituloCodigo(titulo);
      setearFiltroGlobal(filtroCodigo);
      sessionStorage.setItem("etapaSeleccionada", JSON.stringify(titulo))
      sessionStorage.setItem("conteoOportunidades", JSON.stringify(conteoOportunidades))
    }
  };

  return (
    <>
    {
      etapas.map( etapa => (
        <Fragment key={etapa._id}>
          <div style={{ width: etapa.titulo === 'Documentación' ? '20%' : '17%' }}>
          <ButtonEtapa
            text={etapa.nombre}
            typeColor={etapa.nombre}
            variant="outlined"
            onClick={() => handleChance(etapa.nombre, etapa.conteoOportunidades)}
            className={`fontPSemiBold btn-etapa justify-start ${
              etapa.nombre === activeEtapa ? 'activo' : ''
            }`}
            refState={buttonRef}
            titulo={etapa.nombre}
            conteoOportunidades={etapa.conteoOportunidades}
            borderColor={etapa.colores.find(color => color.tipo === 'primary')}
          />
        </div>
        </Fragment>
      ))
    }
    </>
  );
};

export default Etapa;
