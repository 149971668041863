// Components
import { TextCustom } from '../../atoms';
import { Divider } from '@mui/material';

// Hooks
import { useOportunidad } from '../../../hooks/others';
import { renderTipoSeguro } from '../../../core/utils';

const CardSeguroTab = () => {
  const { categoria, valorCotizacion, aseguradoraSeleccionada } =
    useOportunidad();

  return (
    <div className="card-tabs">
      <div className="mb-1 flex justify-between items-center">
        <TextCustom
          text="Seguro"
          className="fontPSemiBold mb-1"
          color="general"
        />
        {renderTipoSeguro(categoria.codigo, 'xs')}
      </div>
      <Divider />
      <div className="pt-4 grid">
        <TextCustom text="Tipo" className="fontPRegular text-sm" />
        <TextCustom
          text={categoria.nombre}
          className="fontPSemiBold text-max"
        />
      </div>
      <div className="pt-4 grid">
        <TextCustom text="Valor" className="fontPRegular text-sm" />
        <TextCustom text={valorCotizacion} className="fontPSemiBold text-max" />
      </div>
      {aseguradoraSeleccionada && (
        <div className="pt-4 grid">
          <TextCustom text="Aseguradora" className="fontPRegular text-sm" />
          <TextCustom
            text={aseguradoraSeleccionada}
            className="fontPSemiBold text-max"
          />
        </div>
      )}
    </div>
  );
};

export default CardSeguroTab;
