import { useEffect, useState } from 'react';

// Assets
import { ReactComponent as NextIcon } from '../../../assets/icons/tablas/NextIcon.svg';
import { ReactComponent as PrevIcon } from '../../../assets/icons/tablas/PrevIcon.svg';
// Styles
import { IconButtonCustom } from '../../atoms';

// Utils
import { getPagination, renderColor } from '../../../core/utils';

const TablePagination = ({
  canPreviousPage = false,
  canNextPage = false,
  gotoPage = () => null,
  nextPage = () => null,
  pageIndex = 0,
  pageOptions = [],
  previousPage = () => null,
  isResetPagina = false,
  setIsResetPagina = () => null,
}) => {
  const [pagesShow, setPagesShow] = useState([]);

  useEffect(() => {
    const pagination = getPagination(pageOptions, pageIndex, 2);
    setPagesShow(pagination);
  }, [pageOptions, pageIndex]);

  useEffect(() => {
    if (isResetPagina) {
      setIsResetPagina(false);
      gotoPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetPagina]);

  return (
    <div className="flex gap-2 justify-center items-center mt-5">
      <IconButtonCustom
        icon={<PrevIcon />}
        onClick={previousPage}
        disabled={!canPreviousPage}
        size="small"
        className="p-0"
      />
      {pagesShow.map((page, index) => (
        <div
          key={index}
          className={`flex justify-center items-center w-6 h-6 cursor-pointer rounded-full`}
          style={{
            color: `${
              pageIndex === page ? renderColor('white') : renderColor('general')
            }`,
            backgroundColor: `${
              pageIndex === page ? renderColor('general') : renderColor('fondo')
            }`,
          }}
          onClick={() => page !== '...' && gotoPage(page)}
        >
          <span className="text-center fontPBold text-xs">
            {page !== '...' ? page + 1 : page}
          </span>
        </div>
      ))}
      <IconButtonCustom
        icon={<NextIcon />}
        onClick={nextPage}
        disabled={!canNextPage}
        size="small"
        className="p-0"
      />
    </div>
  );
};

export default TablePagination;
