// Components
import { Button } from '@mui/material';

// Core
import { renderColorEtapa, renderColor } from '../../../core/utils';

//styles
import './style.css';

const ButtonEtapa = ({
  text = '',
  onClick = () => null,
  variant = 'contained',
  className = '',
  startIcon = null,
  endIcon = null,
  disabled = false,
  typeColor = 'primary',
  textTransform = 'none',
  height = '',
  refState = '',
  conteoOportunidades = 0,
  borderColor = {},
}) => {
  return (
    <Button
      color="primary"
      ref={refState}
      variant={variant}
      onClick={onClick}
      className={`fontPRegular px-4 ${className}`}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      sx={{
        textTransform: textTransform,
        backgroundColor:
          variant === 'contained' && !disabled
            ? renderColor(typeColor)
            : undefined,
        borderColor:
          (variant === 'outlined' || variant === 'text') && !disabled
            ? borderColor.codigo
            : undefined,
        color:
          (variant === 'outlined' || variant === 'text') && !disabled
            ? renderColor('texto')
            : undefined,
        borderRadius: '20px',
        fontSize: '14px',
        boxShadow: 'none',
        width: '100%',
        height: height ? height : 50,
        padding: '9px 16px',
        '&:hover': {
          backgroundColor:
            variant === 'contained' && !disabled
              ? `${renderColorEtapa(typeColor)}CF`
              : (variant === 'outlined' || variant === 'text') && !disabled
              ? `${renderColorEtapa(typeColor)}0F`
              : undefined,
          borderColor: borderColor.codigo,
        },
        '&.activo': {
          backgroundColor: renderColorEtapa(typeColor),
          color: '#FFF',
          borderColor: '#FFF',
        },
      }}
    >
      <div className="flex items-center">
        <p
          className={`num text-[20px] border-r border-solid h-70 pr-3`}
          style={{
            borderColor: borderColor.codigo,
            color: borderColor.codigo,
          }}
        >
          {conteoOportunidades}
        </p>
        <p className="pl-2 ">{text}</p>
      </div>
    </Button>
  );
};

export default ButtonEtapa;
