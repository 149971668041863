import { useEffect, useState } from "react";
import { buscarPermiso } from "../../../core/utils";
import {
 CardDescargaArchivo,
  CardSubidaArchivo,
  TituloPage,
} from "../../molecules";
import { obtenerUrlBlackList } from "../../../services/apis/blacklist/apiBlacklist";


const ListaControl = () => {
  // eslint-disable-next-line no-unused-vars
  const [permiso, setPermiso] = useState(false);


  const [subidaArchivo, setSubidaArchivo] = useState({
    estado: false,
    nombre: "",
    fecha: "",
    tamaño: "",
    url: "",
  });

  const permisos = JSON.parse(localStorage.getItem("listPermisos"));

  useEffect(() => {
    setPermiso(buscarPermiso(permisos, "usuarios", "post"));
    
  }, [permisos]);

  useEffect(() => {

    
    const obtenerUrl = async () => {
      const data = await obtenerUrlBlackList();
      const { fecha, nombreArchivo, size, url } = data.data;
      

      if (data && data.success) {
        setSubidaArchivo({
          fecha: fecha,
          nombre: nombreArchivo,
          tamaño: size,
          url: url,
          estado: true,
        });
      }

    };

    obtenerUrl();
  }, [subidaArchivo.nombre]);

  return (
    <>
      <div className="grid gap-14">
        <TituloPage titulo="Lista de Control" />

        <div className="grid  gap-3 cursor-pointer ">
          
       { subidaArchivo.estado && <CardDescargaArchivo
            titulo={subidaArchivo.nombre}
            fecha={subidaArchivo.fecha}
            tamaño={subidaArchivo.tamaño}
            url={subidaArchivo.url}
          />}
          
          <div>
            <CardSubidaArchivo
              titulo="Actualizar lista de control"
              subtitulo="Lorem ipsum dolor sit amet consectetur adipisicing elit."
              className="p-8 "
              setSubidaArchivo={setSubidaArchivo}
             
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListaControl;
