import { useState } from 'react';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';
import { DialogActions, DialogContent } from '@mui/material';
import { DialogCustom } from '../../templates';

// Asset
import { ReactComponent as Candado } from '../../../assets/images/Modal/Candado.svg';

const DialogCambiarEtapa = ({
  open = false,
  setOpen = () => null,
  accion = () => null,
  etapa = '',
}) => {
  const [isLoading, setisLoading] = useState(false);

  const handleAccept = async () => {
    setisLoading(true);
    await accion(etapa);
    setisLoading(false);
  };

  const handleCerrar = () => {
    setOpen(false);
  };

  return (
    <DialogCustom
      open={open}
      setOpen={setOpen}
      disabledDismiss
      colorHexa="#fff"
    >
      <DialogContent style={{ width: 600 }}>
        <div className="flex justify-center items-center">
          <Candado />
        </div>
        <div className="mt-5 mb-5 text-center">
          <TextCustom
            text="¿Quieres editar esta etapa?"
            className="fontPSemiBold"
            variant="h4"
            color="general"
          />
          <TextCustom text="Estas seguro que deseas cambiar de etapa, ..." />
        </div>
      </DialogContent>
      <DialogActions className="justify-center my-5 gap-3">
        <ButtonCustom
          text="Cancelar"
          typeColor="danger"
          onClick={handleCerrar}
          width={'140px'}
          height="45px"
        />
        <ButtonCustom
          text="Aceptar"
          typeColor="primary"
          onClick={handleAccept}
          width={'140px'}
          height="45px"
          loading={isLoading}
        />
      </DialogActions>
    </DialogCustom>
  );
};

export default DialogCambiarEtapa;
