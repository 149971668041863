import { memo } from 'react';

// Components
import { InputAdornment, TextField } from '@mui/material';
import { IconButtonCustom, MensajeError } from '../';

// Core
import {
  validTextInput,
  validInputInitialNumbers,
} from '../../../core/validations';

//assets
import { ReactComponent as IconoError } from '../../../assets/icons/IconoErrorInput.svg';
import { ReactComponent as IconoListo } from '../../../assets/icons/Icono-Listo.svg';

// Utils
import { renderColor } from '../../../core/utils';

const TextInputCustom = ({
  name = '',
  value = '',
  setValue = () => null,
  onBlur = () => null,
  onEnter = () => null,
  placeholder = '',
  type = 'text',
  typesValidation = '',
  validInitNumbers = [],
  maxLength = null,
  className = '',
  iconStart = null,
  iconEnd = null,
  iconMode = 'adornment',
  iconTypeColor = 'primary',
  iconOnClick = () => null,
  msgError = '',
  disabled = false,
  multiline = false,
  required = false,
  success = false,
  fontSize = 18,
  borderStyle = false,
  paddingY = '16px',
  paddingX = '14px',
  filtro = false,
  filtroServer = false,
  filterParams,
  setFilterParams,
  onKeyUp = () => null,
}) => {
  const handleOnChange = e => {
    const inputValue = e.target.value;
    let isValid = true;
    if (validInitNumbers.length) {
      isValid = validInputInitialNumbers(inputValue, validInitNumbers);
    } else {
      isValid = validTextInput(inputValue, typesValidation);
    }
    if (isValid || inputValue === '' || !inputValue) {
      setValue(inputValue);
    }
  };

  const handleChangeFilterParams = (e) =>{
    if(filtroServer) {
      const inputValue = e.target.value;
      setFilterParams({...filterParams, search: inputValue })
    }

    setValue(e.target.value)
  }


  const renderIcon = icon => {
    if (icon) {
      return iconMode === 'button' ? (
        <IconButtonCustom
          icon={icon}
          onClick={iconOnClick}
          typeColor={iconTypeColor}
          size={16}
          typeColorHover={iconTypeColor}
        />
      ) : (
        <InputAdornment position="start">{icon}</InputAdornment>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <TextField
        label={name}
        value={value}
        onChange={filtroServer ? handleChangeFilterParams : handleOnChange }
        onBlur={onBlur}
        onKeyDown={event => {
          if (event.code === 'Enter') {
            onEnter();
          }
        }}
        onKeyUp={onKeyUp}
        variant="outlined"
        size="large"
        multiline={multiline}
        minRows={multiline ? '3' : '1'}
        maxRows={multiline ? '4' : '1'}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        required={required}
        inputProps={{
          maxLength: maxLength,
          className: 'text-base',
          style: {
            textAlign: 'left',
            padding: `${paddingY} ${paddingX}`,
            fontFamily: 'Poppins-SemiBold',
            borderRadius: 20,
            color:
              msgError.length > 0
                ? renderColor('danger')
                : success
                ? renderColor('success')
                : renderColor('general'),
            fontWeight: 'bold',
          },
        }}
        InputProps={{
          startAdornment: renderIcon(iconStart),
          endAdornment: iconEnd
            ? renderIcon(iconEnd)
            : msgError.length > 0
            ? renderIcon(<IconoError />)
            : success && <IconoListo color="success" />,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor:
                msgError.length > 0
                  ? renderColor('danger')
                  : success
                  ? renderColor('success')
                  : borderStyle
                  ? renderColor('bordes')
                  : 'transparent',
            },
          },
          '& legend': {
            marginLeft: 2,
            fontSize: fontSize * 0.82,
          },
          '& .MuiInputBase-root': {
            '& fieldset': {
              borderRadius: filtro ? 2 : 5,
              border: msgError.length > 0 || success ? 2 : borderStyle ? 1 : 0,
              borderColor:
                msgError.length > 0
                  ? renderColor('danger')
                  : success
                  ? renderColor('success')
                  : borderStyle
                  ? renderColor('bordes')
                  : 'transparent',
              color: renderColor('general'),
              boxShadow:
                !borderStyle && '0px 6px 40px ' + renderColor('sombra'),
            },
            '&.Mui-focused fieldset': {
              borderColor:
                msgError.length > 0
                  ? renderColor('danger')
                  : success
                  ? renderColor('success')
                  : borderStyle
                  ? renderColor('general')
                  : 'transparent',
              color: renderColor('texto'),
              fontSize: fontSize,
            },
          },
          '& .MuiInputLabel-asterisk': {
            color: renderColor('danger'),
          },
          '& .MuiInputLabel-shrink': {
            marginLeft: 2,
            color: renderColor('texto'),
            fontSize: fontSize,
            fontWeight: '600',
            '& .MuiInputLabel-asterisk': {
              color: renderColor('danger'),
              display: 'inline',
            },
          },
          backgroundColor: disabled
            ? renderColor('ligthGray')
            : renderColor('white'),
          borderRadius: 5,
          marginTop: 1,
        }}
      />
      {msgError && <MensajeError mensaje={msgError} />}
    </div>
  );
};

export default memo(TextInputCustom);
