import axios from 'axios';
import { endpoints } from '../../../common/endpoints';
import buildRequest from '../../buildRequest';
import { stGetToken } from '../../storage/storageToken';



export const enviarCorreoVerificacion = async params => {
    const url = `${process.env.REACT_APP_api}${endpoints.correoVerificacion}`;
    const method = 'post';
    const dataResponse = {
      success: false,
      statusCode: 0,
      message: '',
      data: [],
    };
  
    const { data: token } = stGetToken();
  
    const request = {
      ...params
    };
  
    try {
      const response = await axios[method](url, request, buildRequest(token));
  
      const { data } = response;
  
      dataResponse.success = true;
      dataResponse.data = data.data;
    } catch (error) {
      dataResponse.message = error.response.data.message;
      dataResponse.error = error.response.data.error;
      dataResponse.statusCode = error.response.status;
      dataResponse.success = false;
    }
    return dataResponse;
  };