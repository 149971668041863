import { typesValidation } from '../../common/types';
import { validInputEmail, validTextInput } from './validateInput';

const { validateEmail, onlyPassword } = typesValidation;

export const validFormUsuario = user => {
  const dataResponse = {
    isValid: true,
    msgValid: {
      errors: null,
      success: null,
    },
  };
  let isValid = true;
  let inputsError = {
    nombre: '',
    correo: '',
    password: '',
    rol: '',
  };
  let inputsSuccess = {
    nombre: true,
    correo: true,
    password: true,
    rol: true,
  };

  if (!user.nombre) {
    inputsError.nombre = 'Nombre de usuario no ha sido asiganado.\n';
    inputsSuccess.nombre = false;
    isValid = false;
  }

  if (!user.correo) {
    inputsError.correo = 'Correo no ha sido asignado.\n';
    inputsSuccess.correo = false;
    isValid = false;
  }

  if (!user.rol) {
    inputsError.rol = 'Rol no ha sido asignada.\n';
    inputsSuccess.rol = false;
    isValid = false;
  }

  if (!user.password) {
    inputsError.password = 'Contraseña no ha sido asignada.\n';
    inputsSuccess.password = false;
    isValid = false;
  } else {
    if (user.password.length < 8) {
      inputsError.password = 'La contraseña debe tener 8 o más caracteres';
      inputsSuccess.password = false;
      isValid = false;
    } else {
      if (!validTextInput(user.password, onlyPassword)) {
        inputsError.password =
          'La contraseña debe tener mayúsculas, minúsculas y números';
        inputsSuccess.password = false;
        isValid = false;
      }
    }
  }

  if (!inputsError.correo && !validInputEmail(user.correo, validateEmail)) {
    inputsError.correo = 'Correo no válido.\n';
    inputsSuccess.correo = false;
    isValid = false;
  }

  dataResponse.isValid = isValid;
  dataResponse.msgValid.errors = inputsError;
  dataResponse.msgValid.success = inputsSuccess;
  return dataResponse;
};
