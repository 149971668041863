import { Button, ButtonGroup } from '@mui/material';
import { useOportunidad } from '../../../hooks/others';

const ButtonGroupCustom = ({ options = [] }) => {
  const { divisaSeleccionada, setDivisaSeleccionada } = useOportunidad();

  const handleActive = moneda => {
    setDivisaSeleccionada(moneda);
  };

  return (
    <ButtonGroup
      sx={{
        '& .MuiButtonGroup-grouped': {
          border: 'none',
          borderRadius: '10px',
          backgroundColor: '#E5EBEF',
        },
      }}
    >
      {options.map(option => (
        <Button
          key={option.moneda}
          onClick={() => handleActive(option.moneda)}
          className={` ${
            divisaSeleccionada === option.moneda
              ? 'bg-[#003763] text-white'
              : 'bg-[#E5EBEF] text-[#003763]'
          }  font-bold text-base hover:border-none`}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupCustom;
