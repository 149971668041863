import { useQuery } from '@tanstack/react-query';
import { formatearFecha, renderTipoSeguro } from '../../../core/utils';
import { useOportunidad } from '../../others';
import { apiOportunidadesPagination } from '../../../services';

const useQueryOportunidades = filterParams => {
  const { infoEtapas, categorias, resetearContext } = useOportunidad();
  const filterParamsReduce = eliminarCamposNulos(filterParams);
  const filtrarValoresCotizacion = (valor, data) => {
    if (Array.isArray(data.dataEtapas) && data.dataEtapas.length > 0) {
      const objectPago = data.dataEtapas.filter(item => {
        return item.codigo === 'PAG';
      });

      const valorCotizacion = objectPago[0].values.totalPagar;
      return valorCotizacion;
    } else {
      return valor;
    }
  };

  const queryOportunidadesFiltro = useQuery({
    refetchOnWindowFocus: false,
    enabled:false,
    queryKey: ['oportunidades'],
    queryFn: async () => {
      const result = await apiOportunidadesPagination(filterParamsReduce);
      resetearContext();
      

      const { pagination, data } = result.data;
  
      const oportunidadesFormateadas = data.map(oportunidades =>
        formateoDataOportunidades(oportunidades, data),
      );

      const categoriasFormateadas = categorias.map(categoria => ({
        label: categoria?.nombre,
        id: categoria?.codigo,
      }));

      categoriasFormateadas.unshift({ label: 'Todo', id: '' });
      return {
        oportunidades: oportunidadesFormateadas,
        pagination: pagination,
        categorias: categoriasFormateadas,
      };
    },
  });

  function eliminarCamposNulos(obj) {
    const result = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        result[key] = obj[key];
      }
    }
    return result;
  }

  const formateoDataOportunidades = (oportunidad, data) => {
    let valor = oportunidad.valor_cotizacion ?? 0;

    valor = filtrarValoresCotizacion(valor, data)

    const etapa = infoEtapas.find(
      etapa => etapa._id === oportunidad.etapa._id,
    )?.nombre;

    const categoria = categorias.find(
      categoria => categoria._id === oportunidad.categoria._id,
    );

    const nombreCompleto = `${oportunidad?.contacto?.primer_nombre} ${oportunidad?.contacto?.segundo_nombre} ${oportunidad?.contacto?.primer_apellido} ${oportunidad?.contacto?.segundo_apellido}`;
    const primerNombre = oportunidad?.contacto?.primer_nombre || '';
    const segundoNombre = oportunidad?.contacto?.segundo_nombre || '';
    const primerApellido =
      oportunidad?.contacto?.primer_apellido || segundoNombre;
    const segundoApellido =
      oportunidad?.contacto?.segundo_apellido || segundoNombre;
    const nombre1ConApellidos = `${primerNombre} ${primerApellido} ${segundoApellido}`;
    const nombre2ConApellidos = `${segundoNombre} ${primerApellido} ${segundoApellido}`;
    const nombre1ConApellido2 = `${primerNombre} ${segundoApellido}`;
    const nombre2ConApellido2 = `${segundoNombre} ${segundoApellido}`;

    if (categoria.codigo === 'SEG-V') {
      valor = valor.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else {
      valor = valor.toLocaleString('es-CR', {
        style: 'currency',
        currency: 'CRC',
      });
    }

    return {
      id_oportunidad: oportunidad?._id,
      nombre_seguro: renderTipoSeguro(categoria?.codigo, '', categoria?.nombre),
      filtro: categoria?.nombre,
      contacto: nombreCompleto,
      fecha_creacion: formatearFecha(oportunidad.createdAt).toUpperCase(),
      aliado: oportunidad?.aliado?.nombre,
      valor,
      estado: etapa,
      nombre1Apellidos: nombre1ConApellidos,
      nombre2Apellidos: nombre2ConApellidos,
      nombre1Apellido2: nombre1ConApellido2,
      nombre2Apellido2: nombre2ConApellido2,
    };
  };

  return { queryOportunidadesFiltro };
};

export default  useQueryOportunidades;
