import { memo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//hooks
import { AuthContext } from '../../../hooks/context';

//autentiticacion
import {
  stRemoveIsAuth,
  stRemoveListPermisos,
  stRemovePersonalInfo,
  stRemoveToken,
} from '../../../services/storage';

//types
import { typesActionsAuth } from '../../../common/types';

// Components
import { List } from '@mui/material';
import { DrawerItem } from '../../atoms';

// Assets
import { ReactComponent as LogoAsegurame } from '../../../assets/images/Logo-Asegurame-Blanco.svg';
import { ReactComponent as AliadoIcon } from '../../../assets/icons/sideMenu/Icono-Aliados.svg';
import { ReactComponent as CerrarSesionIcon } from '../../../assets/icons/sideMenu/Icono-CerrarSesion.svg';
import { ReactComponent as ConfiguracionIcon } from '../../../assets/icons/sideMenu/Icono-Configuración.svg';
import { ReactComponent as ContactoIcon } from '../../../assets/icons/sideMenu/Icono-Contacto.svg';
import { ReactComponent as CotizacionesIcon } from '../../../assets/icons/sideMenu/Icono-Cotizaciones.svg';
import { ReactComponent as OportunidadesIcon } from '../../../assets/icons/sideMenu/Icono-Oportunidades.svg';
import { ReactComponent as PerfilIcon } from '../../../assets/icons/sideMenu/Icono-Perfil.svg';
import { ReactComponent as TableroIcon } from '../../../assets/icons/sideMenu/Icono-Tablero.svg';

const SideMenu = ({ onChange = () => null }) => {
  const location = useLocation();
  const navigate = useNavigate();

  //dispatch
  const { dispatchAuth } = useContext(AuthContext);

  const handleHome = () => {
    navigate('/dashboard/home');
    onChange();
  };

  const handleContactos = () => {
    navigate('/dashboard/contactos');
    onChange();
  };



  const handleOportunidades = () => {
    navigate('/dashboard/oportunidades');
    onChange();
  };

  const handleCotizaciones = () => {
    navigate('/dashboard/cotizaciones');
    onChange();
  };

  const handleAliados = () => {
    navigate('/dashboard/aliados');
    onChange();
  };

  const handlePerfil = () => {
    navigate('/dashboard/perfil');
    onChange();
  };

  const handleConfiguracion = () => {
    navigate('/dashboard/configuration');
    onChange();
  };

  const handleLogout = () => {
    navigate('/login');
    dispatchAuth({ type: typesActionsAuth.authLogout });
    stRemoveIsAuth();
    stRemovePersonalInfo();
    stRemoveToken();
    stRemoveListPermisos();
  };

  return (
    <div className="flex flex-col h-full py-4 ">
      <div className="flex justify-center items-center py-12">
        <LogoAsegurame className="w-10/12" />
      </div>
      <div className="h-full flex flex-col justify-between ">
        <List className="flex flex-col gap-1 py-0 ">
          <DrawerItem
            text={'Tablero'}
            onClick={handleHome}
            icon={<TableroIcon />}
            isSelected={location.pathname === '/dashboard/home'}
          />
          <DrawerItem
            text={'Cotizaciones'}
            onClick={handleCotizaciones}
            icon={<CotizacionesIcon />}
            isSelected={location.pathname.includes('/dashboard/cotizaciones')}
            ruta="cotizaciones"
            metodo="get"
          />
          <DrawerItem
            text={'Oportunidades'}
            onClick={handleOportunidades}
            icon={<OportunidadesIcon />}
            isSelected={location.pathname.includes('/dashboard/oportunidades')}
            ruta="oportunidades"
            metodo="get"
          />
          <DrawerItem
            text={'Aliados'}
            onClick={handleAliados}
            icon={<AliadoIcon />}
            isSelected={location.pathname.includes('/dashboard/aliados')}
            ruta="aliados"
            metodo="get"
          />
          <DrawerItem
            text={'Contactos'}
            onClick={handleContactos}
            icon={<ContactoIcon />}
            isSelected={location.pathname.includes('/dashboard/contactos')}
            ruta="personas"
            metodo="get"
          />


          


        </List>

        

        <div className="pb-2">
          <div className="px-5 pb-2">
            <hr />
          </div>
          <List className="flex flex-col gap-1 py-0 ">
            <DrawerItem
              text={'Perfil'}
              onClick={handlePerfil}
              icon={<PerfilIcon />}
              //isSelected={location.pathname === '/dashboard/home'}
              isBottom={true}
              ruta="perfil"
              metodo="get"
            />
            <DrawerItem
              text={'Configuración'}
              onClick={handleConfiguracion}
              icon={<ConfiguracionIcon />}
              //isSelected={location.pathname === '/dashboard/personas'}
              isBottom={true}
              ruta="usuarios"
              metodo="get"
            />
            <DrawerItem
              text={'Cerrar sesión'}
              onClick={handleLogout}
              icon={<CerrarSesionIcon />}
              //isSelected={location.pathname === '/dashboard/oportunidades'}
              isBottom={true}
            />
          </List>
        </div>
      </div>
    </div>
  );
};

export default memo(SideMenu);
