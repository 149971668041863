import axios from 'axios';
import { endpoints } from '../../../common/endpoints';
import buildRequest from '../../buildRequest';
import { stGetToken } from '../../storage';

export const postApiAliados = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.aliados}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    error: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    identificacion: params.cedula,
    nombre: params.nombre,
    correo: params.correo,
    telefono: params?.telefono,
    foto: params?.foto,
    usuarios: params.usuarios ? params.usuarios : [],
    codigoAliado: params.codigoAliado,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.message = data?.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.error = error.response.data.error;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const getUsuariosAliado = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.usuariosAliado}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idAliado: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const postApiAgregarUsuarioAliado = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.aliados_agregar_usuario}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idAliado: params.idAliado,
    idUsuario: params.idUsuario,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.message = data?.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
export const deleteApiQuitarUsuarioAliado = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.aliados_quitar_usuario}`;
  const method = 'post';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    idAliado: params.idAliado,
    idUsuario: params.idUsuario,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
    dataResponse.message = data?.message;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const putApiAliados = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.aliados}?id=${params.id}`;
  const method = 'patch';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    error: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    identificacion: params.identificacion,
    nombre: params.nombre,
    correo: params.correo,
    telefono: params.telefono,
    foto: params.foto,
    usuarios: params.usuarios,
  };

  try {
    const response = await axios[method](url, request, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.error = error.response.data.error;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const getApiAliados = async () => {
  const url = `${process.env.REACT_APP_api}${endpoints.aliados}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };
  const { data: token } = stGetToken();

  try {
    const response = await axios[method](url, buildRequest(token));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data.data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};

export const getApiDetalleAliado = async params => {
  const url = `${process.env.REACT_APP_api}${endpoints.aliados}`;
  const method = 'get';
  const dataResponse = {
    success: false,
    statusCode: 0,
    message: '',
    data: [],
  };

  const { data: token } = stGetToken();

  const request = {
    id: params,
  };

  try {
    const response = await axios[method](url, buildRequest(token, request));

    const { data } = response;

    dataResponse.success = true;
    dataResponse.data = data;
  } catch (error) {
    dataResponse.message = error.response.data.message;
    dataResponse.statusCode = error.response.status;
    dataResponse.success = false;
  }
  return dataResponse;
};
