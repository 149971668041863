// Components
import { TextCustom, TituloCard } from '../../atoms';

// Assets
import { ReactComponent as TelIcon } from '../../../assets/icons/Icono-Telefono.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Icono-Correo.svg';

// Utils
import { formatearTelefono } from '../../../core/utils';

const CardContacto = ({
  telefono = '',
  correo = '',
  editable = false,
  handleShowModal = () => null,
}) => {
  return (
    <>
      <div className="relative">
        <TituloCard
          titulo="Contacto"
          editar={editable}
          handleShowModal={handleShowModal}
        />
      </div>
      <div className="flex flex-col justify-between gap-y-6 m-2 mt-6 break-words h-3/5">
        <div>
          <TextCustom text="Correo Electrónico" className="text-sm" />
          <div className="flex gap-2 items-center">
            <EmailIcon />
            <TextCustom
              text={`${correo ? correo : '-'}`}
              className="fontPSemiBold text-max"
              component="p"
              variant="p"
            />
          </div>
        </div>
        <div>
          <TextCustom text="Teléfono" className="text-sm" />
          <div className="flex gap-2 items-center">
            <TelIcon />
            <TextCustom
              text={`${telefono ? formatearTelefono(telefono) : '-'}`}
              component="p"
              variant="p"
              className="fontPSemiBold"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardContacto;
