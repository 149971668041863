import { useEffect } from 'react';

// Components
import { TextCustom, TituloCard } from '../../atoms';
import {
  CardAliadoTab,
  CardContactoTab,
  CardBitacora,
  CardRequisitos,
  CardSeguroTab,
} from '../../molecules';
import { FormPreguntas } from '../../organisms';
import { Tab, Tabs } from '@mui/material';

// Utils
import { renderDetalle } from '../../../core/utils';

// Sytles
import './style.css';
import { useOportunidad } from '../../../hooks/others';

// Assets
import IconConfirmacion from '../../../assets/icons/Ganada/Icon-Confirmacion.png';
import IconConsecutivo from '../../../assets/icons/Ganada/Icon-Consecutivo.png';
import IconPoliza from '../../../assets/icons/Ganada/Icon-Poliza.png';

const TabsEtapas = ({
  inputProps = [],
  paso = 1,
  candado = true,
  etapaActual = '',
  handleTabChange = () => null,
  setSelectedTab = () => null,
  selectedTab = 1,
}) => {
  const {
    categoria,
    detalleCotizacion,
    numPoliza,
    numConsecutivo,
    numConfirmacion,
    razonEtapa,
  } = useOportunidad();

  useEffect(() => {
    setSelectedTab(paso - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso]);

  const renderNombreDetalle = () => {
    switch (categoria?.codigo) {
      case 'SEG-DA':
        return 'Datos del vehículo';
      case 'SEG-V':
        return 'Datos del viaje';
      case 'SEG-AEG':
        return 'Datos del Asegurado';
      case 'SEG-ONC':
        return 'Datos del Asegurado'
      case 'SEG-PLENI':
        return 'Datos del Asegurado'
    
      default:
        return null;
    }
  };

  return (
    <div className="w-100 bg-degradado">
      <div className="pb-6">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          sx={{
            '& .MuiTabs-indicator': { display: 'none' },
            '& .MuiTabs-flexContainer': {
              gap: 1,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            },
          }}
        >
          {inputProps.map(
            (input, index) =>
              input.mostrarTab && (
                <Tab
                  key={input.idEtapa}
                  label={input?.alias ?? input.label}
                  style={{
                    width: etapaActual.codigo !== 'GAN' ? '13.5%' : '16%',
                  }}
                  id={input.codigo}
                  className={`opacity-100 fontPSemiBold capitalize text-sm`}
                  sx={{
                    color:
                      input.checked ||
                      paso === index + 1 ||
                      etapaActual.label === input.label
                        ? input.colorPrimary
                        : '#DDDDDD',
                    '&.Mui-selected': {
                      color: input.colorPrimary,
                    },
                    '& .MuiTouchRipple-root': {
                      height: 5,
                      top: 'auto',
                      backgroundColor:
                        input.checked ||
                        paso === index + 1 ||
                        etapaActual.label === input.label
                          ? input.colorPrimary
                          : '#DDDDDD',
                    },
                    '&.Mui-disabled': {
                      color: '#DDDDDD',
                      borderBottom: '5px solid #DDDDDD',
                    },
                  }}
                  disabled={input.disabledTab}
                />
              ),
          )}
        </Tabs>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3 grid gap-4 h-fit">
          {detalleCotizacion.length !== 0 && (
            <div className="card-tabs pt-5 pb-0">
              <TituloCard titulo={renderNombreDetalle()} />
              <div className="grid grid-cols-4 gap-x-2 gap-y-7 mx-5 py-5">
                {Object.entries(detalleCotizacion).map(([clave, valor]) => (
                  <>{renderDetalle(clave, valor, 'OPT')}</>
                ))}
              </div>
            </div>
          )}
          <div className="relative" id="contenedor_datos">
            <div className={`${candado && 'contenedor-lock'}`}></div>
            {inputProps.length !== 0 &&
              inputProps.map((input, index) => (
                <div
                  key={index}
                  className={`w-100 contenedor-pasos contenedor-${index} ${
                    selectedTab === index ? 'block' : 'hidden'
                  }`}
                >
                  {etapaActual.label === 'Ganada' &&
                    input.label === 'Ganada' && (
                      <div className="grid grid-cols-3 gap-4 pb-4">
                        <div className="card-tabs py-4 grid grid-cols-3">
                          <div className="col-span-1">
                            <img
                              src={IconPoliza}
                              alt="poliza"
                              className="w-[80px] h-[80px]"
                            />
                          </div>
                          <div className="col-span-2 flex flex-col justify-center ml-2">
                            <TextCustom text="Póliza" className="text-sm" />
                            <TextCustom
                              text={numPoliza}
                              className="text-base fontPSemiBold"
                              color="general"
                            />
                          </div>
                        </div>
                        <div className="card-tabs py-4 grid grid-cols-3">
                          <div className="col-span-1">
                            <img
                              src={IconConsecutivo}
                              alt="Consecutivo"
                              className="w-[80px] h-[80px]"
                            />
                          </div>
                          <div className="col-span-2 flex flex-col justify-center ml-2">
                            <TextCustom
                              text="Consecutivo"
                              className="text-sm"
                            />
                            <TextCustom
                              text={numConsecutivo}
                              className="text-base fontPSemiBold"
                              color="general"
                            />
                          </div>
                        </div>
                        <div className="card-tabs py-4 grid grid-cols-3">
                          <div className="col-span-1">
                            <img
                              src={IconConfirmacion}
                              alt="Confirmación"
                              className="w-[80px] h-[80px]"
                            />
                          </div>
                          <div className="col-span-2 flex flex-col justify-center ml-2">
                            <TextCustom
                              text="Confirmación"
                              className="text-sm"
                            />
                            <TextCustom
                              text={numConfirmacion}
                              className="text-base fontPSemiBold"
                              color="general"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {input.datos.length !== 0 && (
                    <div
                      className={`grid ${
                        input.datos.length === 1
                          ? 'grid-cols-1'
                          : 'grid-cols-2 gap-4'
                      }`}
                    >
                      {input.datos.map(pregunta =>
                        pregunta?.tipo_dato?.tipo ? (
                          <FormPreguntas
                            pregunta={pregunta}
                            tipoDato={pregunta?.tipo_dato?.tipo}
                            valor={pregunta.value}
                            values={input.values}
                            key={pregunta.codigo}
                          />
                        ) : (
                          <CardRequisitos
                            requisito={pregunta}
                            codigo={pregunta.codigo}
                            values={input.values}
                            key={pregunta.codigo}
                          />
                        ),
                      )}
                    </div>
                  )}
                  {(etapaActual.label === 'Dormida' ||
                    etapaActual.label === 'Perdida') &&
                    (input.label === 'Dormida' ||
                      input.label === 'Perdida') && (
                      <div className="card-tabs">
                        <TituloCard titulo={`Razón de ${etapaActual.label}`} />
                        <div className="pt-5">
                          <TextCustom text={razonEtapa} />
                        </div>
                      </div>
                    )}
                </div>
              ))}
          </div>

          <CardBitacora metodo="get" ruta="bitacora_oportunidad" />
        </div>
        <div className="col-span-1 grid gap-4 h-fit">
          <CardSeguroTab />
          <CardContactoTab />
          <CardAliadoTab />
        </div>
      </div>
    </div>
  );
};

export default TabsEtapas;
