import { useEffect, useRef, useState } from 'react';

// Components
import { ButtonCardsText, TextCustom } from '../../atoms';

// Hooks
import { useOportunidad } from '../../../hooks/others';

// Assets
import ButtonCopyClipboard from '../ButtonCopyClipboard';
const CardRadio = ({
  pregunta = '',
  value = '',
  setValue = () => null,
  defaulValue = '',
}) => {
  const inputRef = useRef(null);

  const { pago, setPago, linkCotizacion } = useOportunidad();
  const [options, setOptions] = useState([]);
  const [toolTip, setToolTip] = useState('Copiar');
  const [isMostrandoLinkPago, setIsMostrandoLinkPago] = useState(false);

  useEffect(() => {
    let newOptions = pregunta.respuestas.map(respuesta => {
      const option = {
        id: respuesta._id ? respuesta._id : respuesta.id,
        label: respuesta.nombre ? respuesta.nombre : respuesta.label,
        estado: respuesta.estado ? respuesta.estado : '',
      };
      return option;
    });

    const objetoOrdenado = newOptions.sort((a, b) => a.label - b.label);
    setOptions(objetoOrdenado);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (options.length > 0) {
      if (!value && defaulValue) {
        setValue(defaulValue);
      } else if (pregunta.value !== 'metodoPago') {
        handleClick(options[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (value) {
      setIsMostrandoLinkPago(
        pregunta.respuestas.some(resp => resp._id === value),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClick = val => {
    let valor = pregunta.value;
    let newPago = { ...pago };

    if (valor in newPago) newPago[valor] = val?.id;

    setPago(newPago);
    setValue(val?.id);
  };

  const handleCopyClick = async () => {
    navigator.clipboard.writeText(inputRef.current.value);
    setToolTip('Copiado');

    setTimeout(() => {
      setToolTip('Copiar');
    }, 2000);
  };

  return (
    <div className="mt-5">
      <TextCustom className="text-sm" text={pregunta.descripcion} />
      <div className="grid grid-cols-3 my-4">
        {options.map(respuesta => (
          <ButtonCardsText
            label={respuesta.label}
            id={respuesta.id}
            estado={respuesta.estado}
            value={value}
            onClick={handleClick}
            key={respuesta.label}
            data={respuesta}
            width={'auto'}
            height={70}
          />
        ))}
      </div>
      {pregunta.value === 'metodoPago' && isMostrandoLinkPago && (
        <ButtonCopyClipboard
          handleCopyClick={handleCopyClick}
          inputRef={inputRef}
          titulo="Link de pago"
          toolTipMensaje={toolTip}
          valueToCopy={linkCotizacion}
          tamTitulo="xs"
        />
      )}
    </div>
  );
};

export default CardRadio;
