import { useState } from 'react';

// Components
import { Tooltip } from '@mui/material';
import { IconButtonCustom, TableState } from '../../atoms';

// Const
import { typesTableActions } from '../../../common/types';

// Assets
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { ReactComponent as ViewIcon } from '../../../assets/icons/tablas/ViewIcon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/Icono-Link.svg';

// Style
import './style.css';

const { tableView, tableAdd, tableEdit, tableDelete, linkButton } =
  typesTableActions;

const TableRows = ({
  actionClick = () => null,
  actions = [],
  identifierName = 'id',
  isActions = false,
  page = [],
  prepareRow = () => null,
}) => {
  const [toolTip, setToolTip] = useState('Copiar');

  const renderActions = action => {
    let actionIcon = null;
    switch (action) {
      case tableView:
        actionIcon = (
          <Tooltip title="Ver">
            <ViewIcon />
          </Tooltip>
        );
        break;
      case linkButton:
        actionIcon = (
          <Tooltip title={toolTip}>
            <LinkIcon />
          </Tooltip>
        );
        break;
      case tableAdd:
        actionIcon = (
          <Tooltip title="Agregar">
            <AddIcon />
          </Tooltip>
        );
        break;
      case tableEdit:
        actionIcon = (
          <Tooltip title="Editar">
            <EditIcon />
          </Tooltip>
        );
        break;
      case tableDelete:
        actionIcon = (
          <Tooltip title="Eliminar">
            <DeleteIcon />
          </Tooltip>
        );
        break;
      default:
        actionIcon = null;
        break;
    }
    return actionIcon;
  };

  const renderCells = cell => {
    let element = null;
    if (cell.column.id === 'estado') {
      element = <TableState state={cell.value} />;
    } else {
      element = cell.render('Cell');
    }
    return element;
  };

  const actionTable = (action, id, dataRow) => {
    if (action === linkButton) {
      setToolTip('Copiado');
    }
    actionClick(action, id, dataRow);

    setTimeout(() => {
      setToolTip('Copiar');
    }, 2000);
  };
  return (
    <>
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <tr className="shadow-table" {...row.getRowProps()} key={index}>
            {row.cells.map(cell => (
              <td
              key={cell.column.id}
              className={`px-3 py-4 pl-5 2xl:pl-10 h-24 ${
                cell.column.id === "nombre_seguro" ||
                cell.column.id === "contacto" ||
                cell.column.id === "fecha_creacion" ||
                cell.column.id === "aliado" ||
                cell.column.id === "fechaNacimiento"
                  ? "w-[350px]"
                  : cell.column.id === "telefono"
                  ? "w-[250px] "
                  : cell.column.id === "correo"
                  ? "w-[370px] "
                  : cell.column.id === "fecha_creacion_oportunidad" ||
                    cell.column.id === "valor" ||
                    cell.column.id === "estado"
                  ? "w-[190px] "
                  : ""
                }`}
                {...cell.getCellProps()}
              >

                {renderCells(cell)}
              </td>
            ))}
            {isActions && (
              <td className="px-3 py-4 flex justify-center items-center 3xl:mt-3 mt-3">
                {actions.map((action, index) => {
                  let rowEnabled = row.original.ENABLED;
                  let enabled = true;
                  if (typeof rowEnabled === 'boolean') {
                    enabled = rowEnabled;
                  }
                  return (
                    <IconButtonCustom
                      key={index}
                      icon={renderActions(action)}
                      typeColor="default"
                      typeColorHover="primary"
                      size="1.5rem"
                      onClick={() =>
                        actionTable(
                          action,
                          row.original[identifierName],
                          row.original,
                        )
                       
                      }
                      disabled={!enabled}
                    />
                  );
                })}
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
};

export default TableRows;
